/* eslint-disable prettier/prettier */
export class Observation {
  constructor(props) {
    this.id = props.observationId;
    this.requestId = props.requestId;
    this.justification = props.justification;
    this.state = props.state?.value ?? '';
    this.createdBy = props.createdBy;
    this.largeId = props.Id;
    this.type = props.type;
    this.typeId = props.type?.id ;
    this.url = props.url;
    this.isEditable = props.editable;
    this.updateDate = props.updateDate;
    this.creationDate = props.creationDate;
    this.modelUrl = props?.modelUrl ?? '';
    this.title = props.title;
    this.updatedBy = props.updatedBy;
    this.file = props?.file ?? null;
  }
}
