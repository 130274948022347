import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import './index.less';

const WUploadFile = ({
  title,
  fileList,
  handleUpload,
  handleChange,
  errorMessage,
  icon,
  accept,
}) => {
  return (
    <div className="container-upload">
      <Upload
        accept={accept}
        onChange={handleChange}
        customRequest={handleUpload}
        fileList={fileList}
      >
        <Button icon={icon} className={`btn-upload ${errorMessage && 'error'}`}>
          {title}
        </Button>
      </Upload>
      {!isEmpty(errorMessage) && <label className="error-file">{errorMessage}</label>}
    </div>
  );
};

WUploadFile.defaultProps = {
  icon: <UploadOutlined />,
  accept: '',
};

WUploadFile.propTypes = {
  icon: PropTypes.node,
  accept: PropTypes.string,
};

export default WUploadFile;
