import React, { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { Divider, Input, Spin, Typography } from 'antd';
import './index.less';
import { isEmpty } from 'lodash';
import {
  ButtonAdd,
  ConfirmationModal,
  ContainerCard,
  CustomIcon,
  DropZone,
  ObservationModal,
} from '../../../components';
import { observationOptions } from '../../../utilities/Constant';
import { generateFileUrl } from '../../../utilities/Functions';
import { KB_1 } from '../../../components/DropZone/DropZone';
import { FileItem } from '../../NewRequest/ThirdStepRequest';

const { Title, Text } = Typography;
const { TextArea } = Input;

const modelObservationInitial = {
  type: 1,
  justification: '',
  file: {},
};
const INITIAL_OBS_MODEL_ERRORS = {
  justification: null,
  file: null,
};

const observationTypeLabel = {
  1: 'Observación de modelo',
  2: 'Información de Solicitud',
  3: 'Contraparte',
  4: 'Anexos',
};

const Error = ({ visible, text = 'Error en esta sección.' }) => {
  return <>{visible && <Text style={{ color: '#ff6565', marginTop: '5px' }}>{text}</Text>}</>;
};

export default function SecondStepDetailRequest({
  onSetChange = () => {},
  modelObservationErrors,
  updateModelObservations,
  setValObsModel,
}) {
  const [modalObservation, setModalObservation] = useState(false); // observacion de info
  const [confirmationModal, setConfirmationModal] = useState(false); // observation de Modelo
  const [informationObservations, setInformationObservations] = useState([]);
  const [justificationObservation, setJustificationObservation] = useState(false);
  const [observationSelected, setObservationSelected] = useState(null);
  const [indexSelected, setIndexSelected] = useState(null);
  const [modelObservation, setModelObservation] = useState({ ...modelObservationInitial });
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadFileError, setUploadFileError] = useState('');
  const [confirmDeleteObservation, setConfirmDeleteObservation] = useState(false);
  const [confirmDeleteModelObservation, setConfirmDeleteModelObservation] = useState(false);

  useEffect(() => {
    let observations = [...informationObservations];
    if (justificationObservation) {
      const { type, justification, file } = modelObservation;
      observations = [...observations, { type, justification, file }];
    }
    onSetChange(observations);
  }, [informationObservations, modelObservation]);

  const onAddInformationObservation = (obs) => {
    if (observationSelected) {
      informationObservations[indexSelected] = obs;
    } else {
      informationObservations.push(obs);
    }
    setInformationObservations([...informationObservations]);
    setModalObservation(false);
  };

  const onDeleteObservation = (index) => {
    informationObservations.splice(index, 1);
    setInformationObservations([...informationObservations]);
  };

  const onJustificationObservation = () => {
    setJustificationObservation(true);
    updateModelObservations({ ...INITIAL_OBS_MODEL_ERRORS });
    setValObsModel(true);
    setConfirmationModal(false);
  };

  const onEditObservation = (observation, index) => {
    unstable_batchedUpdates(() => {
      setObservationSelected(observation);
      setIndexSelected(index);
      setModalObservation(true);
    });
  };

  const showModalToAddInfoObservation = () => {
    unstable_batchedUpdates(() => {
      setObservationSelected(null);
      setModalObservation(true);
    });
  };

  const onSelectFile = async (uploadedFile) => {
    setIsLoadingUpload(true);
    const { url, id } = await generateFileUrl(uploadedFile);
    setIsLoadingUpload(false);
    if (!isEmpty(url)) {
      modelObservation.file = {
        url,
        id,
        filename: uploadedFile.name,
        size: Math.round(uploadedFile.size / KB_1),
        type: uploadedFile.type,
      };
      setModelObservation({ ...modelObservation });
    } else {
      setUploadFileError('Error al subir file.');
    }
  };

  const deleteFile = () => {
    const newModelObservation = { ...modelObservation };
    newModelObservation.file = {};
    setModelObservation(newModelObservation);
    updateModelObservations({ file: true });
  };

  const { file } = modelObservation;
  // eslint-disable-next-line no-unused-vars
  const { url, filename } = file;

  const onChangeJustification = (e) => {
    modelObservation.justification = e.target.value;
    setModelObservation({ ...modelObservation });
    if (isEmpty(e.target.value.trim())) {
      updateModelObservations({ justification: true });
    } else {
      updateModelObservations({ justification: false });
    }
  };

  return (
    <div className="secondStep-container">
      <Title className="secondStep-title">Observaciones</Title>
      <div
        className={
          informationObservations.length !== 0
            ? 'secondStep-information'
            : 'secondStep-InformationNull'
        }
      >
        <div>
          <Title className="secondStep-subtitle">Observaciones de información</Title>
          <div className="secondStep-disclaimerContainer">
            <CustomIcon type="errorAlert" />
            <Text className="secondStep-disclaimerText">
              Ingrese las observaciones que presenta la solicitud
            </Text>
          </div>
        </div>
        <ButtonAdd title="Agregar" onClick={showModalToAddInfoObservation} />
      </div>
      {informationObservations.length !== 0 && (
        <div className="informationObservation-container">
          {informationObservations.map((observation, index) => {
            return (
              <div key={`${observation.type}`} className="observationCard-container">
                <div className="observationCard-category">
                  <Title className="observationCard-categoryTitle">
                    {observationTypeLabel[observation.type]}
                  </Title>
                  <Text>{observation.justification}</Text>
                </div>
                <CustomIcon
                  type="editObservation"
                  onClick={() => onEditObservation(observation, index)}
                />
                <CustomIcon
                  className="observationCard-icon"
                  type="deleteObservation"
                  onClick={() => {
                    setConfirmDeleteObservation(true);
                    setIndexSelected(index);
                  }}
                />
                {indexSelected === index && (
                  <ConfirmationModal
                    visible={confirmDeleteObservation}
                    icon="alertNotification"
                    title="Confirmación de eliminación"
                    subtitle="¿Estás seguro de eliminar esta observación?"
                    buttonOne="Cancelar"
                    buttonTwo="Aceptar"
                    onClose={() => setConfirmDeleteObservation(false)}
                    onClickBnOne={() => setConfirmDeleteObservation(false)}
                    onClickBnTwo={() => onDeleteObservation(index)}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      <div className="secondStep-information">
        <div>
          <Title className="secondStep-subtitle">Observaciones de modelo</Title>
          <div className="secondStep-disclaimerContainer">
            <CustomIcon type="errorAlert" />
            <Text className="secondStep-disclaimerText">
              Ingrese la observación del modelo usado por el usuario
            </Text>
          </div>
        </div>
        {!justificationObservation ? (
          <ButtonAdd title="Agregar" onClick={() => setConfirmationModal(true)} />
        ) : (
          <CustomIcon
            className="secondStep-bnDeleteJustification"
            type="deleteJustification"
            onClick={() => setConfirmDeleteModelObservation(true)}
          />
        )}
        <ConfirmationModal
          visible={confirmDeleteModelObservation}
          icon="alertNotification"
          title="Confirmación de eliminación"
          subtitle="¿Estás seguro de eliminar esta observación?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmDeleteModelObservation(false)}
          onClickBnOne={() => setConfirmDeleteModelObservation(false)}
          onClickBnTwo={() => {
            unstable_batchedUpdates(() => {
              setModelObservation({ ...modelObservationInitial });
              updateModelObservations({ ...INITIAL_OBS_MODEL_ERRORS });
              setJustificationObservation(false);
              setValObsModel(false);
              setConfirmDeleteModelObservation(false);
            });
          }}
        />
        <ObservationModal
          visible={modalObservation}
          onClickClose={() => setModalObservation(false)}
          onClickButton={onAddInformationObservation}
          options={observationOptions}
          observation={observationSelected}
        />
        <ConfirmationModal
          visible={confirmationModal}
          icon="alertNotification"
          title="Observación de modelo"
          subtitle="¿Desea observar el modelo? La solicitud no podrá desarrollarse hasta tener una respuesta por parte del usuario."
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmationModal(false)}
          onClickBnOne={() => setConfirmationModal(false)}
          onClickBnTwo={() => onJustificationObservation()}
        />
      </div>
      {justificationObservation && (
        <ContainerCard className="secondStep-justificationModel">
          {!isEmpty(file) ? (
            <div
              className="secondStep-justificationModel_container"
              style={{ display: 'flex', flexDirection: 'column', width: '50%' }}
            >
              <FileItem key={`file-${filename}`} attached={file} onDelete={deleteFile} />
            </div>
          ) : (
            <Spin tip="Subiendo archivo..." spinning={isLoadingUpload}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DropZone
                  title=""
                  className={
                    modelObservationErrors.file
                      ? 'secondStep__justificationDropzone--error'
                      : 'secondStep__justificationDropzone'
                  }
                  button="Aceptar"
                  onSelectFile={onSelectFile}
                />
                <Error
                  visible={modelObservationErrors.file || uploadFileError}
                  text={!isEmpty(uploadFileError) ? uploadFileError : 'Necesita subir un archivo'}
                />
              </div>
            </Spin>
          )}
          <Divider type="vertical" className="secondStep-justificationDivider" />
          <div className="container-justification">
            <TextArea
              className="secondStep-justificationInput"
              placeholder="Observación de modelo"
              onChange={onChangeJustification}
              value={modelObservation.justification}
              style={modelObservationErrors.justification ? { border: '1px solid #ff6565' } : {}}
            />
            <Error
              visible={modelObservationErrors.justification}
              text="La observación es requerida."
            />
          </div>
        </ContainerCard>
      )}
    </div>
  );
}
