/* eslint-disable spaced-comment */
/* eslint-disable prettier/prettier */
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';

import AuthServices from '../../../domain/usecases/AuthServices';
import { clearRoles } from './role';
import { FIELD_NEXTTIME_LOGIN, ROL_CODE } from '../../utilities/Constant';
import UserServices from '../../../domain/usecases/UserServices';

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// Reducer

export default function userReducer(state = {}, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...action.payload, error: {} };
    case LOGOUT_SUCCESS:
      return { ...action.payload };
    case LOGIN_ERROR:
      return { ...action.payload, successLogin: false, loading: false, isProcessing: true };
    default:
      return state;
  }
}

// Actions

export const login = (user, password) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: {
        loading: true,
        isProcessing: true,
      },
    });
    const request = {
      user,
      password,
    };
    const [data, error] = await AuthServices.authRequest(request);
    if ((data && error === null) || isEmpty(password)) {
      const [dataUser, errorUser] = await UserServices.getUserByEmail(user);
      localStorage.setItem(FIELD_NEXTTIME_LOGIN, new Date());
      if (errorUser === null && dataUser !== null) {
        const { roleId } = dataUser;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...dataUser,
            isApplicant: ROL_CODE.applicant === roleId,
            isLegalOwner: ROL_CODE.legalOwner === roleId,
            isAdmin: ROL_CODE.admin === roleId,
            loading: false,
          },
        });
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            error: { message: 'Credenciales Incorrectas' },
          },
        });
      }
    } else {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          error: { message: 'Credenciales Incorrectas' },
        },
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: {
        error: { message: 'Credenciales Incorrectas' },
      },
    });
  }
};
export const loginFedereation = (user) => async (dispatch) => {
    try {
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: {
          loading: true,
          isProcessing: true,
        },
      });
      const request = {
        user,
      };
        const [dataUser, errorUser] = await UserServices.getUserByEmail(request.user);
        localStorage.setItem(FIELD_NEXTTIME_LOGIN, new Date());
        if (errorUser === null && dataUser !== null) {
          const { roleId } = dataUser;
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              ...dataUser,
              isApplicant: ROL_CODE.applicant === roleId,
              isLegalOwner: ROL_CODE.legalOwner === roleId,
              isAdmin: ROL_CODE.admin === roleId,
              loading: false,
            },
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              error: { message: 'Credenciales Incorrectas' },
            },
          });
        }
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          error: { message: 'Credenciales Incorrectas' },
        },
      });
    }
  };
  
export const changeLoading = (loading) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: {
        loading,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch(clearRoles());
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    await Auth.signOut({
      global: true,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  }
};

export const setErrorLogin =
  (message = 'Token ha expirado.') =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_ERROR,
      payload: { error: { message, successLogin: false } },
    });
  };
