import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';

import { NormalButton, ResponsableModal, ResponsableDetailsCard } from '../../../components';
import CustomIcon from '../../../components/CustomIcon/CustomIcon';

import './index.less';

const { Title, Paragraph } = Typography;

const SecondStepRequestTemplate = ({ secondStepData }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLegalOwner, setSelectedLegalOwner] = useState({});
  const { legalOwners, onSetRequest, legalOwner, errorMessages } = secondStepData;

  useEffect(() => {
    if (legalOwner && !isEmpty(legalOwner)) {
      const legalOwnerFound = legalOwners.find((lo) => {
        return lo.email === legalOwner;
      });
      setSelectedLegalOwner(legalOwnerFound ?? {});
    }
  }, []);

  const onHandleModal = () => {
    setModalVisible((prevent) => !prevent);
  };

  return (
    <>
      <div className="requestType-headContainer">
        <Title className="requestType-title">Responsable de solicitud</Title>
        <Paragraph className="requestType-subtitle ">
          Seleccione el responsable para el desarrollo de la solicitud
        </Paragraph>
      </div>
      <div className="main-responsableDetail">
        <div className="header-card">
          <div>
            <div className="card-title">Responsable</div>
            <div className="card-subtitle">
              <CustomIcon type="ic_alert_withoutBackg" />
              <Paragraph
                className={`text ${errorMessages && isEmpty(selectedLegalOwner) && 'error'}`}
              >
                Es obligatorio seleccionar un responsable.
              </Paragraph>
            </div>
          </div>

          <div>
            <div className="select-responsable">
              <NormalButton label="Seleccionar" onClick={onHandleModal} />
              <ResponsableModal
                visible={modalVisible}
                onClose={onHandleModal}
                legalOwners={legalOwners}
                onSelectLegalOwner={setSelectedLegalOwner}
                onSetRequest={onSetRequest}
              />
            </div>
          </div>
        </div>
        <ResponsableDetailsCard responsable={selectedLegalOwner} />
      </div>
    </>
  );
};

SecondStepRequestTemplate.propTypes = {};

export default SecondStepRequestTemplate;
