/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.less';
import { Typography, Button, Tag, Spin } from 'antd';
import {
  CustomDynamicTable,
  CustomIcon,
  RequestStatus,
  SearchInput,
  ContainerCard,
} from '../../components/index';
import { Routes } from '../../configuration/routes/Routes';
import RequestServices from '../../../domain/usecases/RequestServices';
import { valueInSpanish } from '../../utilities/Constant';

const { Title, Text } = Typography;

const statuses = [
  { id: '1', type: 'all' },
  { id: '2', type: 'new' },
  { id: '3', type: 'process' },
  { id: '4', type: 'revision' },
  { id: '5', type: 'observation' },
  { id: '6', type: 'validation' },
  { id: '7', type: 'finalized' },
  { id: '8', type: 'cancelled' },
];

const colors = {
  Process: 'geekblue',
  Review: 'orange',
  Observed: 'red',
  Validation: 'purple',
  Finalized: 'green',
  New: 'blue',
};

const CustomTag = ({ value }) => {
  return <Tag color={colors[value]}>{valueInSpanish[value] || ''}</Tag>;
};

const RequestsTemplate = (props) => {
  const { isNotUser } = props;
  const [requests, setRequests] = useState([]);
  const [statusSelected, setStatusSelected] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getRequestList = useCallback(async () => {
    setIsLoading(true);
    const [data, error] = await RequestServices.getRequestList();
    if (data && error === null) {
      setRequests(data.sort((a, b) => b.updateDate - a.updateDate));
    } else {
      //  todo manage the errors
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getRequestList();
  }, [getRequestList]);

  const onStatusSelected = (value) => {
    setStatusSelected(value);
  };

  const history = useHistory();

  const columns = [
    {
      title: 'ID solicitud',
      dataIndex: 'contractId',
      key: 'contractId',
    },
    {
      title: 'Tipo solicitud',
      dataIndex: 'type',
      key: 'type',
      render: (item) => item,
    },
    {
      title: 'Responsable',
      dataIndex: 'legalOwner',
      key: 'legalOwner',
    },
    {
      title: 'Fecha recibida',
      dataIndex: 'contractDate',
      key: 'contractDate',
    },
    {
      title: 'Fecha de entrega',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
    },
    {
      title: 'Observaciones',
      dataIndex: 'observations',
      key: 'observations',
      render: (item) => <div className="observations-quantity">{item}</div>,
    },
    {
      title: 'Notificaciones',
      dataIndex: 'notifications',
      key: 'notifications',
      render: (item) => <div className="observations-quantity">{item}</div>,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      render: (info = {}) => <CustomTag value={info} />,
    },
    {
      title: ' ',
      key: 'action',
      render: (item) => {
        const { requestId, requestUrl } = item;
        return (
          <div className="requestTemplate-dFlex">
            <CustomIcon
              type="requestTable"
              onClick={() => {
                history.push(`${Routes.REQUEST.DETAIL}/${requestId}`);
              }}
            />
            <CustomIcon
              type="showRow"
              onClick={() => {
                window.open(requestUrl, '_blank');
              }}
            />
          </div>
        );
      },
    },
  ];

  // eslint-disable-next-line array-callback-return, consistent-return
  let datafilter = requests.filter((item) => {
    const searchCondition =
      searchTerm === '' ||
      (item?.legalOwner ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (valueInSpanish[item?.state] ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item?.contractId ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item?.type ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item?.contractDate ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item?.expirationDate ?? '').toLowerCase().includes(searchTerm.toLowerCase());

    if (searchCondition) {
      return item;
    }
  });

  if (statusSelected !== 0) {
    datafilter = datafilter.filter((item) => {
      return item.stateId === statusSelected;
    });
  }

  const onNewRequest = () => {
    history.push(Routes.REQUEST.NEW);
  };

  return (
    <div>
      <div className="requestTemplate-dFlex">
        <Title className="requestTemplate-title">Solicitudes</Title>
        {!isNotUser && (
          <Button className="requestTemplate-bn-newRequest" onClick={onNewRequest}>
            <Text className="bn-newRequest-plus">+</Text>
            <Text className="bn-newRequest-text">Crear Solicitud</Text>
          </Button>
        )}
      </div>
      <div className="requestTemplate-statusContainer ">
        {statuses.map((status, index) => {
          const { type } = status;
          return (
            <RequestStatus
              key={`status-${status.id}`}
              className="requestTemplate-requestStatus"
              type={type}
              onClick={() => onStatusSelected(index)}
            />
          );
        })}
      </div>
      <div className="requestTemplate-searchInput-container">
        <SearchInput
          placeholder="Busque ID de solicitud, tipo de solicitud, encargado, estado de solicitud, etc…"
          value={searchTerm}
          onChange={(evt) => setSearchTerm(evt.target.value)}
        />
      </div>
      <Spin
        spinning={isLoading}
        tip="Cargando información..."
        style={{ height: '500px', maxHeight: '500px' }}
      >
        <ContainerCard>
          <CustomDynamicTable headerData={columns} bodyData={datafilter} minWidth="calc(900px)" />
        </ContainerCard>
      </Spin>
    </div>
  );
};

RequestsTemplate.propTypes = {};

export default RequestsTemplate;
