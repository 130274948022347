import React from 'react';
import { Spin } from 'antd';

import './index.less';

const Loading = ({ ...props }) => {
  return (
    <div className="loading-container">
      <Spin size="large" {...props} />
    </div>
  );
};

export default Loading;
