import React from 'react';
import { Input } from 'antd';
import './index.less';

const { TextArea } = Input;

const WTextInput = ({ placeholder, value, onChange }) => {
  return (
    <TextArea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="custom-textarea"
    />
  );
};

export default WTextInput;
