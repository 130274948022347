import { Button, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { pathServer } from '../../utilities/Constant';
import './index.less';

const WCollapseShare = ({ state }) => {
  const { active } = state;
  const [collapse, setCollapse] = useState(active);
  const [buttonState, setButtonState] = useState(false);
  const [xPosition, setX] = useState(300);
  const handleClick = () => {
    setButtonState(true);
  };
  const handleClickSvg = () => {
    setButtonState(false);
  };
  const fCollapse = () => {
    if (xPosition >= 0) {
      setX(380);
      setTimeout(() => {
        setCollapse(false);
      }, 300);
    }
    set(0);
  };
  useEffect(() => {
    setX(0);
  }, []);

  return (
    <>
      {collapse ? (
        <div className="side-bar" style={{ transform: `translatex(${xPosition}px)` }}>
          <div className="container">
            <Row className="contenido-top">
              <ReactSVG
                values="svg"
                onClick={buttonState ? handleClickSvg : fCollapse}
                className="icon-svg"
                src={`${pathServer.PATH_ICONS}${
                  buttonState ? 'ic_feather_arrow_left.svg' : 'ic_share.svg'
                }`}
              />
              <Title level={5}>Compartir documento</Title>
              <ReactSVG
                onClick={fCollapse}
                className="icon-svg"
                src={`${pathServer.PATH_ICONS}ic_out.svg`}
              />
            </Row>
            {buttonState ? (
              <Col className="contenido-bottom" style={{ height: ' 200px' }}>
                <div className="title">
                  Ingrese correo electronico de la contraparte para que tenga acceso a este
                  documento.
                </div>
                <div className="wrap-input1 validate-input">
                  <label className="label-input1">
                    <span> correo electronico</span>
                  </label>
                  <input className="input1" type="text" placeholder="example@gmail.com" />
                  <span className="focus-input1" />
                </div>
                <div className="buton-container">
                  <Button className="button">Enviar</Button>
                </div>
              </Col>
            ) : (
              <Row className="contenido-bottom">
                <Button type="secundary" onClick={handleClick}>
                  <ReactSVG className="icon-svg" src={`${pathServer.PATH_ICONS}ic_link.svg`} />
                  Compartir Documento
                  <ReactSVG className="icon-svg" src={`${pathServer.PATH_ICONS}ic_forward.svg`} />
                </Button>
              </Row>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
WCollapseShare.defaultProps = {
  state: {
    active: false,
  },
};
export default WCollapseShare;
