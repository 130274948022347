export const Routes = {
  MAIN: '/',
  HOME: '/home',
  HOME_ADMIN: '/home-admin',
  TEST: '/test',
  LOGIN: '/auth',
  ICONS: '/icons',
  COMPONENT: '/component',
  REQUEST: {
    LIST: '/request/list',
    NEW: '/request/new',
    DETAIL: '/request/detail',
  },
  REPOSITORY: '/repository',
  MEMBERS: '/members',

  MODULO_NAME_2: {
    SUBMODULO: 'xxxx',
    SUBMODULO2: 'xxxx',
  },
  MODULO_NAME_: {
    SUBMODULO: 'xxxx',
    SUBMODULO2: 'xxxx',
  },
};
