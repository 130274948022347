import React from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { pathServer } from '../../utilities/Constant';
import { Routes } from '../../configuration/routes/Routes';

import './index.less';

const WMenu = ({ className, onLogout, selected }) => {
  const history = useHistory();
  return (
    <Menu defaultSelectedKeys={[selected]} mode="inline" theme="light" className={className}>
      <Menu.Item
        key="1"
        icon={
          <ReactSVG
            src={`${pathServer.PATH_ICONS}ic_ principalPage.svg`}
            style={{ paddingTop: '10px' }}
          />
        }
        onClick={() => history.push(Routes.HOME)}
      >
        Página principal
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={
          <ReactSVG src={`${pathServer.PATH_ICONS}ic_request.svg`} style={{ paddingTop: '10px' }} />
        }
        onClick={() => history.push(Routes.REQUEST.LIST)}
      >
        Solicitudes
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<img src={`${pathServer.PATH_IMG}logoutUser.png`} />}
        className="option-logout"
        onClick={() => onLogout()}
      >
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );
};
export default WMenu;
