import React, { useEffect, useState } from 'react';
import { Divider, Input, Form, Typography } from 'antd';
import { isEmpty } from 'lodash';

import { requestProperty } from '../constants';
import { contractModelType, fileExtension, fileType } from '../../../utilities/Constant';
import {
  onSelectContractFile,
  onSelectJustifyFile,
  onSelectPickerModel,
} from '../../../utilities/Functions';

import { Toggle, DropZone, ContainerCard, CustomIcon, PickerDrive } from '../../../components';
import { FileItem } from '../ThirdStepRequest';

import './index.less';

const { TextArea } = Input;
const { Title } = Typography;

const acceptContractModel = {
  [fileType.DOCX]: [`.${fileExtension.DOC}`, `.${fileExtension.DOCX}`],
};

const acceptContractJustify = {
  [fileType.PDF]: [
    `.${fileExtension.PDF}`,
    `.${fileExtension.DOC}`,
    `.${fileExtension.DOCX}`,
    `.${fileExtension.XLS}`,
    `.${fileExtension.XLSX}`,
    `.${fileExtension.JPEG}`,
    `.${fileExtension.JPG}`,
  ],
};

const disclaimerMap = {
  0: 'Seleccionar un modelo de contrato del repositorio.',
  1: 'Subir el modelo en formato .doc',
};

const modelTypeMap = {
  0: contractModelType.REPOSITORY,
  1: contractModelType.EXTERNAL,
};

const FifthStepRequestTemplate = ({ fifthStepData }) => {
  const { contractModel, onSetRequest, errorMessages } = fifthStepData;
  const [form] = Form.useForm();
  const {
    origin,
    justify: { id, modelName, size, type, documents, description },
    contractDrive,
  } = contractModel;
  const [optionSelected, setOptionSelected] = useState(
    origin === contractModelType.REPOSITORY ? 0 : 1
  );
  const savedData = {
    justificationDescription: description,
  };

  const onOptionSelected = (value) => {
    setOptionSelected(value);
    const contractModelToUpdate = { ...contractModel };
    contractModelToUpdate.origin = modelTypeMap[value];
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  const onSelectPickerFile = (params) => {
    onSelectPickerModel(params, contractModel, onSetRequest);
  };
  const onSelectFile = async (file, imageUrl) => {
    const data = {
      origin,
      contractModel,
      onSetRequest,
    };
    onSelectContractFile(file, imageUrl, data);
  };

  const onSelectDocFile = async (file) => {
    onSelectJustifyFile(file, contractModel, onSetRequest);
  };

  const onDeleteContract = () => {
    const contractModelToUpdate = { ...contractModel };
    if (optionSelected === 0) {
      contractModelToUpdate.contractDrive = {};
    } else {
      contractModelToUpdate.justify = { documents, description };
    }
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  const onDeleteDoc = (idDoc) => {
    const contractModelToUpdate = { ...contractModel };
    const documentsAll = contractModelToUpdate.justify.documents;
    contractModelToUpdate.justify.documents = documentsAll.filter((doc) => doc.id !== idDoc);
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  const onChangeTextArea = (value) => {
    const contractModelToUpdate = { ...contractModel };
    contractModelToUpdate.justify.description = value;
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  const contractFile = modelName ? { id, modelName, size, type } : {};

  useEffect(() => {
    if (errorMessages) form.submit();
    else form.resetFields();
  }, [errorMessages, optionSelected]);

  return (
    <>
      <Form name="step-5" autoComplete="off" form={form} initialValues={savedData}>
        <Toggle
          title="Modelo de contrato"
          subtitle="Ingrese el modelo de contrato con el que se trabajará"
          state={{ checked: 'Persona Jurídica' }}
          options={[
            {
              label: 'Modelo de repositorio',
              value: 'Modelo de repositorio',
            },
            {
              label: 'Modelo Externo',
              value: 'Modelo Externo',
            },
          ]}
          onChange={onOptionSelected}
          optionSelected={optionSelected}
        />
        {optionSelected === 0 ? (
          <ContainerCard className="container-dropZone">
            <PickerDrive
              title="Modelo de contrato"
              disclaimer={disclaimerMap[optionSelected]}
              handleClick={onSelectPickerFile}
              disabled={!isEmpty(contractDrive)}
              errorMessage={
                errorMessages && isEmpty(contractDrive) ? 'Debe seleccionar un archivo' : null
              }
            />
            {!isEmpty(contractDrive) && (
              <div className="fifthStepRequest-file-contract">
                <FileItem
                  attached={contractDrive}
                  fileName="modelName"
                  onDelete={onDeleteContract}
                />
              </div>
            )}
          </ContainerCard>
        ) : (
          <>
            <ContainerCard className="container-dropZone">
              <DropZone
                title="Modelo de contrato"
                disclaimer={disclaimerMap[optionSelected]}
                button={optionSelected === 0 ? 'Aceptar' : null}
                accept={acceptContractModel}
                multiple={false}
                onSelectFile={onSelectFile}
                disabled={!isEmpty(modelName)}
                errorMessage={errorMessages && isEmpty(modelName) && 'Debe seleccionar un archivo'}
              />
              {!isEmpty(modelName) && (
                <div className="fifthStepRequest-file-contract">
                  <FileItem
                    attached={contractFile}
                    fileName="modelName"
                    onDelete={onDeleteContract}
                  />
                </div>
              )}
            </ContainerCard>
            <ContainerCard className="fifthStepRequest-containerCard">
              <div className="d-flex-column">
                <Title className="title-dropZone" level={5}>
                  Justificación de modelo
                </Title>
                <div className="disclaimer-dropZone">
                  <CustomIcon className="icon-disclaimer" type="ic_alert_withoutBackg" />
                  Subir archivos con forma DOC, PDF, EXE o JPG (max 800x400px)
                </div>
              </div>
              <div className="fifthStepRequest-content">
                <div className="fifthStepRequest-dropZone-container">
                  <DropZone
                    title={null}
                    accept={acceptContractJustify}
                    multiple={false}
                    onSelectFile={onSelectDocFile}
                  />
                  {documents?.length > 0 && (
                    <div className="fifthStepRequest-fileList">
                      {documents.map((file) => {
                        return (
                          <FileItem
                            key={`document-${file.id}`}
                            attached={file}
                            onDelete={onDeleteDoc}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="fifthStepRequest-divider">
                  <Divider type="vertical" style={{ height: '100% !important' }} />
                </div>
                <Form.Item
                  name="justificationDescription"
                  className="fifthStepRequest-dropZone-input"
                  rules={[{ required: true, message: 'Ingrese la justificación' }]}
                >
                  <TextArea
                    className="height-200"
                    placeholder="Ingrese la justificación... "
                    value={description}
                    onChange={({ target: { value } }) => {
                      onChangeTextArea(value);
                    }}
                  />
                </Form.Item>
              </div>
            </ContainerCard>
          </>
        )}
      </Form>
    </>
  );
};

FifthStepRequestTemplate.propTypes = {};

export default FifthStepRequestTemplate;
