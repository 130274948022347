import React from 'react';
import { Divider } from 'antd';
import './index.less';
import { ReactSVG } from 'react-svg';
import { pathServer } from '../../utilities/Constant';

const WUserInfo = () => {
  return (
    <div className="user-info-container">
      <div className="container-image">
        <ReactSVG src={`${pathServer.PATH_ICONS}ic_logo.svg`} />
      </div>
      <Divider className="divider-image" />
    </div>
  );
};

WUserInfo.propTypes = {};

export default WUserInfo;
