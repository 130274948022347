import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Form } from 'antd';
import { getValueDefault, maxRepresentative, validateEmail } from '../../utilities/Functions';

import {
  businessTypes,
  companyProperty,
  personType,
  REPRESENTATIVE,
  validationType,
} from '../../utilities/Constant';
import WSelectInput from '../SelectInput';
import CustomIcon from '../CustomIcon/CustomIcon';

import RepresentativeCardEdit from '../RepresentativeCardEdit/RepresentativeCardEdit';
import { requestProperty } from '../../templates/NewRequest/constants';
import PTextInput from '../PTextInput';

import './index.less';

const { Title, Text } = Typography;

const CompanyCardEdit = ({
  company,
  className = '',
  title = '',
  onSetCompanies = () => {},
  companyIndex,
  isReadOnly,
  counterPart,
  onSetRequest,
  errorMessages,
  form,
}) => {
  const {
    name = '',
    address = '',
    electronicCertificate = '',
    registerOffice = '',
    ruc = '',
    socialReason = '',
    type = '',
    legalRepresentatives,
    email,
  } = company;

  const [editItemRepresentative, setEditRepresentative] = useState(null);

  const checkEmail = () => validateEmail(getValueDefault(email));

  const onChangeValue = (value, property) => {
    const companyToUpdate = { ...company };
    companyToUpdate[property] = value;
    onSetCompanies(companyToUpdate, companyIndex);
  };

  const onSetRepresentative = (representative, representativeIndex) => {
    const companyToUpdate = { ...company };
    companyToUpdate.legalRepresentatives[representativeIndex] = representative;
    onSetCompanies(companyToUpdate, companyIndex);
  };

  const addRepresentativeToCompany = () => {
    const counterPartToUpdate = [...counterPart];
    const indexLegal = counterPart.findIndex((e) => e.type === personType.LEGAL);

    const legalPerson = counterPartToUpdate[indexLegal];
    const quantity = legalPerson.companies[companyIndex].lastRepresentative + 1;
    const representative = `${REPRESENTATIVE} ${quantity}`;
    counterPartToUpdate[indexLegal].companies[companyIndex][
      companyProperty.LEGAL_REPRESENTATIVES
    ].push({
      representative,
    });
    counterPartToUpdate[indexLegal].companies[companyIndex].lastRepresentative = quantity;
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
    setEditRepresentative(representative);
  };

  const editRepresentantive = (representative) => {
    const currentRepresentative = representative.representative;
    if (editItemRepresentative === currentRepresentative) setEditRepresentative(null);
    else setEditRepresentative(currentRepresentative);
  };

  const onDeleteRepresentativeFromCompany = (representantive) => {
    setEditRepresentative(null);
    onDeleteRepresentative(representantive?.representative);
  };

  const onDeleteRepresentative = (representative) => {
    const counterPartToUpdate = [...counterPart];
    let representativesList;
    // legal person
    const indexLegal = counterPart.findIndex((e) => e.type === personType.LEGAL);
    representativesList =
      counterPartToUpdate[indexLegal].companies[companyIndex][
        companyProperty.LEGAL_REPRESENTATIVES
      ];
    representativesList = representativesList.filter((re) => re.representative !== representative);
    counterPartToUpdate[indexLegal].companies[companyIndex][companyProperty.LEGAL_REPRESENTATIVES] =
      representativesList;
    const repQuantity = representativesList.length;
    if (repQuantity === 1) {
      counterPartToUpdate[indexLegal].companies[companyIndex].lastRepresentative = 1;
    } else {
      counterPartToUpdate[indexLegal].companies[companyIndex].lastRepresentative =
        maxRepresentative(representativesList);
    }
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  useEffect(() => {
    if (errorMessages) {
      form.validateFields();
    }
  }, [editItemRepresentative]);

  useEffect(() => {
    if (legalRepresentatives?.length === 1) {
      setEditRepresentative(legalRepresentatives[0].representative);
    }
  }, [company]);

  return (
    <div className={`company-card-container ${className}`}>
      <Title className="company-card-title">{title}</Title>
      <Row gutter={10} className="company-card-row company-firstSection">
        <Col>
          <Form.Item
            name={`${companyProperty.NAME}${companyIndex}`}
            rules={[{ required: true, message: 'Ingrese nombre de la empresa' }]}
          >
            <PTextInput
              placeholder="Nombre de la empresa"
              value={getValueDefault(name)}
              onChange={(value) => onChangeValue(value, companyProperty.NAME)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.RUC}${companyIndex}`}
            rules={[{ required: true, message: 'Ingrese número de RUC' }]}
          >
            <PTextInput
              placeholder="Número de Ruc"
              value={getValueDefault(ruc)}
              onChange={(value) => onChangeValue(value, companyProperty.RUC)}
              type="number"
              maxLength={11}
              validation={validationType.RUC_LEGAL}
              readOnly={isReadOnly}
              errorMessage={`${
                errorMessages && getValueDefault(ruc) ? 'RUC jurídico inválido' : ''
              }`}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.SOCIAL_REASON}${companyIndex}`}
            rules={[{ required: true, message: 'Ingrese razón social' }]}
          >
            <PTextInput
              placeholder="Razón social"
              value={getValueDefault(socialReason)}
              onChange={(value) => onChangeValue(value, companyProperty.SOCIAL_REASON)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.TYPE}${companyIndex}`}
            rules={[{ required: true, message: 'Seleccione tipo de empresa' }]}
          >
            <WSelectInput
              options={businessTypes}
              placeholder="Tipo de empresa"
              value={type}
              onChange={(value) => onChangeValue(value, companyProperty.TYPE)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.ELECTRONIC_CERTIFICATE}${companyIndex}`}
            rules={[{ required: true, message: 'Ingrese partida Electrónica' }]}
          >
            <PTextInput
              placeholder="Partida Electrónica"
              value={getValueDefault(electronicCertificate)}
              onChange={(value) => onChangeValue(value, companyProperty.ELECTRONIC_CERTIFICATE)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.ADDRESS}${companyIndex}`}
            rules={[{ required: true, message: 'Ingrese domicilio Fiscal' }]}
          >
            <PTextInput
              placeholder="Domicilio Fiscal"
              value={getValueDefault(address)}
              onChange={(value) => onChangeValue(value, companyProperty.ADDRESS)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.REGISTER_OFFICE}${companyIndex}`}
            rules={[{ required: true, message: 'Ingrese sede de la Oficina Registral' }]}
          >
            <PTextInput
              placeholder="Sede de la Oficina Registral"
              value={getValueDefault(registerOffice)}
              onChange={(value) => onChangeValue(value, companyProperty.REGISTER_OFFICE)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={`${companyProperty.EMAIL}${companyIndex}`}
            rules={[{ required: true, validator: checkEmail }]}
          >
            <PTextInput
              placeholder="Correo de contacto"
              value={getValueDefault(email)}
              onChange={(value) => onChangeValue(value, companyProperty.EMAIL)}
              readOnly={isReadOnly}
              lowerCase
            />
          </Form.Item>
        </Col>
      </Row>
      <div className="container-representantive">
        <div className="titleContainer">
          <Title className="title">Información de representantes</Title>
        </div>
        <Text className="subtitleContainer">
          <CustomIcon className="subtitleIcon" type="ic_alert_withoutBackg" />
          Es obligatorio completar todo los campos.
        </Text>
        {legalRepresentatives.map((rep, indexRep) => (
          <RepresentativeCardEdit
            key={`${personType.LEGAL}${rep.representative}`}
            className="detailRequest-containerContent-with-border"
            title={rep.representative}
            representative={rep}
            selectedIndexRepresentative={indexRep}
            onSetRepresentative={onSetRepresentative}
            isReadOnly={isReadOnly}
            showDetail={editItemRepresentative === rep.representative}
            editRepresentantive={editRepresentantive}
            onDeleteRepresentative={onDeleteRepresentativeFromCompany}
            showDelete={legalRepresentatives.length > 1}
            companyIndex={companyIndex}
            person={personType.LEGAL}
            errorMessages={errorMessages}
          />
        ))}
        {!isReadOnly && (
          <button
            type="button"
            className="buttonAddRepresentative"
            onClick={addRepresentativeToCompany}
          >
            Agregar representante
            <CustomIcon type="addCircle" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CompanyCardEdit;
