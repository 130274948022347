import React from 'react';
import './index.less';
import { Typography, Button } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';
import { emptyFunction } from '../../utilities/Constant';

const { Paragraph, Text } = Typography;
const CounterPartConfirmAnswer = ({
  className,
  icon,
  title,
  subtitle,
  onClickLeft,
  onClickRight,
  buttonLeftText,
  buttonRightText,
}) => {
  return (
    <div className={`${className} confirm-answer`}>
      <CustomIcon type={icon} className="exclamatory-icon" />
      <div className="right-container">
        <Paragraph className="title-confirm-answer">{title}</Paragraph>
        <Text type="secondary" className="subtitle-confirm-answer">
          {subtitle}
        </Text>
        <div className="container-buttons">
          <Button className="left-button" onClick={onClickLeft}>
            {buttonLeftText}
          </Button>
          <Button className="right-button" onClick={onClickRight}>
            {buttonRightText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CounterPartConfirmAnswer;

CounterPartConfirmAnswer.defaultProps = {
  icon: 'warningTriangle',
  title: '¿Respondió la contraparte?',
  subtitle:
    'Para continuar el desarrollo de la solicitud, la contraparte debe validar el documento enviado. Revise su bandeja de entrada y confirme.',
  buttonLeftText: 'Ir bandeja',
  buttonRightText: 'Confirmar',
  onClickLeft: emptyFunction,
  onClickRight: emptyFunction,
};
