import React, { useState, useEffect } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { Modal, Row, Input } from 'antd';
import { ReactSVG } from 'react-svg';
import './index.less';
import CloseIcon from '../../../resources/svg/Icon-close-circle-outline.svg';
import UserIcon from '../../../resources/svg/ic_user.svg';
import SearchIcon from '../../../resources/svg/Icon-search.svg';
import CustomIcon from '../CustomIcon/CustomIcon';
import { requestProperty } from '../../templates/NewRequest/constants';

const ResponsableModal = ({ visible, onClose, legalOwners, onSelectLegalOwner, onSetRequest }) => {
  const [legalOwnersFiltered, setLegalOwnersFiltered] = useState([...legalOwners]);

  useEffect(() => {
    setLegalOwnersFiltered(legalOwners);
  }, [legalOwners]);

  const onChangeText = (e) => {
    const text = e.target.value?.toLowerCase();
    const filteredLegalOwners = legalOwners?.filter(
      ({ name, fatherLastName, motherLastName }) =>
        name?.toLowerCase().includes(text) ||
        fatherLastName?.toLowerCase().includes(text) ||
        motherLastName?.toLowerCase().includes(text)
    );
    setLegalOwnersFiltered(filteredLegalOwners);
  };

  return (
    <Modal
      wrapClassName="responsable-modal"
      centered
      visible={visible}
      closeIcon={<ReactSVG src={CloseIcon} onClick={onClose} />}
      width={393}
      footer={null}
    >
      <div className="responsableModal-title">
        <h3 className="rm-title">Responsable:</h3>
        <CustomIcon
          type="closeResponsableModal"
          onClick={onClose}
          className="closeResponsableModal"
        />
      </div>
      <SearchInput onChangeText={onChangeText} />
      {legalOwnersFiltered.length === 0 ? (
        <span>No se encontraron responsables con el filtro aplicado</span>
      ) : (
        legalOwnersFiltered?.map((legalOwner) => (
          <ResponsableItemSelect
            legalOwner={legalOwner}
            key={legalOwner.id}
            onSelectLegalOwner={onSelectLegalOwner}
            onClose={onClose}
            onSetRequest={onSetRequest}
          />
        ))
      )}
    </Modal>
  );
};

const SearchInput = ({ onChangeText }) => {
  return (
    <Input
      placeholder="Buscar responsable"
      onChange={onChangeText}
      suffix={<ReactSVG src={SearchIcon} className="rm-search-icon" />}
    />
  );
};

const ResponsableItemSelect = ({ legalOwner, onSelectLegalOwner, onClose, onSetRequest }) => {
  const { firstName, fatherLastName, motherLastName } = legalOwner;
  const onSelect = () => {
    unstable_batchedUpdates(() => {
      onClose();
      onSelectLegalOwner(legalOwner);
      onSetRequest(requestProperty.LEGAL_OWNER, legalOwner.email);
    });
  };

  return (
    <Row className="rm-select-item" justify="start" align="middle" onClick={onSelect}>
      <ReactSVG src={UserIcon} />
      <div>
        <h4>{firstName}</h4>
        <h4>
          {fatherLastName} {motherLastName}
        </h4>
      </div>
    </Row>
  );
};

export default ResponsableModal;
