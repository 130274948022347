import ObservationRepository from '../repositories/ObservationRepository';

export default class ObservationServices {
  static async getObservations(requestId) {
    const [data, error] = await ObservationRepository.getObservations(requestId);
    return [data, error];
  }

  static async saveComment(comment, observationId, requestId) {
    const [data, error] = await ObservationRepository.saveComment(
      comment,
      observationId,
      requestId
    );
    return [data, error];
  }

  static async approveObservation(requestId, observationId) {
    const [data, error] = await ObservationRepository.changeStatus(
      requestId,
      observationId,
      'approve'
    );
    return [data, error];
  }

  static async rejectObservation(requestId, observationId) {
    const [data, error] = await ObservationRepository.changeStatus(
      requestId,
      observationId,
      'reject'
    );
    return [data, error];
  }

  static async sendObservation(requestId, observationId) {
    const [data, error] = await ObservationRepository.changeStatus(
      requestId,
      observationId,
      'send'
    );
    return [data, error];
  }

  static async getComments(requestId, observationId) {
    const [data, error] = await ObservationRepository.getComments(requestId, observationId);
    return [data, error];
  }

  static async deleteObservation(requestId, observationId) {
    const [data, error] = await ObservationRepository.deleteObservation(requestId, observationId);
    return [data, error];
  }

  static async updateObservation(requestId, observationId, jsonJustification) {
    const [data, error] = await ObservationRepository.updateObservation(
      requestId,
      observationId,
      jsonJustification
    );
    return [data, error];
  }
}
