import RoleServices from '../../../domain/usecases/RoleServices';

const ROLE_SUCCESS = 'ROLE_SUCCESS';
const ROLE_ERROR = 'ROLE_ERROR';
const ROLE_CLEAR = 'ROLE_ClEAR';
// Reducer

export default function roleReducer(state = {}, action) {
  switch (action.type) {
    case ROLE_SUCCESS:
      return { ...action.payload };
    case ROLE_ERROR:
      return { ...action.payload };
    case ROLE_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

// Actions

export const getRoles = () => async (dispatch) => {
  try {
    const [data, error] = await RoleServices.getRoles();
    if (data && error === null) {
      dispatch({
        type: ROLE_SUCCESS,
        payload: { roles: data },
      });
    } else {
      dispatch({
        type: ROLE_ERROR,
        payload: { roles: [] },
      });
    }
  } catch (error) {
    dispatch({
      type: ROLE_ERROR,
      payload: { roles: [] }, // put error
    });
  }
};

export const clearRoles = () => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_CLEAR,
    });
  } catch (error) {
    dispatch({
      type: ROLE_CLEAR,
    });
  }
};
