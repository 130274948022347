// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import {
  AMPLIFY_CLIENT_ID,
  AMPLIFY_DOMAIN,
  AMPLIFY_POOL_ID,
  AMPLIFY_REGION,
  REACT_URL,
} from './libs/constant';

const awsConfiguration = {
  Auth: {
    region: AMPLIFY_REGION,
    userPoolId: AMPLIFY_POOL_ID,
    userPoolWebClientId: AMPLIFY_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  oauth: {
    redirectSignIn: [REACT_URL],
    redirectSignOut: [REACT_URL],
    domain: AMPLIFY_DOMAIN,
    providers: ['Google'],
    scopes: ['email', 'openid'],
    responseType: 'token',
  },
};

export default awsConfiguration;
