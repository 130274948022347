import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Spin, Typography } from 'antd';

import { pathServer } from '../../utilities/Constant';

import CustomIcon from '../CustomIcon/CustomIcon';
import WUploadFile from '../UploadFile';
import AnnexesModal from '../AnnexesModal';
import FileItemFolder from '../FileItemFolder/FileItemFolder';
import { validateAttachement } from '../../utilities/Functions';

import './index.less';

export const MAX_SIZE_UPLOAD_FILE = 248000;
export const KB_1 = 1000;
const { Title, Text } = Typography;

export default function AnnexesFolder({
  title,
  disclaimer,
  attached,
  onSelectFile,
  folderSelected,
  setFolderSelected,
  onDeleteFile,
  viewCreateForm,
  errorMessages,
  isReadOnly = true,
  showIcons,
}) {
  const [detailFolder, setDetailFolder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorAnnexes, setErrorAnnexes] = useState(false);

  const getErrorFile = () => {
    const isValid = validateAttachement(attached);
    setErrorAnnexes(isValid);
  };
  const handleUpload = async (file) => {
    setLoading(true);
    await onSelectFile(file);
    setLoading(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    getErrorFile();
  };
  const selectFolder = (id) => {
    setFolderSelected(id);
    if (!viewCreateForm) setShowModal(true);
  };

  useEffect(() => {
    if (errorMessages) getErrorFile(attached);
  }, [errorMessages]);

  useEffect(() => {
    setDetailFolder(folderSelected ? attached.find((at) => at.id === folderSelected)?.files : []);
  }, [folderSelected, attached]);

  return (
    <div
      className={`annexes-folder-container ${!viewCreateForm && 'border-tabRequest'} ${
        errorMessages && errorAnnexes && !viewCreateForm && 'border-error'
      }`}
    >
      <div className="annexes-folder">
        <Row className="row-desc-header">
          <Col flex="1 1 auto">
            <Title className="title-annexes-folder" level={5}>
              {title}
            </Title>
          </Col>
          {!isReadOnly && (
            <Col flex="0 0 10px" className="icon-edit">
              <CustomIcon type="editRequest" />
            </Col>
          )}
        </Row>

        {disclaimer && (
          <span className="text-info-folder">
            <CustomIcon className="icon-disclaimer" type="ic_alert_withoutBackg" />
            {disclaimer}
          </span>
        )}
        <div className="detail-annexes-folder">
          {attached?.map((at) => {
            const isValid = at.required ? at.files.length > 0 : true;
            const errorFolder = errorAnnexes && !isValid;
            const nameImg = errorFolder ? 'folder-red' : 'folder';
            const hashError = errorAnnexes && !isReadOnly && at?.required && !at?.files?.length;

            return (
              <div
                key={at.description}
                className={`row-file-folder ${
                  !viewCreateForm ? 'blue-selected' : at.id === folderSelected && 'folder-selected'
                } `}
                onClick={() => selectFolder(at.id)}
              >
                <Col flex="0 0 30px">
                  <img src={`${pathServer.PATH_IMG}${nameImg}.png`} />
                </Col>
                <Col flex="1 1 200px" className="d-flex-column">
                  <Text>{at.description}</Text>
                  {errorFolder && (
                    <Text className="error-file">Seleccione folder y adjunte sus archivos</Text>
                  )}
                </Col>
                <Col flex="0 0 40px">
                  <Text className={hashError ? 'error-file' : ''}>
                    {viewCreateForm ? `(${at.files?.length})` : `${at.files?.length} doc`}
                  </Text>
                </Col>
              </div>
            );
          })}
        </div>
      </div>
      {viewCreateForm && (
        <>
          <div className="annexes-divider">
            <Divider type="vertical" style={{ height: '100% !important' }} />
          </div>
          <div className="annexes-files">
            <span className="text-info-folder">Documentos anexos:</span>
            <div className="detail-folder">
              {!folderSelected ? (
                <Text className="info-file"> Seleccione un folder</Text>
              ) : (
                <>
                  {detailFolder?.length === 0 && (
                    <Text className="info-file"> No hay documentos adjuntos ...</Text>
                  )}
                  {detailFolder?.map((at) => (
                    <FileItemFolder
                      key={at?.id}
                      file={at}
                      onDelete={onDeleteFile}
                      showIcons={showIcons}
                    />
                  ))}
                  {loading && (
                    <div>
                      <Spin size="small" />
                      <Text> Subiendo archivo</Text>
                    </div>
                  )}
                </>
              )}
              <div className="upload-file">
                {folderSelected && (
                  <WUploadFile
                    title="+ Subir archivo"
                    fileList={detailFolder}
                    handleUpload={handleUpload}
                    icon={null}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <AnnexesModal
          detailFolder={detailFolder}
          visible={showModal}
          onClose={handleCloseModal}
          title="Archivos adjuntos"
          isReadOnly={isReadOnly}
          handleUpload={handleUpload}
          showIcons={showIcons}
          onDelete={onDeleteFile}
          loading={loading}
        />
      )}
    </div>
  );
}

AnnexesFolder.displayName = 'AnnexesFolder';

AnnexesFolder.defaultProps = {
  title: 'Modelo de Contrato',
  viewCreateForm: true,
};
