import React from 'react';
import { Typography, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../configuration/routes/Routes';
import './index.less';

const { Title, Text } = Typography;

export default function HomeTemplate({ name }) {
  const history = useHistory();

  return (
    <div className="home-container">
      <div className="home-title-container">
        <div className="d-flex-column">
          <Title className="home-title">{`Bienvenido ${name},`}</Title>
          <Text className="home-subtitle">¿Cómo te sientes hoy?</Text>
        </div>

        <Button
          className="home-bn-newRequest"
          onClick={() => {
            history.push(Routes.REQUEST.NEW);
          }}
        >
          <div className="bn-newRequest-content">
            <span className="bn-newRequest-text">Crear Solicitud</span>
            <div className="bn-newRequest-plus">
              <Text className="newRequest-plus-text">+</Text>
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
}
