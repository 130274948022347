import React, { useState } from 'react';
import './index.less';
import { Typography, Input, Button } from 'antd';
import moment from 'moment';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Title, Text } = Typography;
const { TextArea } = Input;

const defaultNotification = {
  user: 'Brandon Carrol',
  description:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
  date: '12:30 h',
};
const NotificationCard = ({
  notification,
  onApprove,
  onReject,
  isNotUser,
  onDelete,
  onEdit,
  isLegalOwner,
}) => {
  const { userDetails = {}, creationDate = '', description = '', id = '' } = notification;
  const { name } = userDetails;
  const [text, setText] = useState(description ?? '');
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div className="notificationCard-container">
      <div className="notificationCard-firstSection">
        <div>°</div>
        <div className="notificationCard-text">
          <Title className="notificationCard-user">{name ?? ''}</Title>
          {!isEdit ? (
            <Text className="notificationCard-description">{description}</Text>
          ) : (
            <div className="container-edit-notification">
              <TextArea
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{ resize: 'none' }}
              />
              <div className="container-buttons">
                <Button
                  type="primary"
                  className="button-cancel-notification"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  className="button-edit-notification"
                  loading={false}
                  onClick={() => {
                    onEdit(id, { description: text });
                    setIsEdit(false);
                  }}
                >
                  Guardar
                </Button>
              </div>
            </div>
          )}
          <Text className="notificationCard-date">{moment(creationDate).format('HH:mm')}</Text>
        </div>
      </div>
      {notification?.state === 'New' && isLegalOwner && (
        <div className="notificationCard-iconContainer">
          <>
            <div className="notificationCard-iconAccept" onClick={onApprove}>
              <CustomIcon type="successAlert_big" />
              <Text className="notificationCard-date">Aprobar</Text>
            </div>
            <div className="notificationCard-icon" onClick={onReject}>
              <CustomIcon type="deleteNotification" />
              <Text className="notificationCard-date">Rechazar</Text>
            </div>
          </>
        </div>
      )}
      {notification?.state === 'Rejected' && (
        <div className="notificationCard-iconContainer">
          <CustomIcon type="deleteNotification" />
        </div>
      )}
      {notification?.state === 'Approved' && (
        <div className="notificationCard-iconContainer">
          <CustomIcon type="successAlert_big" />
        </div>
      )}
      {!isNotUser && notification?.state === 'New' && (
        <div className="notificationCard-iconContainer">
          <div className="notificationCard-iconAccept" onClick={onDelete}>
            <CustomIcon type="deleteRed" />
            <Text className="notificationCard-date">Eliminar</Text>
          </div>
          <div
            className="notificationCard-icon"
            onClick={() => {
              setText(description);
              setIsEdit(true);
            }}
          >
            <CustomIcon type="editNotification" />
            <Text className="notificationCard-date">Editar</Text>
          </div>
        </div>
      )}
    </div>
  );
};

NotificationCard.defaultProps = {
  notification: defaultNotification,
};
export default NotificationCard;
