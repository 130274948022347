import React from 'react';
import './index.less';

import { Row, Col, Typography } from 'antd';
import { CustomIcon } from '../../components/index';

const { Title } = Typography;

const IconDocumentation = () => {
  return (
    <div className="container-iconsDocumentation">
      <Title className="title-iconsDocumentation" level={4}>
        Documentación de íconos
      </Title>
      <Row className="rows-iconsDocumentation">
        <Col className="colTitle-iconsDocumentation col-iconsDocumentation  " span={12}>
          <Title level={5}>Icon</Title>
        </Col>
        <Col className="colTitle-iconsDocumentation col-iconsDocumentation" Col span={12}>
          <Title level={5}>Type</Title>
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" span={12}>
          <CustomIcon type="default" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          default
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" span={12}>
          <CustomIcon type="successfull" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          successfull
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" span={12}>
          <CustomIcon type="alert" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          alert
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="close" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          close
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="upload" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          upload
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="doc" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          doc
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteWhite" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteWhite
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="ic_alert_withoutBackg" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          ic_alert_withoutBackg
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="doc_big" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          doc_big
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="doc_small" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          doc_small
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="zoom" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          zoom
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="file" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          file
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="zoomOrange" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          zoomOrange
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="closeBlue" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          closeBlue
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="closeGrey" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          closeGrey
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="alertNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          alertNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="obsNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          obsNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="successNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          successNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="sentNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          sentNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="finishedNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          finishedNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteEmail" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteEmail
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteObservation" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteObservation
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteJustification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteJustification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="addEmail" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          addEmail
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="arrowLeft" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          arrowLeft
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestTable" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestTable
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestAll" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestAll
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestWait" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestWait
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestFinalized" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestFinalized
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestObservation" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestObservation
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestProcess" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestProcess
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestRevision" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestRevision
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="requestValidation" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          requestValidation
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="hidePassword" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          hidePassword
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="showPassword" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          showPassword
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="notification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          notification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="listUser" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          listUser
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="listUserBig" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          listUserBig
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="showRow" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          showRow
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="download" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          download
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="calendar" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          calendar
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="blueCalendar" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          blueCalendar
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="selectCalendar" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          selectCalendar
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="pdf" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          pdf
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="jpg" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          jpg
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="xls" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          xls
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="contract" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          contract
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="showDoc" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          showDoc
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="downloadDoc" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          downloadDoc
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteDoc" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteDoc
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="checkGreen" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          checkGreen
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="folderOrange" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          folderOrange
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="folderGrey" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          folderGrey
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="drive" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          drive
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="deleteRed" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          deleteRed
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="editNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          editNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="editObservation" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          editObservation
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="editRequest" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          editRequest
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="successAlert" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          successAlert
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="successAlert_big" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          successAlert_big
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="warningAlert" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          warningAlert
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="errorAlert" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          errorAlert
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="leftCalendar" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          leftCalendar
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="rightCalendar" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          rightCalendar
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="tabDetail" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          tabDetail
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="tabObservation" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          tabObservation
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="tabNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          tabNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="tabChronology" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          tabChronology
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="send" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          send
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={12}>
          <CustomIcon type="closeResponsableModal" />
        </Col>
        <Col className="col-iconsDocumentation" span={12}>
          closeResponsableModal
        </Col>
      </Row>
    </div>
  );
};
export default IconDocumentation;
