import React from 'react';
import './index.less';
import { Typography } from 'antd';

const { Text } = Typography;

const ButtonAdd = ({ title, onClick }) => {
  return (
    <button type="button" className="buttonAdd" onClick={onClick}>
      <Text className="buttonAdd-plus">+</Text>
      <Text className="buttonAdd-text">{title}</Text>
    </button>
  );
};
export default ButtonAdd;
