import React, { useState, useCallback, useEffect } from 'react';
import './index.less';
import { Typography, Spin } from 'antd';
import { ConfirmationModal, NotificationCard } from '../../../components';
import NotificationServices from '../../../../domain/usecases/NotificationServices';
import { formatDate } from '../../../utilities/Functions';

const { Text } = Typography;

const NotificationTabRequestTemplate = ({
  requestId,
  isNotUser,
  isLegalOwner,
  setNotificationsInParent,
  tabSelectedNumber,
}) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteNotificationModal, setShowDeleteNotificationModal] = useState(false);
  const [showRejectNotificationModal, setShowRejectNotificationModal] = useState(false);
  const [showApproveNotificationModal, setShowApproveNotificationModal] = useState(false);
  const [notificationSelected, setNotificationSelected] = useState(null);
  const today = new Date();
  const date = formatDate(today, 'DD-MM-YYYY');

  const getNotifications = useCallback(async () => {
    setIsLoading(true);
    const [data, error] = await NotificationServices.getNotifications(requestId);
    if (data && error === null) {
      setNotifications(data);
      setNotificationsInParent(data);
    } else {
      //  todo manage the errors
    }
    setIsLoading(false);
  }, [tabSelectedNumber]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const approveNotification = async () => {
    if (notificationSelected) {
      const notificationId = notificationSelected.id;
      setIsLoading(true);
      const [data, error] = await NotificationServices.approveNotification(
        requestId,
        notificationId
      );
      if (data && error === null) {
        await getNotifications();
        setShowApproveNotificationModal(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const rejectNotification = async () => {
    if (notificationSelected) {
      const notificationId = notificationSelected.id;
      setIsLoading(true);
      const [data, error] = await NotificationServices.rejectNotification(
        requestId,
        notificationId
      );
      if (data && error === null) {
        await getNotifications();
        setShowRejectNotificationModal(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const deleteNotification = async () => {
    setIsLoading(true);
    if (notificationSelected) {
      const notificationId = notificationSelected.id;
      setIsLoading(true);
      const [data, error] = await NotificationServices.deleteNotification(
        requestId,
        notificationId
      );
      if (data && error === null) {
        await getNotifications();
        setShowDeleteNotificationModal(false);
      }
      setIsLoading(false);
    }
  };

  const updateNotification = async (notificationId, jsonNotification) => {
    setIsLoading(true);
    const [data, error] = await NotificationServices.updateNotification(
      requestId,
      notificationId,
      jsonNotification
    );
    if (data && error === null) {
      await getNotifications();
    } else {
      setIsLoading(false);
    }
  };

  const newNotifications = notifications.filter((notification) => notification.state === 'New');
  const todayNotifications = notifications.filter(
    (notification) =>
      notification.state !== 'New' && formatDate(notification.creationDate, 'DD-MM-YYYY') === date
  );
  const OldNotifications = notifications.filter(
    (notification) =>
      notification.state !== 'New' && formatDate(notification.creationDate, 'DD-MM-YYYY') !== date
  );

  const onApprove = (notification) => {
    setNotificationSelected(notification);
    setShowApproveNotificationModal(true);
  };

  const onReject = (notification) => {
    setNotificationSelected(notification);
    setShowRejectNotificationModal(true);
  };

  const onDelete = (notification) => {
    setNotificationSelected(notification);
    setShowDeleteNotificationModal(true);
  };

  return (
    <div className="notificationTab-container">
      <Spin spinning={isLoading} tip="Cargando...">
        {OldNotifications.length === 0 &&
        todayNotifications.length === 0 &&
        newNotifications.length === 0 ? (
          <Text>No hay notificaciones disponibles .</Text>
        ) : (
          <>
            <Text className="notificationTab-type">Nuevo</Text>
            <div className="notifications-container">
              {newNotifications.length > 0 &&
                newNotifications.map((notification) => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    onApprove={() => onApprove(notification)}
                    onReject={() => onReject(notification)}
                    isNotUser={isNotUser}
                    onDelete={() => onDelete(notification)}
                    onEdit={updateNotification}
                    isLegalOwner={isLegalOwner}
                  />
                ))}
            </div>
            <Text className="notificationTab-type">{date}</Text>
            <div className="notifications-container">
              {todayNotifications.map((notification) => (
                <NotificationCard
                  key={`notification-${notification.description}`}
                  notification={notification}
                  onApprove={() => onApprove(notification)}
                  onReject={() => onReject(notification)}
                  isNotUser={isNotUser}
                  onDelete={() => onDelete(notification)}
                  onEdit={updateNotification}
                />
              ))}
            </div>
            <Text className="notificationTab-type">Más antiguo</Text>
            <div className="notifications-container">
              {OldNotifications.map((notification) => (
                <NotificationCard
                  key={`notification-old-${notification.description}`}
                  notification={notification}
                  onApprove={() => onApprove(notification)}
                  onReject={() => onReject(notification)}
                  isNotUser={isNotUser}
                  onDelete={() => onDelete(notification)}
                  onEdit={updateNotification}
                />
              ))}
            </div>
            {showDeleteNotificationModal && (
              <ConfirmationModal
                visible={showDeleteNotificationModal}
                icon="alertNotification"
                title="Confirmación de eliminación"
                subtitle="¿Estás seguro de eliminar esta notificación ?"
                buttonOne="Cancelar"
                buttonTwo="Aceptar"
                onClose={() => setShowDeleteNotificationModal(false)}
                onClickBnOne={() => setShowDeleteNotificationModal(false)}
                onClickBnTwo={deleteNotification}
                isLoading={isLoading}
              />
            )}
            {showRejectNotificationModal && (
              <ConfirmationModal
                visible={showRejectNotificationModal}
                icon="alertNotification"
                title="Confirmación de rechazo"
                subtitle="¿Estás seguro de rechazar esta notificación ?"
                buttonOne="Cancelar"
                buttonTwo="Aceptar"
                onClose={() => setShowRejectNotificationModal(false)}
                onClickBnOne={() => setShowRejectNotificationModal(false)}
                onClickBnTwo={rejectNotification}
                isLoading={isLoading}
              />
            )}
            {showApproveNotificationModal && (
              <ConfirmationModal
                visible={showApproveNotificationModal}
                icon="alertNotification"
                title="Confirmación de aprobación"
                subtitle="¿Estás seguro de aprobar esta notificación ?"
                buttonOne="Cancelar"
                buttonTwo="Aceptar"
                onClose={() => setShowApproveNotificationModal(false)}
                onClickBnOne={() => setShowApproveNotificationModal(false)}
                onClickBnTwo={approveNotification}
                isLoading={isLoading}
              />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

NotificationTabRequestTemplate.propTypes = {};

export default NotificationTabRequestTemplate;
