import React, { useState } from 'react';
import { Typography, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import {
  CustomIcon,
  RequestUsercard,
  ResponsableModal,
  InformativeModal,
  AnnexesFolder,
} from '../../../components/index';
import ReasignLegalOwnerServices from '../../../../domain/usecases/ReasignLegalOwnerServices';
import { contractModelType, personType } from '../../../utilities/Constant';

import CounterpartTemplate from '../../CounterPart/CounterPart';
import RequestDescriptionTemplate from '../../RequestDescription/RequestDescription';
import UploadModel from '../../../components/UploadModel';

import './index.less';

const { Text } = Typography;

export default function FirstStepDetailRequest({ firstStepDetailData, legalOwners, applicants }) {
  const [modalResponsable, setModalResponsable] = useState(false);
  const [successfulUpdateModal, setSuccessfulUpdateModal] = useState(false);
  const { isApplicant, isLegalOwner } = useSelector((state) => state.user);
  const [folderSelected, setFolderSelected] = useState(null);

  const {
    counterPart = [],
    attached = [],
    contractModel = {},
    requestId = '',
    companyRef,
    representativeRef,
    type,
    contractDate,
    legalOwner,
    createdBy,
  } = firstStepDetailData;
  const { origin, justify = {}, contractDrive = {} } = contractModel;
  const { modelName, id: modelDocId, documents = [] } = justify;
  const { modelName: repositoryName, url: repositoryUrl } = contractDrive;
  const counterPartLegal = counterPart.find((x) => x.type === personType.LEGAL);
  const counterPartNatural = counterPart.find((x) => x.type === personType.NATURAL);
  const { companies = [] } = counterPartLegal ?? {};
  const { legalRepresentatives = [] } = counterPartNatural ?? {};
  const isExternalContract = origin === contractModelType.EXTERNAL;

  const onResponsableModal = (value) => {
    setModalResponsable(value);
  };

  const onSetRequest = async (property, value) => {
    // eslint-disable-next-line no-param-reassign
    firstStepDetailData[property] = value; // todo review
  };

  const legalOwnerFound = legalOwners.find((x) => x.email === legalOwner);
  const applicantFound = applicants.find((x) => x.email === createdBy);

  const onSelectLegalOwner = async (item) => {
    if (requestId) {
      const [data, error] = await ReasignLegalOwnerServices.reassignLegalResponsible(
        { legalOwner: item.email },
        requestId
      );
      if (data && error === null) {
        setSuccessfulUpdateModal(true);
        setTimeout(() => {
          setSuccessfulUpdateModal(false);
        }, 2000);
      } else {
        //  todo manage the errors
      }
    }
  };

  return (
    <div className="detailRequest-body">
      <div className="detailRequest-containerSidebar">
        {applicantFound && <RequestUsercard key={applicantFound.id} user={applicantFound} />}
        {legalOwnerFound && (
          <RequestUsercard
            key={legalOwnerFound.id}
            user={legalOwnerFound}
            reasigne
            onReasigne={() => onResponsableModal(true)}
          />
        )}
      </div>
      <div className="detailRequest-containerContent">
        <div className="detailRequest-containerContent-head">
          <div>
            <Text>Tipo de solicitud</Text>
            <div className="detailRequest-iconText">
              <CustomIcon className="detailRequest-iconText-icon" type="contract" />
              <Text>{type}</Text>
            </div>
          </div>
          <div>
            <div className="detailRequest-iconText">
              <CustomIcon className="detailRequest-iconText-icon" type="blueCalendar" />
              <Text>Fecha Enviada</Text>
            </div>
            <Text>{contractDate}</Text>
          </div>
        </div>
        <RequestDescriptionTemplate
          data={firstStepDetailData}
          isReadOnly={isLegalOwner}
          editable={isApplicant}
        />
        <CounterpartTemplate
          companies={companies}
          legalRepresentatives={legalRepresentatives}
          companyRef={companyRef}
          representativeRef={representativeRef}
          isReadOnly={isLegalOwner}
        />
        <Row gutter={11} className="detailRequest-containerContent-mb20">
          <Col span={12}>
            <AnnexesFolder
              title="Anexos"
              disclaimer="Subir archivos con forma DOC, PDF, EXE"
              attached={attached}
              viewCreateForm={false}
              folderSelected={folderSelected}
              setFolderSelected={setFolderSelected}
              showIcons={{ remove: false }}
            />
          </Col>
          <Col span={12}>
            <UploadModel
              origin={origin}
              title="Modelo de contrato"
              file={
                isExternalContract
                  ? { filename: modelName, id: modelDocId }
                  : { filename: repositoryName, href: repositoryUrl }
              }
              contractModel={contractModel}
              justify={{
                title: 'Justificación de contrato',
                description: justify?.description || '',
                files: { documents },
              }}
            />
          </Col>
        </Row>
        <ResponsableModal
          legalOwners={legalOwners}
          visible={modalResponsable}
          onClose={() => setModalResponsable(false)}
          onSelectLegalOwner={onSelectLegalOwner}
          onSetRequest={onSetRequest}
        />
        <InformativeModal
          visible={successfulUpdateModal}
          title="Responsable actualizado"
          subtitle="Se actualizó exitosamente al responsable de la solicitud"
        />
      </div>
    </div>
  );
}
