import React, { useState } from 'react';
import { Typography, Row, Col, Spin, Form } from 'antd';
import { isEmpty } from 'lodash';

import {
  downloadFileUrl,
  generateFileUrl,
  getValueDefault,
  openFile,
  validateEmail,
} from '../../utilities/Functions';
import {
  docTypes,
  genderOptions,
  maxLengthDocTypes,
  personType,
  representativeProperty,
  validationDocTypes,
  validationType,
} from '../../utilities/Constant';
import { checkDNI, checkRUCNatural } from '../../utilities/Validation';
import { KB_1 } from '../DropZone/DropZone';

import WSelectInput from '../SelectInput';
import FileItem from '../FilteItem/FileItem';
import CustomIcon from '../CustomIcon/CustomIcon';
import WUploadFile from '../UploadFile';
import PTextInput from '../PTextInput';

import './index.less';

const { Title, Text } = Typography;

const RepresentativeCardEdit = ({
  className = '',
  title = '',
  representative,
  selectedIndexRepresentative,
  onSetRepresentative = () => {},
  editRepresentantive = () => {},
  isReadOnly,
  showDetail,
  onDeleteRepresentative = () => {},
  showDelete,
  companyIndex = '',
  person,
  errorMessages = false,
}) => {
  const {
    name,
    middleName,
    familyName,
    gender,
    documentType,
    documentNumber,
    ruc,
    address,
    powerVigency,
    email,
  } = representative;

  const [loading, setLoading] = useState(false);
  const errorUpload = person === personType.LEGAL && errorMessages && !powerVigency;

  const validationTypeDocument = (document) => {
    if (documentType === 'RUC') {
      return checkRUCNatural(document);
    }
    return checkDNI(document);
  };

  const checkEmail = () => validateEmail(getValueDefault(email));

  const handleRepresentative = (value, property) => {
    const representativeToUpdate = { ...representative };
    representativeToUpdate[property] = value;
    onSetRepresentative(representativeToUpdate, selectedIndexRepresentative);
  };

  const handleUpload = async (option) => {
    try {
      const { onSuccess, file } = option;

      if (file) {
        const { filename, id, url } = await generateFileUrl(file);
        const value = {
          url,
          filename,
          size: Math.round(file.size / KB_1),
          type: file.type,
          id,
        };
        handleRepresentative(value, representativeProperty.POWER_VIGENCY);
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onUploadFile = async (params) => {
    setLoading(true);
    await handleUpload(params);
    setLoading(false);
  };
  const pesonTypeIndex = (personT) => {
    let personTypeIndex = '1';
    if (personT === personType.LEGAL) personTypeIndex = '0';
    return personTypeIndex;
  };

  const idFieldItem = (propItem) => {
    const personTypeIndex = pesonTypeIndex(person);
    const idItem = `${companyIndex}${propItem}${title}${personTypeIndex}`;
    return idItem;
  };

  return showDetail ? (
    <div className={`representativeCard-container ${className}`}>
      <Row className="representativeCard-header">
        <Col
          className="cursor-pointer"
          span={12}
          onClick={() => editRepresentantive(representative)}
        >
          <Title className="representativeCard-title ">{title}</Title>
        </Col>
        {showDelete && !isReadOnly && (
          <CustomIcon
            type="deleteRepresentativeDetail"
            className="cursor-pointer"
            onClick={() => {
              onDeleteRepresentative(representative);
            }}
          />
        )}
      </Row>
      <Row className="representativeCard-row">
        <Col>
          <Form.Item
            name={idFieldItem(representativeProperty.NAME)}
            rules={[{ required: true, message: 'Ingrese nombres' }]}
          >
            <PTextInput
              placeholder="Nombres"
              value={getValueDefault(name)}
              onChange={(value) => handleRepresentative(value, representativeProperty.NAME)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={idFieldItem(representativeProperty.MIDDLE_NAME)}
            rules={[{ required: true, message: 'Ingrese apellido paterno' }]}
          >
            <PTextInput
              placeholder="Apellido Paterno"
              value={getValueDefault(middleName)}
              onChange={(value) => handleRepresentative(value, representativeProperty.MIDDLE_NAME)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={idFieldItem(representativeProperty.FAMILY_NAME)}
            rules={[{ required: true, message: 'Ingrese apellido materno' }]}
          >
            <PTextInput
              placeholder="Apellido Materno"
              value={getValueDefault(familyName)}
              onChange={(value) => handleRepresentative(value, representativeProperty.FAMILY_NAME)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={idFieldItem(representativeProperty.GENDER)}
            rules={[{ required: true, message: 'Seleccione género' }]}
          >
            <WSelectInput
              options={genderOptions}
              placeholder="Género"
              value={gender}
              onChange={(value) => handleRepresentative(value, representativeProperty.GENDER)}
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={idFieldItem(representativeProperty.DOCUMENT_TYPE)}
            rules={[{ required: true, message: 'Seleccione tipo de documento' }]}
          >
            <WSelectInput
              options={docTypes}
              placeholder="Tipo de Doc"
              value={documentType}
              onChange={(value) =>
                handleRepresentative(value, representativeProperty.DOCUMENT_TYPE)
              }
              readOnly={isReadOnly}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={idFieldItem(representativeProperty.DOCUMENT_NUMBER)}
            rules={[{ required: true, message: 'Ingrese número de documento' }]}
          >
            <PTextInput
              placeholder="Número de documento"
              value={getValueDefault(documentNumber)}
              onChange={(value) =>
                handleRepresentative(value, representativeProperty.DOCUMENT_NUMBER)
              }
              type="number"
              maxLength={
                isEmpty(getValueDefault(documentType)) ? 11 : maxLengthDocTypes[documentType]
              }
              readOnly={isReadOnly}
              validation={
                isEmpty(documentType)
                  ? validationType.RUC_NATURAL
                  : validationDocTypes[documentType]
              }
              errorMessage={`${
                errorMessages && getValueDefault(documentNumber) ? `${documentType} inválido` : ''
              }`}
              disabled={!documentType}
            />
          </Form.Item>
        </Col>
        {person === personType.NATURAL && (
          <Col>
            <Form.Item
              name={idFieldItem(representativeProperty.RUC)}
              rules={[{ required: true, message: 'Ingrese RUC' }]}
            >
              <PTextInput
                placeholder="RUC"
                value={getValueDefault(ruc)}
                onChange={(value) => handleRepresentative(value, representativeProperty.RUC)}
                type="number"
                maxLength={11}
                readOnly={isReadOnly}
                validation={validationType.RUC_NATURAL}
                errorMessage={`${
                  errorMessages && getValueDefault(ruc) ? 'RUC natural inválido' : ''
                }`}
              />
            </Form.Item>
          </Col>
        )}
        {!isReadOnly && (
          <Col className="item--large">
            <div className="row-upload-powerVigency">
              <div className="upload-file">
                <WUploadFile
                  title="Vigencia de poder"
                  handleUpload={onUploadFile}
                  icon={<CustomIcon type="uploadFile" />}
                  errorMessage={errorUpload && 'Debe seleccionar un archivo'}
                />
              </div>

              {loading ? (
                <div>
                  <Spin size="small" />
                  <Text> Subiendo archivo</Text>
                </div>
              ) : (
                powerVigency?.filename && (
                  <div className="filename-powerVigency">
                    <Row onClick={() => openFile(powerVigency)}>
                      <Text className="fileItem-text">{powerVigency?.filename}</Text>
                      <Col flex="0 0 26px">
                        <CustomIcon type="downloadFile" />
                      </Col>
                    </Row>
                  </div>
                )
              )}
            </div>
          </Col>
        )}
        {powerVigency && isReadOnly && (
          <Col>
            <div className="justification-filesContainerColOne">
              <div>
                <FileItem file={powerVigency} download={downloadFileUrl} />
              </div>
            </div>
          </Col>
        )}
        {person === personType.NATURAL && (
          <>
            <Col className="item--large">
              <Form.Item
                name={idFieldItem(representativeProperty.ADDRESS)}
                rules={[{ required: true, message: 'Ingrese dirección' }]}
              >
                <PTextInput
                  placeholder="Dirección"
                  value={getValueDefault(address)}
                  onChange={(value) => handleRepresentative(value, representativeProperty.ADDRESS)}
                  readOnly={isReadOnly}
                />
              </Form.Item>
            </Col>
            <Col flex="1 1 180px">
              <Form.Item
                name={idFieldItem(representativeProperty.EMAIL)}
                rules={[{ required: true, validator: checkEmail }]}
              >
                <PTextInput
                  placeholder="Correo de contacto"
                  value={getValueDefault(email)}
                  onChange={(value) => handleRepresentative(value, representativeProperty.EMAIL)}
                  readOnly={isReadOnly}
                  lowerCase
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </div>
  ) : (
    <div className="summaryCard-container">
      <div className="summary-title">
        <span>
          {name || '-'} {middleName || '-'} {familyName || '-'}
        </span>
      </div>
      <div className="summary-detail">
        <div className="text-detail">
          {email && <span>{email} | </span>}
          <span>{gender || '-'}</span>
          <span>|</span>
          <span
            className={`${
              errorMessages && !validationTypeDocument(documentNumber) && 'error-item'
            }`}
          >
            {documentType || '-'} : {documentNumber || '-'}
          </span>
          {ruc && (
            <span className={`${errorMessages && !checkRUCNatural(ruc) && 'error-item'}`}>
              | RUC: {ruc}
            </span>
          )}
          {address && <span>| {address}</span>}
          {powerVigency?.filename && (
            <>
              <span>|</span>
              <CustomIcon
                className="cursor-pointer"
                type="clip"
                onClick={() => downloadFileUrl(powerVigency)}
              />
            </>
          )}
          <span> {powerVigency?.filename} </span>
        </div>
        <div className="actions-detail">
          <CustomIcon
            type="editRepresentative"
            className="cursor-pointer"
            onClick={() => editRepresentantive(representative)}
          />
          {showDelete && !isReadOnly && (
            <CustomIcon
              type="deleteRepresentativeDetail"
              className="cursor-pointer"
              onClick={() => {
                onDeleteRepresentative(representative);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RepresentativeCardEdit;
