import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  SECTION_DETAIL,
  contractModelType,
  sectionDetail,
  valueInSpanish,
} from '../../../utilities/Constant';

import { SummaryRequestComponent, Calendar, AnnexesFolder } from '../../../components/index';
import RequestDescriptionTemplate from '../../RequestDescription/RequestDescription';
import {
  generateFileUrl,
  onSelectContractFile,
  onSelectPickerModel,
} from '../../../utilities/Functions';
import { validationsDetail } from './constant';
import { requestProperty } from '../../NewRequest/constants';
import { KB_1 } from '../../../components/DropZone/DropZone';

import UploadModel from '../../../components/UploadModel';
import CounterpartEditTemplate from '../../CounterPartEdit/CounterPartEdit';

import './index.less';

const defaultSection = {
  requestInformation: false,
  counterPartInformation: false,
  annexes: false,
  contractModel: false,
};
const DetailTabRequestTemplate = ({
  requestDetail,
  companyRef,
  representativeRef,
  requestDescriptionRef,
  observations,
  updateContract,
  onSetRequest,
  requestUpdate,
}) => {
  const {
    state,
    type = '',
    contractDate = '',
    expirationDate = '',
    counterPart = {},
    contractModel = {},
    contractDateCalendar = '',
    expirationDateCalendar = '',
    description,
    userArea,
    contractType = null,
    contractDateTimestamp,
    expirationDateTimestamp,
    attached = [],
    requestUrl = '',
  } = requestDetail;

  // eslint-disable-next-line no-shadow
  const { isApplicant } = useSelector((state) => state.user);
  const { origin, justify = {}, contractDrive = {} } = contractModel;
  const { modelName, id: modelDocId, documents = [] } = justify;
  const { modelName: repositoryName, url: repositoryUrl } = contractDrive;
  const [folderSelected, setFolderSelected] = useState(null);
  const [editDetail, setEditDetail] = useState(defaultSection);
  const { ID_REQUEST_INFORMATION, ID_COUNTERPART_INFORMATION, ID_ANNEXES, ID_CONTRACT_MODEL } =
    sectionDetail;
  const pendingObs = observations.filter((obs) => !obs.isEditable && obs.state !== 'Rejected');
  const isExternalContract = origin === contractModelType.EXTERNAL;
  const [errorMessages, showErrorMessages] = useState(false);
  const [sectionEdit, setSectionEdit] = useState([]);

  const validationUpdate = () => {
    const [hasError, edit] = validationsDetail(requestUpdate, requestDetail);
    showErrorMessages(hasError);
    setSectionEdit(edit);
    if (!hasError) {
      updateContract();
    }
  };

  const onSelectFile = async (option) => {
    try {
      const { onSuccess, file } = option;
      const { id, url } = await generateFileUrl(file);
      const value = {
        url,
        filename: file.name,
        size: Math.round(file.size / KB_1),
        type: file.type,
        id,
      };
      const newAttached = [...attached];
      const indexItem = newAttached.findIndex((a) => a.id === folderSelected);
      const currentFiles = newAttached[indexItem].files;
      newAttached[indexItem].files = [...currentFiles, value];
      onSetRequest(requestProperty.ATTACHED, [...newAttached]);
      onSuccess();
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteFile = (idItem) => {
    if (idItem) {
      const attachedToUpdate = [...attached];
      const indexItem = attachedToUpdate.findIndex((a) => a.id === folderSelected);
      const currentFiles = attachedToUpdate[indexItem].files?.filter((f) => f.id !== idItem);
      attachedToUpdate[indexItem].files = [...currentFiles];
      onSetRequest(requestProperty.ATTACHED, [...attachedToUpdate]);
    }
  };

  const onSelectContract = async (option) => {
    try {
      const { onSuccess, file } = option;

      if (file) {
        const imageUrl = URL.createObjectURL(file);
        const data = {
          origin,
          contractModel,
          onSetRequest,
        };
        await onSelectContractFile(file, imageUrl, data);
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSelectPickerFile = (params) => {
    onSelectPickerModel(params, contractModel, onSetRequest);
  };

  useEffect(() => {
    if (observations) {
      const requestInformation = pendingObs.some((obs) => obs.typeId === ID_REQUEST_INFORMATION);
      const counterPartInformation = pendingObs.some(
        (obs) => obs.typeId === ID_COUNTERPART_INFORMATION
      );
      const annexes = pendingObs.some((obs) => obs.typeId === ID_ANNEXES);
      const obsContractModel = pendingObs.some((obs) => obs.typeId === ID_CONTRACT_MODEL);
      setEditDetail({
        requestInformation,
        counterPartInformation,
        annexes,
        contractModel: obsContractModel,
      });
    }
  }, [observations]);

  return (
    <>
      <div className="detailTab-body">
        <div className="detailTab-containerSidebar">
          <SummaryRequestComponent
            user={{
              title: 'Resumen de solicitud',
              status: valueInSpanish[state],
              contractType: type,
              dateReceived: contractDate,
              dateSent: expirationDate,
              requestUrl,
            }}
          />
          <div className="detailTab-calendar">
            <Calendar
              type={2}
              startDate={contractDateCalendar}
              finishDate={expirationDateCalendar}
            />
          </div>
        </div>
        <div className="detailTab-containerContent">
          <RequestDescriptionTemplate
            data={{
              contractDate,
              expirationDate,
              description,
              userArea,
              contractType,
              contractDateTimestamp,
              expirationDateTimestamp,
            }}
            onSetRequest={onSetRequest}
            className="detailRequest-containerContent-requestDescription"
            isReadOnly={!(isApplicant && editDetail.requestInformation)}
            editable={isApplicant}
            observations={observations}
            ref={requestDescriptionRef}
            errorMessages={errorMessages && sectionEdit.includes(SECTION_DETAIL.request)}
            flowEdit
          />
          <CounterpartEditTemplate
            counterPart={counterPart}
            onSetRequest={onSetRequest}
            companyRef={companyRef}
            representativeRef={representativeRef}
            isReadOnly={!(isApplicant && editDetail.counterPartInformation)}
            errorMessages={errorMessages && sectionEdit.includes(SECTION_DETAIL.counterPart)}
          />
          <Row gutter={[20, 20]} className="detailTab-containerContent row-content">
            <Col flex={1}>
              <AnnexesFolder
                title="Anexos"
                disclaimer="Subir archivos con forma DOC, PDF, EXE"
                attached={attached}
                viewCreateForm={false}
                folderSelected={folderSelected}
                setFolderSelected={setFolderSelected}
                onSelectFile={onSelectFile}
                isReadOnly={!(isApplicant && editDetail.annexes)}
                errorMessages={errorMessages && sectionEdit.includes(SECTION_DETAIL.attached)}
                showIcons={{ remove: isApplicant && editDetail.annexes }}
                onDeleteFile={onDeleteFile}
              />
            </Col>
            <Col flex={1}>
              <UploadModel
                origin={origin}
                title="Modelo de contrato"
                file={
                  isExternalContract
                    ? { filename: modelName, id: modelDocId }
                    : { filename: repositoryName, href: repositoryUrl }
                }
                isReadOnly={!(isApplicant && editDetail.contractModel)}
                contractModel={contractModel}
                handleUpload={isExternalContract ? onSelectContract : onSelectPickerFile}
                justify={{
                  title: 'Justificación de contrato',
                  description: justify?.description || '',
                  files: { documents },
                }}
                onSetRequest={onSetRequest}
                errorMessages={errorMessages && sectionEdit.includes(SECTION_DETAIL.contractModel)}
              />
            </Col>
          </Row>
          {isApplicant && !isEmpty(pendingObs) && (
            <div>
              <Button
                className={`save-bn ${isEmpty(requestUpdate) && 'bnOneDisabled'}`}
                onClick={validationUpdate}
              >
                Guardar cambios
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailTabRequestTemplate;
