import RequestRepository from '../repositories/RequestRepository';

export default class RequestServices {
  static async saveRequest(request) {
    const [data, error] = await RequestRepository.saveRequest(request);
    return [data, error];
  }

  static async updateRequest(request, id) {
    const [data, error] = await RequestRepository.updateRequest(request, id);
    return [data, error];
  }

  static async sendContract(requestId) {
    const [data, error] = await RequestRepository.sendContract(requestId);
    return [data, error];
  }

  static async approvalRequest(requestId) {
    const [data, error] = await RequestRepository.approval(requestId);
    return [data, error];
  }

  static async validateRequest(requestId) {
    const [data, error] = await RequestRepository.editRequest({}, requestId, {
      workflow: 'validate',
    });
    return [data, error];
  }

  static async getRequestList() {
    const [data, error] = await RequestRepository.getRequestList();
    return [data, error];
  }

  static async getRequestById(requestId) {
    const [data, error] = await RequestRepository.getRequestById(requestId);
    return [data, error];
  }

  static async addObservations(requestId, observations) {
    const [data, error] = await RequestRepository.addObservations(requestId, observations);
    return [data, error];
  }

  static async shareDocument(requestId, emailsData) {
    const [data, error] = await RequestRepository.shareDocument(requestId, emailsData);
    return [data, error];
  }

  static async confirmRequest(requestId, emailsData) {
    const [data, error] = await RequestRepository.confirmRequest(requestId, emailsData);
    return [data, error];
  }

  static async cancel(requestId, payload) {
    const [data, error] = await RequestRepository.cancel(requestId, payload);
    return [data, error];
  }

  static async validate(requestId) {
    const [data, error] = await RequestRepository.validate(requestId);
    return [data, error];
  }
}
