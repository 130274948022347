import React from 'react';
import { Typography } from 'antd';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Title, Paragraph } = Typography;
const WItemNotification = ({ user }) => {
  const { title, description, hour } = user;
  return (
    <div className="container-itemNotification">
      <div className="alert" />
      <div className="container-main">
        <Title level={5} className="title">
          {title}
        </Title>
        <Paragraph className="description">{description}</Paragraph>
        <Paragraph className="hour">{hour}</Paragraph>
      </div>
      <div className="container-icon">
        <div className="mar-10">
          <CustomIcon type="deleteRed" className="icon" />
          <Paragraph className="text-icon">Eliminar</Paragraph>
        </div>
        <div className="mar-10">
          <CustomIcon type="editNotification" className="icon mt-4" />
          <Paragraph className="text-icon">Editar</Paragraph>
        </div>
      </div>
    </div>
  );
};

WItemNotification.propTypes = {};

export default WItemNotification;
