import { doGet, doPut, doPost, doDelete } from '../../data/api/ApiService';

import { Error } from '../../data/entities/Error';
import { Observation } from '../../data/entities/Observation';
import { Comment } from '../../data/entities/Comment';

export default class ObservationRepository {
  static async getObservations(requestId) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations`
      );
      const dataResponse = response?.data?.data;
      if (response.status === 200 && dataResponse) {
        data = dataResponse.map((item) => new Observation(item));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async saveComment(comment, observationId, requestId) {
    let data = [];
    let error = null;

    try {
      const response = await doPost(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations/${observationId}/comments`,
        comment
      );
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async updateObservation(requestId, observationId, jsonJustification) {
    let data = [];
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations/${observationId}`,
        jsonJustification
      );
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async changeStatus(requestId, observationId, type) {
    let data = [];
    let error = null;
    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations/${observationId}/${type}`
      );

      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async getComments(requestId, observationId) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations/${observationId}/comments`
      );
      const dataResponse = response?.data?.data;
      if (response.status === 200 && dataResponse) {
        data = dataResponse.map((item) => new Comment(item));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async deleteObservation(requestId, observationId) {
    let data = [];
    let error = null;

    try {
      const response = await doDelete(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations/${observationId}`
      );
      const dataResponse = response?.data;
      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
