import React, { useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { isEmpty, forEach, keys, isNull } from 'lodash';

import { Routes } from '../../configuration/routes/Routes';
import {
  companyProperty,
  companyValidation,
  contractModelType,
  personType,
  representativeProperty,
  representativeValidation,
  validationType,
} from '../../utilities/Constant';
import { RequestJson } from '../../../data/entities/RequestJson';
import RequestServices from '../../../domain/usecases/RequestServices';
import { hasError, validateData } from '../../utilities/Validation';

import { ReturnPage, StepComponent } from '../../components';
import FirstStepRequest from './FirstStepRequest';
import SecondStepRequest from './SecondStepRequest';
import ThirdStepRequest from './ThirdStepRequest';
import FourthStepRequest from './FourthStepRequest';
import FifthStepRequest from './FifthStepRequest';
import ConfirmationModal from '../../components/ConfirmationModal';
import InformativeModal from '../../components/InformativeModal';

import './index.less';

const componentMap = {
  1: FirstStepRequest,
  2: SecondStepRequest,
  3: ThirdStepRequest,
  4: FourthStepRequest,
  5: FifthStepRequest,
};

const validationDocTypeMap = {
  RUC: [validationType.REQUIRED, validationType.RUC_NATURAL],
  DNI: [validationType.REQUIRED, validationType.DNI],
};

function getRepresentativeValidation(representatives, isRequired = false, type) {
  const representativeVal = [];
  forEach(representatives, (re) => {
    if (keys(re).length > 1 || isRequired) {
      const { documentType } = re;
      const validation = { ...representativeValidation };
      if (!isEmpty(documentType)) {
        validation[representativeProperty.DOCUMENT_NUMBER] = validationDocTypeMap[documentType];
      }

      if (type === personType.NATURAL) {
        validation[representativeProperty.RUC] = [validationType.RUC_NATURAL];
        validation[representativeProperty.ADDRESS] = [validationType.REQUIRED];
        validation[representativeProperty.EMAIL] = [validationType.REQUIRED, validationType.EMAIL];
      } else {
        validation[representativeProperty.POWER_VIGENCY] = [validationType.REQUIRED];
      }
      representativeVal.push(validation);
    }
  });
  return representativeVal;
}

function getCounterPartValidation(counterPart) {
  const personLegalValidation = [];
  let personNaturalValidation = [];
  forEach(counterPart, (cp) => {
    const { type, companies, legalRepresentatives } = cp;
    let personVal = {};
    if (type === personType.LEGAL) {
      forEach(companies, (company) => {
        if (keys(company).length > 3) {
          personVal = { ...companyValidation };
          personVal[companyProperty.LEGAL_REPRESENTATIVES] = getRepresentativeValidation(
            company.legalRepresentatives,
            true,
            type
          );
          personLegalValidation.push(personVal);
        }
      });
    } else if (type === personType.NATURAL) {
      personNaturalValidation = getRepresentativeValidation(legalRepresentatives, false, type);
    }
  });

  return { personLegalValidation, personNaturalValidation };
}

function getCounterPartByType(counterPart, type) {
  return (
    counterPart.find((cp) => {
      return cp.type === type;
    }) ?? {}
  );
}

function attachmentValidations(attached) {
  const validations = attached.every(({ required, files }) => (required ? files.length > 0 : true));
  return !validations;
}

export function hasErrorContractModel(contractModel) {
  const error = false;
  if (contractModel.origin === contractModelType.REPOSITORY) {
    if (isEmpty(contractModel.contractDrive)) {
      return true;
    }
  } else if (
    isEmpty(contractModel.justify.modelName) ||
    isEmpty(contractModel.justify.description)
  ) {
    return true;
  }

  return error;
}

export function getErrorByStep(request, step) {
  const {
    type,
    legalOwner,
    contractDate,
    expirationDate,
    userArea,
    contractType,
    description,
    attached,
    counterPart,
    contractModel,
  } = request;
  let error;

  switch (step) {
    case 1:
      error = isEmpty(type) === true;
      break;
    case 2:
      error = isEmpty(legalOwner) === true;
      break;
    case 3:
      error =
        !contractDate ||
        !expirationDate ||
        contractDate >= expirationDate ||
        isEmpty(userArea) ||
        isNull(contractType) ||
        attachmentValidations(attached) ||
        isEmpty(description);
      break;
    case 4:
      // eslint-disable-next-line no-case-declarations
      const { personLegalValidation, personNaturalValidation } =
        getCounterPartValidation(counterPart);
      if (personLegalValidation.length > 0 || personNaturalValidation.length > 0) {
        const personLegal = getCounterPartByType(counterPart, personType.LEGAL);
        const personNatural = getCounterPartByType(counterPart, personType.NATURAL);
        const validatePersonLegal =
          personLegalValidation.length > 0
            ? validateData(personLegal.companies, personLegalValidation)
            : {};
        const validatePersonNatural =
          personNaturalValidation.length > 0
            ? validateData(personNatural.legalRepresentatives, personNaturalValidation)
            : {};

        if (!isEmpty(validatePersonLegal) && !isEmpty(validatePersonNatural)) {
          const hasErrorPL = hasError(validatePersonLegal);
          const hasErrorPN = hasError(validatePersonNatural);

          if (!hasErrorPL && !hasErrorPN) {
            error = false;
          } else {
            error = true;
          }
        } else {
          error =
            (!isEmpty(validatePersonLegal) && hasError(validatePersonLegal)) ||
            (!isEmpty(validatePersonNatural) && hasError(validatePersonNatural));
        }
      } else {
        error = true;
      }
      break;
    case 5:
      error = hasErrorContractModel(contractModel);
      break;
    default:
      error = false;
  }
  return error;
}

const NewRequestTemplate = ({
  currentStepIndex,
  setCurrentStepIndex,
  secondStepData,
  onSetRequest,
  request,
}) => {
  const [showInformativeModal, setShowInformativeModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessages, showErrorMessages] = useState(false);
  const { type, legalOwner, counterPart, contractModel, currentCounterPart } = request;

  const next = () => {
    if (activeButton) {
      if (currentStepIndex === 5) return;
      setCurrentStepIndex(currentStepIndex + 1);
      showErrorMessages(false);
    } else {
      showErrorMessages(true);
    }
  };

  const history = useHistory();

  const closed = () => {
    history.push(Routes.REQUEST.LIST);
  };

  const sendRequest = async () => {
    setIsProcessing(true);
    const [data, error] = await RequestServices.saveRequest(new RequestJson(request));
    if (data && error === null) {
      setTimeout(() => {
        unstable_batchedUpdates(() => {
          setIsProcessing(false);
          setShowConfirmationModal(false);
          setShowInformativeModal(true);
        });
        setTimeout(() => {
          setShowInformativeModal(false);
          history.push(Routes.REQUEST.LIST);
        }, 1500);
      }, 1000);
    } else {
      setIsProcessing(false);
      setShowConfirmationModal(false);
      setShowErrorModal(true);
      setTimeout(() => {
        setShowErrorModal(false);
      }, 3000);
    }
  };

  const firstStepData = {
    type,
    onSetRequest,
    errorMessages,
  };

  const secondStepDataS = {
    legalOwners: secondStepData,
    onSetRequest,
    legalOwner,
    errorMessages,
  };

  const thirdStepData = {
    request,
    onSetRequest,
    errorMessages,
  };

  const fourthStepData = {
    counterPart,
    onSetRequest,
    selectedPersonType: currentCounterPart,
    errorMessages,
  };

  const fifthStepData = {
    contractModel,
    onSetRequest,
    errorMessages,
  };

  const Element = componentMap[currentStepIndex];

  const onConfirmationModal = () => {
    if (activeButton) {
      setShowConfirmationModal((prevState) => !prevState);
    } else {
      showErrorMessages(true);
    }
  };
  const activeButton = !getErrorByStep(request, currentStepIndex);

  const onBack = () => {
    history.push(Routes.REQUEST.LIST);
  };

  return (
    <>
      <div className="header-newReqest">
        <div>
          <ReturnPage onChangeType={onBack} />
          <div className="title">Nueva Solicitud</div>
        </div>
        <StepComponent numberState={currentStepIndex} />
      </div>

      <div className="main-newRequest">
        <Element
          firstStepData={firstStepData}
          secondStepData={secondStepDataS}
          thirdStepData={thirdStepData}
          fourthStepData={fourthStepData}
          fifthStepData={fifthStepData}
        />
      </div>

      <div className="footer-button">
        {currentStepIndex === 1 && (
          <button type="button" onClick={closed}>
            Salir
          </button>
        )}
        {currentStepIndex > 1 && (
          <button type="button" onClick={() => setCurrentStepIndex(currentStepIndex - 1)}>
            Atrás
          </button>
        )}
        {((currentStepIndex > 1 && currentStepIndex < 5) || currentStepIndex === 1) && (
          <button className="bn-continuar" type="button" onClick={next}>
            Continuar
          </button>
        )}
        {currentStepIndex >= 5 && (
          <button type="button" className="bn-continuar" onClick={onConfirmationModal}>
            Finalizar
          </button>
        )}
      </div>

      <ConfirmationModal
        visible={showConfirmationModal}
        footer={null}
        icon="alertNotification"
        title="Confirmación de envío"
        subtitle="¿Estás seguro de enviar el contrato?"
        buttonOne="Cancelar"
        buttonTwo={isProcessing ? 'Enviando...' : 'Aceptar'}
        onClickBnOne={onConfirmationModal}
        onClickBnTwo={sendRequest}
        onClose={onConfirmationModal}
        isLoading={isProcessing}
      />

      <InformativeModal
        visible={showInformativeModal}
        footer={null}
        icon="successfull"
        title="Solicitud enviada"
        subtitle="Se envió satisfactoriamente tu solicitud"
        button="Aceptar"
        onClick={() => {}}
      />
      <InformativeModal
        title="¡Error!"
        visible={showErrorModal}
        subtitle="Hubo un error al registrar el contrato."
        icon="alert"
      />
    </>
  );
};

NewRequestTemplate.propTypes = {};

export default NewRequestTemplate;
