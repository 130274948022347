/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const WButtonIcon = ({ label, icon, onClick, className = 'button-icon', disabled = false, style = {} }) => {
  return (
    <button type="button" onClick={onClick} className={className} disabled={disabled} style={style}>
      {icon !== undefined && <CustomIcon type={icon} className="icon" />}
      {label}
    </button>
  );
};

WButtonIcon.propTypes = {};

export default WButtonIcon;