import React from 'react';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const WStateRequestChronology = ({ description, type }) => {
  const typeAlert = {
    success: { className: 'alert-success', icon: 'successAlert' },
    warning: { className: 'alert-warning', icon: 'warningAlert' },
    error: { className: 'alert-error', icon: 'errorAlert' },
  };
  return (
    <div className={`alert-request  ${typeAlert[type]?.className || 'alert-success'}`}>
      <CustomIcon type={typeAlert[type]?.icon || 'successAlert'} className="alet-typeIcon" />
      {description}
    </div>
  );
};

WStateRequestChronology.propTypes = {};

export default WStateRequestChronology;
