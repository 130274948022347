import React from 'react';
import { Steps } from 'antd';
import './index.less';

const { Step } = Steps;

const WProgressBar = ({ currentStepIndex, stepsQuantity }) => {
  return (
    <Steps current={currentStepIndex}>
      {[...Array(stepsQuantity).keys()].map((step) => (
        <Step key={step} />
      ))}
    </Steps>
  );
};

export default WProgressBar;
