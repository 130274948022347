import React from 'react';
import './index.less';
import { Typography } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Text } = Typography;

const MemberCard = ({ className, member, goProfile }) => {
  return (
    <div className={`${className} memberCard-container`}>
      <div className="memberCard-photo">
        <img className="memberCard-photod" src={member.photo} />
      </div>
      <Text className="memberdCard-name">{member.name}</Text>
      <Text className="memberdCard-occupation">{member.occupation}</Text>
      <div className="memberCard-footer">
        <div className="memberCard-footer-seeProfile" onClick={goProfile}>
          <CustomIcon type="seeProfile" />
          <Text className="memberCard-footer-seeProfileText">Ver perfil</Text>
        </div>
      </div>
    </div>
  );
};
export default MemberCard;
