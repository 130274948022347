import React from 'react';
import { Typography, Button } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';
import './index.less';
import ContainerCard from '../ContainerCard/ContainerCard';
import { getRolById } from '../../utilities/Functions';

const { Title, Text } = Typography;

const defaultUser = {
  name: 'Dana Carolina Saavedra Reátegui',
  occupation: 'Administradora | Lorem',
  campus: 'Sede San Isidro',
};

const RequestUsercard = ({ user, reasigne, onReasigne }) => {
  // eslint-disable-next-line no-unused-vars
  const { firstName = '', fatherLastName = '', motherLastName = '' } = user;
  return (
    <ContainerCard className="requestUserCard-container">
      <CustomIcon type="listUserBig" />
      {/* <Title className="requestUserCard-title">{`${firstName} ${fatherLastName} ${motherLastName}`}</Title> */}
      <Title className="requestUserCard-title">{user.name}</Title>
      <Text className="requestUserCard-occupation">{getRolById(user.roleId) ?? 'Area Legal'}</Text>
      <Text className={reasigne ? 'requestUserCard-campus' : 'requestUserCard-campus-noBn'}>
        {user.campus ?? defaultUser.campus}
      </Text>
      {reasigne && (
        <Button className="requestUserCard-bn" onClick={onReasigne}>
          Reasignar
        </Button>
      )}
    </ContainerCard>
  );
};

RequestUsercard.defaultProps = {
  user: defaultUser,
};

export default RequestUsercard;
