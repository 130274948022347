import React from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { pathServer } from '../../utilities/Constant';
import { Routes } from '../../configuration/routes/Routes';

import './index.less';

const MenuUser = ({ isAdmin, selected }) => {
  const history = useHistory();
  return (
    <div>
      <Menu defaultSelectedKeys={selected} mode="inline" theme="light" className="menuUser">
        <Menu.Item
          key="1"
          icon={
            <ReactSVG
              src={`${pathServer.PATH_ICONS}ic_menuHome.svg`}
              style={{ paddingTop: '10px', height: '53px' }}
            />
          }
          className="menu-option"
          onClick={() => {
            history.push(Routes.HOME_ADMIN);
          }}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={
            <ReactSVG
              src={`${pathServer.PATH_ICONS}ic_menuSolicitudes.svg`}
              style={{ paddingTop: '10px' }}
            />
          }
          className="menu-option"
          onClick={() => {
            history.push(Routes.REQUEST.LIST);
          }}
        >
          Solicitudes
        </Menu.Item>
        {false && (
          <Menu.Item
            key="3"
            icon={
              <ReactSVG
                src={`${pathServer.PATH_ICONS}ic_menuRepositorio.svg`}
                style={{ paddingTop: '10px' }}
              />
            }
            className="menu-option"
            onClick={() => {
              history.push(Routes.REPOSITORY);
            }}
          >
            Repositorio
          </Menu.Item>
        )}
        {false && isAdmin && (
          <Menu.Item
            key="4"
            icon={
              <ReactSVG
                src={`${pathServer.PATH_ICONS}ic_menuMiembros.svg`}
                style={{ paddingTop: '10px' }}
              />
            }
            className="menu-option"
            onClick={() => {
              history.push(Routes.MEMBERS);
            }}
          >
            Miembros
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default MenuUser;
