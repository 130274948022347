import React from 'react';
import { Select } from 'antd';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Option } = Select;

const WSelectInput = ({
  options,
  placeholder,
  value,
  onChange,
  className = 'select-input',
  dropdownClassName = '',
  disabled = false,
  defaultValue,
  readOnly,
}) => {
  return (
    <Select
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      className={`${className} ${readOnly && 'read-only'}`}
      suffixIcon={<SuffixIcon />}
      dropdownClassName={dropdownClassName}
      disabled={disabled}
    >
      {options.map((option) => (
        <Option value={option} key={`option-select-${option}}`}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

const SuffixIcon = () => {
  return <CustomIcon type="arrowBottom" className="icon" />;
};

export default WSelectInput;
