import React from 'react';
import { Typography, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import CustomIcon from '../CustomIcon/CustomIcon';
import './index.less';

const { Text } = Typography;
const defaultFile = {
  type: 'doc',
  name: 'Lorem ipsum.doc',
  size: '13',
};

const FileItem = ({ file, onClick, download, showDoc }) => {
  const { filename = '', size = 20 } = file;
  const fileNameArray = filename.split('.');
  const format = filename ? fileNameArray[fileNameArray.length - 1] : 'doc';

  return (
    <div className="fileItem-container" onClick={onClick}>
      <Row gutter={18}>
        <Col flex="0 0 46px" className="d-flex-center">
          <CustomIcon type={format} />
        </Col>
        <Col flex="1 1 50px">
          <Text className="fileItem-title">{isEmpty(filename) ? defaultFile.name : filename}</Text>
          <Text className="fileItem-size">{size} MB</Text>
        </Col>
        {showDoc && (
          <Col flex="0 0 20px">
            <CustomIcon type="showDoc" />
          </Col>
        )}
        <Col flex="0 0 23px" className="fileItem-download">
          <CustomIcon type="downloadFile" onClick={() => download(file)} className="icon" />
        </Col>
      </Row>
    </div>
  );
};

FileItem.defaultProps = {
  file: defaultFile,
  download: () => {},
  onClick: () => {},
  showDoc: false,
};
export default FileItem;
