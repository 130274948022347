import React from 'react';
import { Typography, Divider } from 'antd';

import { downloadFileUrl } from '../../utilities/Functions';

import FileItem from '../FilteItem/FileItem';

import './index.less';

const { Title, Text } = Typography;

const defaultJustification = {
  title: 'Justificación de Modelo',
  description:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo.',
  files: [
    {
      type: 'doc',
      name: 'Lorem ipsum.doc',
      size: '13',
    },
    {
      type: 'pdf',
      name: 'Lorem ipsum.pdf',
      size: '13',
    },
    {
      type: 'jpg',
      name: 'Lorem ipsum.jpg',
      size: '13',
    },
  ],
};

export const Justification = ({ title, description, files, className }) => {
  return (
    <div className={`justification-container ${className}`}>
      <Title className="justification-title">{title}</Title>
      <Text className="justification-labelDescription">Descripción</Text>
      <Text className="justification-description">{description}</Text>
      <div className="justification-filesContainer">
        <div className="justification-filesContainerColOne">
          {files.map((file, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`file-justification-one-${index}`}>
                {index < files.length / 2 && <FileItem file={file} download={downloadFileUrl} />}
                {index < (files.length - 1) / 2 && <Divider className="justification-divider" />}
              </div>
            );
          })}
        </div>
        <div className="justification-filesContainerColTwo">
          {files.map((file, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`file-justification-two-${index}`}>
                {index >= files.length / 2 && <FileItem file={file} download={downloadFileUrl} />}
                {index >= files.length / 2 && index !== files.length - 1 && (
                  <Divider className="justification-divider" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Justification.defaultProps = {
  title: defaultJustification.title,
  description: defaultJustification.description,
  files: defaultJustification.files,
};
export default Justification;
