import React from 'react';
import { Typography, Row, Col } from 'antd';

import CustomIcon from '../CustomIcon/CustomIcon';

import './index.less';

const { Text } = Typography;

const FileItemFolder = ({ file, onDelete, onDownload, showIcons }) => {
  const { download = true, remove = true } = showIcons;
  return (
    <Row align="middle" className="row-file-folder">
      <Col flex="0 0 20px">
        <CustomIcon className="icon-disclaimer" type="fileGray" />
      </Col>
      <Col flex="1 1 140px" className="second-item">
        <Text>{file.filename}</Text>
      </Col>
      <Col flex="0 0 20px" className="icon-item">
        {download && <CustomIcon type="downloadFile" onClick={() => onDownload(file.id)} />}
        {remove && <CustomIcon type="deleteDoc" onClick={() => onDelete(file.id)} />}
      </Col>
    </Row>
  );
};

export default FileItemFolder;
