import {
  COMPANY,
  REPRESENTATIVE,
  contractModelType,
  personType,
} from '../../app/utilities/Constant';
import { getDateTimestamp, getStringDateWithFormat } from '../../app/utilities/Functions';
import { ContractModelExternal, ContractModelOrigin } from './RequestJson';

export class Request {
  constructor(props) {
    this.key = props.Sort || props.sort;
    this.contractModel = getContractModel(props.contractModel);
    this.requestId = props.requestId;
    this.description = props.description;
    this.counterPart = getCounterPart(props.counterPart);
    this.contractDate = props.contractDate;
    this.expirationDate = props.expirationDate;
    this.notifications = props.notifications;
    this.observations = props.observations;
    this.legalOwner = props.legalOwner;
    this.createdBy = props.createdBy;
    this.entity = props.Id;
    this.requestUrl = props.requestURL;
    this.attached = props.attached;
    this.state = props.state?.value;
    this.stateId = props.state?.id;
    this.type = props?.type?.value || '';
    this.userArea = props?.userArea?.value || '';
    this.contractType = props?.contractType?.description;
    this.contractDateCalendar = getStringDateWithFormat(props.contractDate);
    this.expirationDateCalendar = getStringDateWithFormat(props.expirationDate);
    this.contractDateTimestamp = getDateTimestamp(props.contractDate);
    this.expirationDateTimestamp = getDateTimestamp(props.expirationDate);
    this.emailSent = props.emailSent;
    this.counterpartAnswer = props.counterpartAnswer;
    this.contractId = props.contractId;
    this.updateDate = props.updateDate;
  }
}

function getContractModel(contractModel) {
  if (contractModel.origin === contractModelType.REPOSITORY) {
    return new ContractModelOrigin(contractModel);
  }
  return new ContractModelExternal(contractModel);
}
function getCounterPart(counterPart) {
  return counterPart.map((cp) => {
    if (cp.type === personType.LEGAL) {
      return {
        ...cp,
        companies: cp.companies.map((c, index) => ({
          ...c,
          company: `${COMPANY} ${index + 1}`,
          legalRepresentatives: getLegalRepresentatives(c),
          lastRepresentative: c.legalRepresentatives.length,
        })),
        lastCompany: cp.companies.length,
      };
    }
    return {
      ...cp,
      legalRepresentatives: getLegalRepresentatives(cp),
      lastRepresentative: cp.legalRepresentatives.length,
    };
  });
}

function getLegalRepresentatives(cp) {
  return cp.legalRepresentatives.map((r, index) => ({
    ...r,
    representative: `${REPRESENTATIVE} ${index + 1}`,
  }));
}
