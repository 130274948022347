import FileRepository from '../repositories/FileRepository';

export default class FileServices {
  static async getPresingUrl(fileName) {
    const [data, error] = await FileRepository.getPresingUrl(fileName);
    return [data, error];
  }

  static async uploadFile(presignedUrl, file, type) {
    const [data, error] = await FileRepository.uploadFile(presignedUrl, file, type);
    return [data, error];
  }

  static async downloadFile(id) {
    const [data, error] = await FileRepository.downloadFileUrl(id);
    return [data, error];
  }
}
