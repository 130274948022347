import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Typography } from 'antd';

import './index.less';
import { isEmpty } from 'lodash';
import CustomIcon from '../CustomIcon/CustomIcon';

export const MAX_SIZE_UPLOAD_FILE = 50000000;

export const KB_1 = 1000;

const { Title } = Typography;

const ERROR_FILE_MAX_SIZE = 'El peso máximo del archivo es 50MB';
const ERROR_FILE_GENERIC = 'Error al subir file';
const errorFileMap = {
  'file-too-large': ERROR_FILE_MAX_SIZE,
};

export default function DropZone({
  title,
  disclaimer,
  onSelectFile,
  url,
  className,
  button,
  accept,
  multiple,
  disabled = false,
  errorMessage,
}) {
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!error) {
      setError(errorMessage);
    }
  }, [errorMessage]);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(file);
      onSelectFile(file, imageUrl);
    } else if (fileRejections.length > 0) {
      const { errors } = fileRejections[0];
      setError(errorFileMap[errors[0].code] || ERROR_FILE_GENERIC);
    }
  };

  const onFileDialogOpen = () => {
    if (!isEmpty(error)) {
      setError(null);
    }
  };

  const onDragEnter = () => {
    if (!isEmpty(error)) {
      setError(null);
    }
  };

  return (
    <div className={`${className}`}>
      <Dropzone
        maxSize={MAX_SIZE_UPLOAD_FILE}
        accept={accept}
        multiple={multiple}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        disabled={disabled}
        onFileDialogOpen={onFileDialogOpen}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            {title && (
              <Title
                className={disclaimer ? 'title-dropZone' : 'title-dropZone-wDisclaimer'}
                level={5}
              >
                {title}
              </Title>
            )}

            {disclaimer && (
              <div className="disclaimer-dropZone">
                <CustomIcon className="icon-disclaimer" type="ic_alert_withoutBackg" />
                {disclaimer}
              </div>
            )}
            <div
              className={`container-dropzone ${!isEmpty(error) && 'error-dropzone'}`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {image || url ? (
                <img src={image || url} alt="" width={250} />
              ) : (
                <div>
                  <CustomIcon type="upload" />
                </div>
              )}
              {!button ? (
                <div className="uploadText-dropZone">
                  <span>Suelta archivos para adjuntar, o </span>
                  <span className="blueBold">Cargar</span>
                </div>
              ) : (
                <Button className={`uploadBn-dropZone ${disabled ? 'upload-btn-disabled' : ''}`}>
                  Seleccionar
                </Button>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      {!isEmpty(error) && <label className="error-file">{error}</label>}
    </div>
  );
}

DropZone.displayName = 'DropZone';

DropZone.defaultProps = {
  title: 'Modelo de Contrato',
};
