import React from 'react';
import './index.less';
import { Typography, Modal } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Paragraph } = Typography;

const InformativeModal = ({ visible, icon, title, subtitle }) => {
  return (
    <Modal visible={visible} footer={null} className="informative-modal">
      <div className="container-informativeModal">
        <div className="container-items">
          <CustomIcon className="icon-informativeModal" type={icon} />
        </div>
        <Paragraph className="title-informativeModal">{title}</Paragraph>
        <text type="secondary" className="subtitle-informativeModal">
          {subtitle}
        </text>
      </div>
    </Modal>
  );
};

export default InformativeModal;
InformativeModal.defaultProps = {
  visible: false,
  icon: 'successNotification',
  title: 'Solicitud aceptada',
  subtitle: 'La solicitud fue aceptada exitosamente para su desarrollo',
};
