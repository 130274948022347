import React, { useState } from 'react';
import './index.less';
import { isEmpty } from 'lodash';
import { validationType } from '../../utilities/Constant';
import { checkDNI, checkRUC, checkRUCLegal, checkRUCNatural } from '../../utilities/Validation';

const validationMap = {
  [validationType.DNI]: checkDNI,
  [validationType.RUC]: checkRUC,
  [validationType.RUC_LEGAL]: checkRUCLegal,
  [validationType.RUC_NATURAL]: checkRUCNatural,
};

const WTextInput = ({
  placeholder,
  value,
  onChange,
  type = 'text',
  maxLength = null,
  readOnly = false,
  disabled = false,
  validation = '',
  errorMessage = '',
}) => {
  const [error, setError] = useState('');

  const handleChange = (event) => {
    event.stopPropagation();
    let result = event.target.value;
    if (type === 'number') {
      result = result.replace(/\D/g, '');
    }

    if (!isEmpty(error)) {
      setError('');
    }

    if (maxLength === null || (maxLength && result.length <= maxLength)) {
      onChange(result);
    }
  };

  const handleBlur = () => {
    if (!readOnly && !isEmpty(validation)) {
      const test = validationMap[validation](value);
      // console.log('handleBlur:', test);
      if (!test) setError(errorMessage);
    }
  };

  return (
    <div className="text-input">
      <input
        className={`text-input-value${isEmpty(error) ? '' : ' text-input-error'}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        type={type}
        required
        readOnly={readOnly}
        disabled={disabled}
      />
      <span className="text-input-label">{placeholder}</span>
      {!isEmpty(error) && <span className="error-label">{error}</span>}
    </div>
  );
};

export default WTextInput;
