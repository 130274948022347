import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.less';
import { Button, Typography, Divider, Tag } from 'antd';
import { isEmpty } from 'lodash';
import { Routes } from '../../configuration/routes/Routes';
import FirstStepDetailRequest from './FirstStepDetailRequest/FirstStepDetailRequest';
import SecondStepDetailRequest from './SecondStepDetailRequest/SecondStepDetailRequest';
import { ReturnPage, StepComponent, InformativeModal } from '../../components/index';
import RequestServices from '../../../domain/usecases/RequestServices';
import { valueInSpanish } from '../../utilities/Constant';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getValuesObservation, hasTrueValue } from '../../utilities/Functions';

const { Title, Text } = Typography;
const steps = ['Detalle de solicitud', 'Observaciones'];
const observationMessages = {
  title: 'Solicitud Observada',
  icon: 'obsNotification',
  subtitle: 'La solicitud fue observada, tiene observaciones de modelo',
};
export default function DetailRequestNewTemplate({
  onBack,
  requestDetail,
  legalOwners,
  applicants,
}) {
  const history = useHistory();
  const [numberStep, setNumberStep] = useState(1);
  const [modalInformativeRequest, setModalInformativeRequest] = useState(false);
  const [observations, setObservations] = useState([]);
  const [valObsModel, setValObsModel] = useState(false);
  const [modelObservationErrors, setModelObservationErrors] = useState({});
  const [showCompletionConfirmationModal, setShowCompletionConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasModelObservation = observations.some((x) => x.type.id === 1);
  const propsFinalize = hasModelObservation ? observationMessages : {};
  const companyRef = useRef();
  const representativeRef = useRef();

  const currentsStep = {
    1: FirstStepDetailRequest,
    2: SecondStepDetailRequest,
  };
  const Element = currentsStep[numberStep];
  const onNumberStep = async () => {
    if (numberStep === 1) {
      setNumberStep(2);
    } else {
      validateObsModel();
    }
  };

  const validateObsModel = () => {
    if (valObsModel) {
      const obsValues = getValuesObservation(observations, 1);
      if (hasTrueValue(obsValues)) updateObsIfNeeded(obsValues);
      else setShowCompletionConfirmationModal(true);
    }

    if (!valObsModel) {
      setShowCompletionConfirmationModal(true);
    }
  };

  const updateObsIfNeeded = (values) => {
    const updateParams = {
      file: values[0],
      justification: values[1],
    };

    if (updateParams.file || updateParams.justification) {
      updateModelObservations(updateParams);
    }
  };

  const finalizeRequest = async () => {
    setIsLoading(true);
    await addObservations();
    setIsLoading(false);
    setShowCompletionConfirmationModal(false);
  };

  const addObservations = async () => {
    const modelObservationFound = observations.find((x) => x.type.id === 1);

    let errors = {};

    if (modelObservationFound) {
      const { justification, file } = modelObservationFound;
      const { url } = file;
      if (isEmpty(justification) || isEmpty(url)) {
        errors = isEmpty(justification) ? { ...errors, justification: true } : errors;
        errors = isEmpty(url) ? { ...errors, file: true } : errors;
      }
    }

    if (isEmpty(errors)) {
      const [data, error] = await RequestServices.addObservations(requestDetail.requestId, {
        observations,
      });

      if (data && error === null) {
        setNumberStep(1);
        setModalInformativeRequest(true);
        setTimeout(() => {
          history.push(Routes.REQUEST.LIST);
        }, 2000);
      } else {
        //  todo manage the errors
      }
    } else {
      setModelObservationErrors({ ...errors });
    }
  };

  const updateModelObservations = (errors) => {
    setModelObservationErrors({ ...modelObservationErrors, ...errors });
  };

  return (
    <>
      <header>
        <div className="detailRequest-containerHeadRow">
          <ReturnPage labelButton="Regresar" onChangeType={onBack} />
          <StepComponent numberState={numberStep} steps={steps} />
        </div>
        <div className="detailRequest-containerHeadRow">
          <div className="detailRequest-containerHead-requestData">
            <Title className="detailRequest-containerHead-title">Solicitud</Title>
            <Text className="detailRequest-containerHead-number">
              N° {requestDetail.contractId}
            </Text>
            <Tag color="blue" className="detailRequest-containerHead-tag">
              {valueInSpanish[requestDetail.state]}
            </Tag>
          </div>
          <Button className="detailRequest-containerHead-bn" onClick={() => onNumberStep()}>
            {numberStep === 1 ? 'Continuar' : 'Finalizar'}
          </Button>
        </div>
        <Divider className="detailRequest-containerHead-divider" />
      </header>
      <Element
        firstStepDetailData={{ ...requestDetail, companyRef, representativeRef }}
        requestDetail={requestDetail}
        secondStepDetailData={modalInformativeRequest}
        legalOwners={legalOwners}
        onSetChange={(value) => setObservations(value)}
        applicants={applicants}
        modelObservationErrors={modelObservationErrors}
        setValObsModel={(value) => setValObsModel(value)}
        updateModelObservations={updateModelObservations}
      />
      <InformativeModal {...propsFinalize} visible={modalInformativeRequest} />
      {showCompletionConfirmationModal && (
        <ConfirmationModal
          visible={showCompletionConfirmationModal}
          footer={null}
          title="Confirmación de finalización"
          subtitle="¿Estás seguro de finalizar la solicitud?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClickBnOne={() => setShowCompletionConfirmationModal(false)}
          onClickBnTwo={finalizeRequest}
          onClose={() => setShowCompletionConfirmationModal(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
