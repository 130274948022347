import React, { useState } from 'react';
import './index.less';
import { Button, Typography, Table } from 'antd';
import { ButtonAdd, CustomIcon, FilesTree, QuickAccessFile } from '../../components';

const { Title } = Typography;

export default function RepositoryTemplate() {
  // eslint-disable-next-line no-unused-vars
  const [optionSelected, setOptionSelected] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [tableContent, setTableContent] = useState(0);
  const onSelectedOption = (value) => {
    setOptionSelected(value);
    if (value === 0) {
      setCollapsed(!collapsed);
    }
  };
  const onTableContent = (index) => {
    setTableContent(index);
  };
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Última Modificación',
      dataIndex: 'lastModification',
      key: 'lastModification',
    },
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Tamaño',
      dataIndex: 'size',
      key: 'size',
    },
  ];
  const files = [
    {
      name: ' Legal',
      lastModification: '18 Nov 2021',
      user: 'Yo',
      size: '-',
      files: [
        {
          name: ' Legal',
          lastModification: '18 Nov 2021',
          user: 'Yo',
          size: '-',
        },
        {
          name: ' Legal',
          lastModification: '18 Nov 2021',
          user: 'Yo',
          size: '-',
        },
      ],
    },
    {
      name: ' Finanzas',
      lastModification: '18 Nov 2021',
      user: 'Vincent Lawson',
      size: '-',
      files: [
        {
          name: ' Legal',
          lastModification: '18 Nov 2021',
          user: 'Yo',
          size: '-',
        },
      ],
    },
    {
      name: ' Finanzas',
      lastModification: '18 Nov 2021',
      user: 'Patrick Ross',
      size: '40 MB',
    },
    {
      name: ' Legal',
      lastModification: '18 Nov 2021',
      user: 'Yo',
      size: '-',
      files: [
        {
          name: ' Finanzas',
          lastModification: '18 Nov 2021',
          user: 'Yo',
          size: '-',
        },
        {
          name: ' Legal',
          lastModification: '18 Nov 2021',
          user: 'Yo',
          size: '-',
        },
      ],
    },
  ];

  return (
    <div>
      <div className="repository-containerTitle">
        <Title className="repository-title">Repositorio</Title>
        <Button className="repository-bn-actualize">Actualizar</Button>
      </div>
      <div className="repository-containerBody">
        <div className="repository-body-sideBar">
          <div className="body-sideBar-menu">
            <CustomIcon className="body-sideBar-iconDrive" type="drive" />
            <div>Google Drive</div>
          </div>
          <div className="repository-menuItem" onClick={() => onSelectedOption(0)}>
            <CustomIcon
              className="repository-menuItem-iconCollapsed"
              type={collapsed ? 'collapsed' : 'noCollapsed'}
            />
            <CustomIcon className="repository-menuItem-icon" type="repositoryRepository" />
            <div>Repositorio</div>
          </div>
          {collapsed && <FilesTree files={files} className="repository-body-menu" />}
          <div className="repository-menuItem" onClick={() => onSelectedOption(1)}>
            <CustomIcon className="repository-menuItem-icon" type="repositoryShared" />
            <div>Compartido</div>
          </div>
          <div className="repository-menuItem" onClick={() => onSelectedOption(2)}>
            <CustomIcon className="repository-menuItem-icon" type="repositoryFavorites" />
            <div>Favoritos</div>
          </div>
          <div className="repository-menuItem" onClick={() => onSelectedOption(3)}>
            <CustomIcon className="repository-menuItem-icon" type="repositoryRecents" />
            <div>Reciente</div>
          </div>
          <ButtonAdd title="Agregar" onClick={() => {}} />
        </div>
        <div className="repository-body-content">
          <div className="repository-body-quickAccess">
            Acceso Rápido
            <div className="temp-container-files">
              {files.map((file, index) => {
                return (
                  <div key={file.name} onClick={() => onTableContent(index)}>
                    <QuickAccessFile file={file} />
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <Table
              className="repository-body-table"
              columns={columns}
              dataSource={files[tableContent]?.files}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
