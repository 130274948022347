import { doGet } from '../../data/api/ApiService';

import { Error } from '../../data/entities/Error';
import { User } from '../../data/entities/User';

export default class UserRepository {
  static async getUsers() {
    let data = [];
    let error = null;

    try {
      const response = await doGet(`${process.env.REACT_APP_BASE_URL_SERVICES}/users`);
      const dataResponse = response.data.response;

      if (response.status === 200 && dataResponse) {
        data = dataResponse.payload.map((item) => new User(item));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async getUsersByRolId(rolId) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(
        `${process.env.REACT_APP_BASE_URL_SERVICES}/users/roles/${rolId}`,
        {}
      );
      const dataResponse = response.data.data;

      if (response.status === 200 && dataResponse) {
        data = dataResponse.map((item) => new User(item));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async getUserByEmail(email) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(`${process.env.REACT_APP_BASE_URL_SERVICES}/users/${email}`);
      const dataResponse = response.data.data;

      if (response.status === 200 && dataResponse) {
        data = new User(dataResponse);
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
