import React from 'react';
import './index.less';

const WTextButton = ({ label, onClick }) => {
  return (
    <button onClick={onClick} type="button" className="text-button">
      {label}
    </button>
  );
};

export default WTextButton;
