import { Col, Row, Collapse, Typography, Avatar } from 'antd';

import React from 'react';
import { ReactSVG } from 'react-svg';
import { pathServer } from '../../utilities/Constant';
import './index.less';

const { Title } = Typography;
const { Panel } = Collapse;

const WCommentCard = ({ user }) => {
  return (
    <div className="card-container-main">
      <Col className="title" span={24}>
        <Title level={3}>Observaciones</Title>
      </Col>
      <Collapse
        defaultActiveKey={['2']}
        expandIconPosition="right"
        ghost
        expandIcon={({ isActive }) => (
          <Row>
            <ReactSVG
              className="svg-container"
              src={`${pathServer.PATH_ICONS}caretOutilined.svg`}
              style={{
                transform: `rotate(${isActive ? '0deg ' : '-180deg'}) `,
              }}
            />
          </Row>
        )}
      >
        <Panel header="Ver comentarios anteriores">
          <Col className="comment-container" span={24}>
            {user.map(({ title, date, descripcion, icon } = user) => (
              <Row key={title}>
                <Col flex="32px">
                  <Avatar size={38} src={icon || ` ${pathServer.PATH_ICONS}avatar.svg`} />
                </Col>
                <Col className="descripcion-container">
                  <Col>
                    <Title level={4}>{title}</Title>
                  </Col>
                  <Col className="date">{date}</Col>
                  <Col className="descripcion">{descripcion}</Col>
                </Col>
              </Row>
            ))}
          </Col>
        </Panel>
      </Collapse>
    </div>
  );
};

WCommentCard.propTypes = {};

export default WCommentCard;
