/* eslint-disable prettier/prettier */
import { doPost } from '../../data/api/ApiService';
import { Error } from '../../data/entities/Error';

export default class AuthRepository {
  static async authRequest(userData) {
    const authenticationType = '02';
    const request = {
      authentication: {
        applicationId: process.env.REACT_APP_APPLICATION_ID,
        authenticationType,
        userData,
      },
    };
    let data = [];
    let error = null;

    try {
      const response = await doPost(`${process.env.REACT_APP_AUTH_SERVICE}`, request);
      const dataResponse = response.data;
      if (response.status === 200 && dataResponse && dataResponse.authenticationResponse?.authenticationState ===200 ) {
        data = true;
        localStorage.setItem('token', dataResponse.authenticationResponse.authenticationData.securityToken)
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
