import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const FilesTree = React.forwardRef(({ className, files }, ref) => {
  const [active, setActive] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  const onCollapsed = (index) => {
    if (active !== index) {
      setActive(index);
      setCollapsed(true);
    } else {
      setActive(index);
      setCollapsed(!collapsed);
    }
  };
  return (
    <div className={`filesTree_container ${className}`} ref={ref}>
      <div className="contracts-list_container">
        {files?.map((item, index) => (
          <div key={item.id} className={item.files ? 'contract-item' : 'contract-item empty-file'}>
            <div
              className="d-flex w-100"
              onClick={() => {
                onCollapsed(index);
              }}
            >
              {active === index && item.files && collapsed ? (
                <CustomIcon className="icon-collapse" type="collapsed" />
              ) : (
                <CustomIcon
                  type="noCollapsed"
                  className={item.files ? 'icon-collapse' : 'icon-collapse-empty'}
                />
              )}
              <CustomIcon type="folderGrey" className="icon-container" />
              <p>{item.name}</p>
            </div>
            {active === index && item.files && collapsed && (
              <div className="content-children">
                {item.files.map((c) => (
                  <div key={`file-${c.name}`} className="d-flex">
                    <CustomIcon type="folderGrey" className="icon-container" />
                    <p>{c.name}</p>
                  </div>
                ))}
              </div>
            )}
            <div />
          </div>
        ))}
      </div>
    </div>
  );
});

FilesTree.displayName = 'FilesTree';

FilesTree.defaultProps = {
  className: '',
};

FilesTree.propTypes = {
  className: PropTypes.string,
};

export default FilesTree;
