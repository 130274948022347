import React, { useState } from 'react';
import { Row, Col, Typography, Divider, Input } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';
import './index.less';

const { Title } = Typography;

const MyUnit = ({ files }) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const onCurrentFile = (value) => {
    setCurrentFile(value);
  };

  const onSelectedDoc = (index) => {
    setSelectedDoc(index);
  };

  return (
    <>
      <div
        className={
          selectedDoc !== null
            ? 'myUnit-containerBody'
            : 'myUnit-containerBody-docSelected myUnit-containerBody'
        }
      >
        <div className="container-searchBar">
          <Title className="title-searchBar" level={4}>
            Mi unidad
          </Title>
          <Input
            className="searchBar-MyUnit"
            placeholder="Buscar modelo"
            suffix={<CustomIcon type="zoomOrange" />}
          />
        </div>
        <Divider className="divider-searchBar" />
        <Row className="container-files">
          {files?.map((file, index) => {
            return (
              <Col key={file.name}>
                <div
                  className="container-file"
                  onClick={() => {
                    onCurrentFile(index);
                    onSelectedDoc(null);
                  }}
                >
                  <CustomIcon className="file-icon" type="file" />
                  <span className="file-name">{file.name}</span>
                </div>
              </Col>
            );
          })}
        </Row>
        <Title className="title-files" level={5}>
          Archivos
        </Title>
        <Row>
          {files[currentFile]?.archivos.map((item, index) => {
            return (
              <Col key={`file-upload-${item.name}`}>
                <div
                  className={
                    index === selectedDoc ? 'container-doc container-docSelected' : 'container-doc'
                  }
                  onClick={() => {
                    onSelectedDoc(index);
                  }}
                >
                  <CustomIcon type="doc_big" />
                  <div
                    className={
                      index === selectedDoc
                        ? 'container-docName container-docNameSelected'
                        : 'container-docName'
                    }
                  >
                    <CustomIcon className="doc-icon" type="doc_small" />
                    <span className="doc-name">{item.name}</span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      {selectedDoc !== null && (
        <Row className="myUnit-container-footer">
          <Col span={1}>
            <CustomIcon
              className="footer-close"
              type="closeBlue"
              onClick={() => onSelectedDoc(null)}
            />
          </Col>
          <Col span={20}>
            <span className="footer-textStyle">Modelo Seleccionado</span>
          </Col>
          <Col span={3}>
            <span className="footer-textStyle">SELECCIONAR</span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MyUnit;

MyUnit.defaultProps = {
  files: {},
};
