import React from 'react';
import { Card, Typography, Col, Row } from 'antd';
import './index.less';

const { Title } = Typography;
const WCardInfo = ({ user }) => {
  const { title, name, lastName1, lastName2, email, infoExtra, infoExtra2 } = user;

  return (
    <div className="box-container">
      <div className="info-container">
        <Title level={4}>{title}</Title>
      </div>
      <Card className="card-container">
        <Row className="object1-container ">
          <WParagraph subtitle="Nombre" user={name} />

          <WParagraphCompuest
            subCompuest={['Ap. Paterno', 'Ap. Materno']}
            user={[lastName1, lastName2]}
          />
          <WParagraph subtitle="Correo" user={email} />
          <WParagraph subtitle="Lorem" user={infoExtra} />
          <WParagraph subtitle="Lorem" user={infoExtra2} />
        </Row>
      </Card>
    </div>
  );
};

const WParagraph = ({ subtitle, user }) => {
  return (
    <>
      <Col span={24} className="subtitle">
        {subtitle}
      </Col>
      <Col span={24} className="descripcion">
        {user}
      </Col>
    </>
  );
};
const WParagraphCompuest = ({ subCompuest, user }) => {
  return (
    <>
      <Row className="object1-container ">
        <Col span={12}>
          <WParagraph subtitle={subCompuest[0]} user={user[0]} />
        </Col>
        <Col style={{ paddingLeft: ' 9px' }} span={12}>
          <WParagraph subtitle={subCompuest[1]} user={user[1]} />
        </Col>
      </Row>
    </>
  );
};
WCardInfo.propTypes = {};

export default WCardInfo;
