import { companyProperty, representativeProperty } from '../../../utilities/Constant';
import { getValueDefault } from '../../../utilities/Functions';

const {
  NAME,
  MIDDLE_NAME,
  FAMILY_NAME,
  DOCUMENT_NUMBER,
  RUC,
  ADDRESS,
  DOCUMENT_TYPE,
  GENDER,
  EMAIL,
} = representativeProperty;

const data = {
  legalPerson: {
    items: [
      companyProperty.NAME,
      companyProperty.RUC,
      companyProperty.SOCIAL_REASON,
      companyProperty.ELECTRONIC_CERTIFICATE,
      companyProperty.ADDRESS,
      companyProperty.REGISTER_OFFICE,
      companyProperty.EMAIL,
    ],
    select: [companyProperty.TYPE],
    natualPerson: {
      items: [NAME, MIDDLE_NAME, FAMILY_NAME, DOCUMENT_NUMBER, RUC, ADDRESS],
      select: [DOCUMENT_TYPE, GENDER],
    },
  },
  naturalPerson: {
    items: [NAME, MIDDLE_NAME, FAMILY_NAME, DOCUMENT_NUMBER, RUC, ADDRESS, EMAIL],
    select: [DOCUMENT_TYPE, GENDER],
  },
};

export const setInitialValue = (personLegal, personNatural) => {
  const savedData = {};

  personLegal?.companies.forEach((c, indexCompany) => {
    const currentC = personLegal.companies[indexCompany];
    data.legalPerson.items.forEach((prop) => {
      savedData[`${prop}${indexCompany}`] = getValueDefault(currentC[prop]);
    });
    data.legalPerson.select.forEach((prop) => {
      if (getValueDefault(currentC[prop])) {
        savedData[`${prop}${indexCompany}`] = getValueDefault(currentC[prop]);
      }
    });

    currentC[companyProperty.LEGAL_REPRESENTATIVES].forEach((r, index) => {
      const legalRepresentative = currentC[companyProperty.LEGAL_REPRESENTATIVES][index];
      data.legalPerson.natualPerson.items.forEach((prop) => {
        savedData[`${indexCompany}${prop}${r.representative}${0}`] = getValueDefault(
          legalRepresentative[prop]
        );
      });
      data.legalPerson.natualPerson.select.forEach((prop) => {
        if (getValueDefault(legalRepresentative[prop])) {
          savedData[`${indexCompany}${prop}${r.representative}${0}`] = getValueDefault(
            legalRepresentative[prop]
          );
        }
      });
    });
  });
  personNatural?.legalRepresentatives.forEach((item) => {
    data.naturalPerson.items.forEach((prop) => {
      savedData[`${prop}${item.representative}${1}`] = getValueDefault(item[prop]);
    });
    data.naturalPerson.select.forEach((prop) => {
      if (getValueDefault(item[prop])) {
        savedData[`${prop}${item.representative}${1}`] = getValueDefault(item[prop]);
      }
    });
  });
  return savedData;
};
