import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import useDrivePicker from 'react-google-drive-picker';
import { isEmpty } from 'lodash';

import CustomIcon from '../CustomIcon/CustomIcon';
import { configurationPicker } from '../../utilities/Constant';

import './index.less';

export const MAX_SIZE_UPLOAD_FILE = 248000;
export const KB_1 = 1000;
const { Title } = Typography;

export default function PickerDrive({
  title,
  disclaimer,
  className,
  errorMessage,
  handleClick,
  disabled,
}) {
  const [openPicker, data] = useDrivePicker();

  const handleOpenPicker = () => {
    const googleToken = localStorage.getItem('pickerToken');

    openPicker({
      ...configurationPicker,
      ...(googleToken ? { token: googleToken } : {}),
      callbackFunction: (res) => {
        if (res.action === 'picked') {
          handleClick(res.docs[0]);
        }
      },
    });
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem('pickerToken', data?.access_token);
    }
  }, [data]);

  return (
    <>
      <div className={`${className}`}>
        <section>
          {title && (
            <Title
              className={disclaimer ? 'title-dropZone' : 'title-dropZone-wDisclaimer'}
              level={5}
            >
              {title}
            </Title>
          )}
          {disclaimer && (
            <div className="disclaimer-dropZone">
              <CustomIcon className="icon-disclaimer" type="ic_alert_withoutBackg" />
              {disclaimer}
            </div>
          )}
          <div className={`container-dropzone ${!isEmpty(errorMessage) && 'error-dropzone'}`}>
            <div>
              <CustomIcon type="upload" />
            </div>
            <Button
              className={`uploadBn-dropZone ${disabled ? 'upload-btn-disabled' : ''}`}
              onClick={handleOpenPicker}
              disabled={disabled}
            >
              Seleccionar
            </Button>
          </div>
        </section>
        {!isEmpty(errorMessage) && <label className="error-file">{errorMessage}</label>}
      </div>
    </>
  );
}

PickerDrive.displayName = 'PickerDrive';

PickerDrive.defaultProps = {
  title: 'Modelo de Contrato',
};
