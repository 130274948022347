/* eslint-disable prettier/prettier */
import {UserDetail} from './UserDetail'

export class Notification {
    constructor(props) {
      this.id = props.notificationId ?? "";
      this.requestId = props.requestId;
      this.description = props.description;
      this.state = props.state?.value ?? '';
      this.type = props.type;
      this.createdBy = props.createdBy;
      this.creationDate = props.creationDate;
      this.subtype = props.subtype;
      this.title =  props.title;
      this.updateDate = props.updateDate;
      this.updatedBy = props.updatedBy;
      this.userDetails = new UserDetail(props.userDetails ?? {})
    }
}