import React from 'react';
import { Row } from 'antd';
import './index.less';

const WOutlinedButton = ({ label, onClick, icon }) => {
  return (
    <button onClick={onClick} type="button" className="outlined-button">
      <Row align="middle" justify="center" gutter={[5, 5]}>
        {icon}
        <span>{label}</span>
      </Row>
    </button>
  );
};

export default WOutlinedButton;
