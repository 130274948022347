import React from 'react';
import './index.less';
import { Typography, Row, Col, Pagination } from 'antd';
import MemberCard from '../../components/MemberCard/MemberCard';

const { Title } = Typography;

export default function MembersTemplate() {
  const members = [
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Rony Jose Aldave',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Brandon Willis',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Brandon Willis',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Kyle Cook',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Philip Hayes',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Chris Holland',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Philip Hayes',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Jonathan Mendoza',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Jeremy Schneider',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Keanu Wade',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Rony Jose Aldave',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Brandon Willis',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Brandon Willis',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Kyle Cook',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Philip Hayes',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Chris Holland',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Philip Hayes',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Jonathan Mendoza',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Jeremy Schneider',
      occupation: 'Lorem ipsum',
    },
    {
      photo:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU',
      name: 'Keanu Wade',
      occupation: 'Lorem ipsum',
    },
  ];
  return (
    <div>
      <Title className="membersTemplate-title">Miembros</Title>
      <Row gutter={14}>
        {members.map((member) => {
          return (
            <Col key={member.name} span={4.8}>
              <MemberCard className="memberTemplate-memberCards" member={member} />
            </Col>
          );
        })}
      </Row>
      <div className="membersTemplate-pagination">
        <Pagination />
      </div>
    </div>
  );
}
