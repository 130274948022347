/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

import {
  SummaryRequestComponent,
  Calendar,
  ChronologyComponent,
  InformativeModal,
} from '../../../components';
import { valueInSpanish } from '../../../utilities/Constant';

import CounterPartConfirmAnswer from '../../../components/CounterConfirmAnswer/CounterPartConfirmAnswer';
import ModalEnterNotification from '../../../components/ModalEnterNotification/ModalEnterNotification';
import NotificationServices from '../../../../domain/usecases/NotificationServices';

import './index.less';

const ChronologyTabRequestTemplate = ({
  getRequestDetail,
  requestDetail,
  confirmAction,
  observationQuantity,
  isNotUser,
  isAdmin,
  getNotifications,
  isApplicant,
}) => {
  const {
    state = '',
    type = '',
    contractDate = '',
    expirationDate = '',
    contractDateCalendar = '',
    expirationDateCalendar = '',
    emailSent = false,
    counterpartAnswer = false,
    requestId = '',
    requestUrl = '',
  } = requestDetail;

  const [chronologyState, setChronologyState] = useState(2);
  const [alertArray, setAlertArray] = useState([]);
  const [buttonArray, setButtonArray] = useState([]);
  const [modalEnterNotification, setModalEnterNotification] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  useEffect(() => {
    let chronologyStateValue;
    const alert = [];
    const button = [];
    switch (state) {
      case 'Process':
        if (observationQuantity === 0) {
          chronologyStateValue = 3;
        } else {
          chronologyStateValue = 2;
        }

        if (observationQuantity > 0) {
          alert.push({
            position: 2,
            type: 'warning',
            description: 'Existen observaciones pendientes por resolver.',
          });
        }
        break;
      case 'Observed':
        chronologyStateValue = 1;
        alert.push({
          position: 2,
          type: 'warning',
          description: 'Existen observaciones pendientes por resolver.',
        });
        break;
      case 'Review':
        if (observationQuantity === 0) {
          chronologyStateValue = 3;
          if (!isNotUser) {
            button.push({
              position: 3,
              description: 'Notificar',
            });
          }
        } else {
          chronologyStateValue = 2;
          alert.push({
            position: 2,
            type: 'warning',
            description: 'Existen observaciones pendientes por resolver.',
          });
        }
        break;
      case 'Validation':
        chronologyStateValue = 4;
        if (isAdmin) {
          button.push({
            position: 4,
            description: 'Notificar',
          });
        }
        break;
      case 'Finalized':
        chronologyStateValue = 6;
        break;
      default:
        chronologyStateValue = 1;
    }
    setChronologyState(chronologyStateValue);
    setAlertArray([...alert]);
    setButtonArray([...button]);
  }, [observationQuantity]);

  const documentReturn = async () => {
    const [data, error] = await NotificationServices.documentReturn(requestDetail.requestId);
    if (data && !error) {
      getRequestDetail();
      getNotifications();
    } else {
      //  todo manage the errors
      setShowErrorModal(true);
      setTimeout(() => {
        setShowErrorModal(false);
      }, 2000);
    }
  };

  const handleNotification = async (notification) => {
    const [data, error] = await NotificationServices.addNotification(requestId, notification);
    if (data && error === null) {
      setModalEnterNotification(false);
      getNotifications();
    } else {
      //  todo manage the errors
      setShowErrorModal(true);
      setTimeout(() => {
        setShowErrorModal(false);
      }, 2000);
    }
  };

  return (
    <div className="main-chronology">
      <div className="summary">
        <SummaryRequestComponent
          user={{
            title: 'Resumen de solicitud',
            status: valueInSpanish[state],
            contractType: type,
            dateReceived: contractDate,
            dateSent: expirationDate,
            requestUrl,
          }}
        />
        <Calendar
          className="calendar"
          type={2}
          startDate={contractDateCalendar}
          finishDate={expirationDateCalendar}
        />
      </div>
      <div className="chronology">
        {isApplicant && emailSent && !counterpartAnswer && (
          <CounterPartConfirmAnswer
            onClickLeft={() => window.open('https://mail.google.com/mail/u/0/#inbox')}
            onClickRight={confirmAction}
          />
        )}
        <ChronologyComponent
          numberState={chronologyState}
          alert={alertArray}
          botton={buttonArray}
          onClickButton={() => setModalEnterNotification(true)}
        />
        {modalEnterNotification && (
          <ModalEnterNotification
            visible={modalEnterNotification}
            onClose={() => setModalEnterNotification(false)}
            onOptionNotification={handleNotification}
            documentReturn={documentReturn}
          />
        )}
        <InformativeModal
          title="¡Error!"
          visible={showErrorModal}
          subtitle="No se pudo enviar la notificación"
          icon="alert"
        />
      </div>
    </div>
  );
};

ChronologyTabRequestTemplate.propTypes = {};

export default ChronologyTabRequestTemplate;
