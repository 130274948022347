import React from 'react';
import { Input, Row, Col, Typography, Select, Form } from 'antd';
import moment from 'moment';

import { requestProperty } from '../../templates/NewRequest/constants';
import { areaTypes, contractTypes, MomentFormat } from '../../utilities/Constant';
import CustomIcon from '../CustomIcon/CustomIcon';
import WDatepicker from '../Datepicker';
import { convertTimeStampToDate } from '../../utilities/Functions';

import './index.less';

const { TextArea } = Input;
const { Title, Text } = Typography;

const RequestDescription = ({
  request,
  className = '',
  onFormStateChange = () => {},
  isReadOnly = false,
  isNew = true,
}) => {
  const {
    contractDate,
    expirationDate,
    description,
    userArea,
    contractType,
    contractDateTimestamp = 0,
    expirationDateTimestamp = 0,
  } = request;

  const contractDateTimestampValue = isNew ? contractDate : contractDateTimestamp;
  const expirationDateTimestampValue = isNew ? expirationDate : expirationDateTimestamp;

  const handleChange = (date, dateString, field) => {
    let value = null;
    if (date) {
      const ts = moment(date).format('X');
      // eslint-disable-next-line radix
      value = parseInt(ts);
    }
    // eslint-disable-next-line radix
    onFormStateChange(field, value);
  };

  const disabledDate = (current, field) => {
    if (field === requestProperty.CONTRACT_DATE && !expirationDateTimestampValue) {
      return false;
    }

    if (field === requestProperty.EXPIRATION_DATE && !contractDateTimestampValue) {
      return false;
    }
    const ts = moment(current).format('X');
    // eslint-disable-next-line radix
    const value = parseInt(ts);

    // console.log('disabledDate')
    if (field === requestProperty.CONTRACT_DATE) {
      return value > expirationDateTimestampValue;
    }

    if (field === requestProperty.EXPIRATION_DATE) {
      return value < contractDateTimestampValue;
    }
    return false;
  };

  return (
    <div
      className={
        !isReadOnly
          ? `requestDescription-container-editable ${className}`
          : `requestDescription-container ${className}`
      }
    >
      {request !== null && !isNew && (
        <Row className="row-desc-header">
          <Col flex="1 1 auto">
            <Title className="requestDescription-EditTitle">Información de solicitud</Title>
          </Col>
          {!isReadOnly && (
            <Col flex="0 0 10px" className="icon-edit">
              <CustomIcon type="editRequest" />
            </Col>
          )}
        </Row>
      )}
      <Row className="requestDescription-firstSection">
        <Col flex="1 0 230px">
          {contractDate && (
            <Text className="requestDescription-label">Fecha de Inicio de Contrato</Text>
          )}
          <Form.Item
            name={requestProperty.CONTRACT_DATE}
            rules={[{ required: true, message: 'Ingrese fecha de contrato' }]}
          >
            <WDatepicker
              className={`${
                contractDate
                  ? 'requestDescription-dateAndSelect requestDescription-dateAndSelect-data date-picker-selected'
                  : 'requestDescription-dateAndSelect'
              } ${isReadOnly && 'request-disabled'}
              `}
              placeholder="Fecha de Inicio de Contrato"
              format={MomentFormat.DD_FROM_MMMM_YYYY}
              value={convertTimeStampToDate(isNew ? contractDate : contractDateTimestamp)}
              defaultValue={convertTimeStampToDate(isNew ? contractDate : contractDateTimestamp)}
              suffixIcon={<CustomIcon type="selectCalendar" />}
              onChange={(date, dateString) =>
                handleChange(date, dateString, requestProperty.CONTRACT_DATE)
              }
              disabledDate={(current) => disabledDate(current, requestProperty.CONTRACT_DATE)}
            />
          </Form.Item>
        </Col>

        <Col flex="1 0 200px">
          {expirationDate && (
            <Text className="requestDescription-label">Fecha Fin de Contrato</Text>
          )}
          <Form.Item
            name={requestProperty.EXPIRATION_DATE}
            rules={[{ required: true, message: 'Ingrese fecha de expiración' }]}
          >
            <WDatepicker
              className={`${
                expirationDate
                  ? 'requestDescription-dateAndSelect requestDescription-dateAndSelect-data date-picker-selected'
                  : 'requestDescription-dateAndSelect'
              } ${isReadOnly && 'request-disabled'}
              `}
              placeholder="Fecha Fin de Contrato"
              format={MomentFormat.DD_FROM_MMMM_YYYY}
              value={convertTimeStampToDate(isNew ? expirationDate : expirationDateTimestamp)}
              defaultValue={convertTimeStampToDate(
                isNew ? expirationDate : expirationDateTimestamp
              )}
              suffixIcon={<CustomIcon type="selectCalendar" />}
              onChange={(date, dateString) =>
                handleChange(date, dateString, requestProperty.EXPIRATION_DATE)
              }
              disabledDate={(current) => disabledDate(current, requestProperty.EXPIRATION_DATE)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 120px">
          <Form.Item
            name={requestProperty.USER_AREA}
            rules={[{ required: true, message: 'Seleccione área usuaria' }]}
          >
            <Select
              value={userArea}
              defaultValue={userArea}
              options={areaTypes}
              className={`${
                request !== null && !isNew
                  ? 'requestDescription-dateAndSelect requestDescription-select'
                  : 'requestDescription-dateAndSelect'
              } ${isReadOnly && 'request-disabled'}
              `}
              placeholder="Área usuaria"
              onChange={(value) => onFormStateChange(requestProperty.USER_AREA, value)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 0 200px">
          <Form.Item
            name={requestProperty.CONTRACT_TYPE}
            rules={[{ required: true, message: 'Seleccione tipo de contrato' }]}
          >
            <Select
              value={contractType}
              defaultValue={contractType}
              options={contractTypes}
              className={`${
                request !== null && !isNew
                  ? 'requestDescription-dateAndSelect requestDescription-select'
                  : 'requestDescription-dateAndSelect'
              } ${isReadOnly && 'request-disabled'}
              `}
              placeholder="Tipo de contrato"
              onChange={(value) => onFormStateChange(requestProperty.CONTRACT_TYPE, value)}
            />
          </Form.Item>
        </Col>
      </Row>
      {description && (
        <Col>
          <Text className="requestDescription-label label-description">Descripción</Text>
        </Col>
      )}
      <Form.Item
        className="mt-10"
        name={requestProperty.DESCRIPTION}
        rules={[{ required: true, message: 'Ingrese descripción' }]}
      >
        <TextArea
          className={
            description
              ? 'requestDescription-description requestDescription-description-data'
              : 'requestDescription-description'
          }
          placeholder="Ingrese descripción..."
          value={description}
          defaultValue={description}
          onChange={({ target: { value } }) =>
            onFormStateChange(requestProperty.DESCRIPTION, value)
          }
          disabled={isReadOnly}
        />
      </Form.Item>
    </div>
  );
};
export default RequestDescription;
