import RequestRepository from '../repositories/RequestRepository';

export default class ReassignLegalOwnerServices {
  static async reassignLegalResponsible(body, requestId) {
    const [data, error] = await RequestRepository.reassignLegalResponsible(requestId, body, {
      workflow: 'reasign',
    });
    return [data, error];
  }
}
