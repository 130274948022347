import React from 'react';
import { Typography } from 'antd';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';
import StateRequestChronologyComponent from '../StateRequestChronology/index';

const { Title, Paragraph } = Typography;

const WChronology = ({ numberState, alert, botton, onClickButton }) => {
  const steps = [
    {
      title: 'Solicitud aceptada',
      description: 'Tu solicitud fue aceptada satisfactoriamente para su desarrollo.',
      key: 'step-1',
    },
    {
      title: 'Observaciones',
      description: 'La solicitud presenta observación en el modelo escogido por el usuario.',
      key: 'step-2',
    },
    {
      title: 'Revisión de solicitud',
      description: 'Documento realizado por el responsable de Área Legal para su revisión.',
      key: 'step-3',
    },
    {
      title: 'Visto bueno de Sub gerencia Área Legal',
      description:
        'El documento fue aceptado exitosamente por el usuario, esperando validación de la sub gerencia de área legal',
      key: 'step-4',
    },
    {
      title: 'Solicitud finalizada',
      description: '',
      key: 'step-5',
    },
  ];

  return (
    <div className="container-chronoly">
      <Title level={5} className="title">
        Cronología
      </Title>
      <div className="ant-steps-vertical">
        {steps.map((item, index) => {
          const bottonFind = botton.find((a) => a.position === index + 1);
          const alertFind = alert.find((a) => a.position === index + 1);

          return (
            <div
              key={item.key}
              className={`ant-steps-item ${
                index < numberState - 1 ? 'ant-steps-item-finish' : 'ant-steps-item-wait'
              }`}
            >
              <div className="ant-steps-item-container">
                {index < steps.length - 1 && <div className="ant-steps-item-tail" />}

                <div
                  className={
                    index < numberState - 1
                      ? 'ant-steps-item-icon process-complete'
                      : 'ant-steps-item-icon process-wait'
                  }
                >
                  {index < numberState - 1 ? (
                    <span className="ant-steps-sinicon">
                      <CustomIcon type="checkGreen" />
                    </span>
                  ) : (
                    <span className="ant-steps-icon number">{index + 1}</span>
                  )}
                </div>
              </div>

              <div className="ant-steps-item-content">
                <div className="ant-steps-item-title">
                  <Paragraph className="step-title">{item.title}</Paragraph>
                  <Paragraph className="subtitle">{item.description}</Paragraph>
                  {bottonFind && (
                    <div className="botton" onClick={onClickButton}>
                      <a>{bottonFind.description}</a>
                    </div>
                  )}
                </div>

                {alertFind && (
                  <StateRequestChronologyComponent
                    description={alertFind.description}
                    type={alertFind.type}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

WChronology.propTypes = {};

export default WChronology;
