import React, { forwardRef, useEffect } from 'react';
import { Form } from 'antd';

import { requestProperty } from '../NewRequest/constants';
import {
  convertTimeStampToDate,
  formatDate,
  getStringDateWithFormat,
} from '../../utilities/Functions';
import { MomentFormat } from '../../utilities/Constant';
import { RequestDescription } from '../../components';

const RequestDescriptionTemplate = forwardRef(
  ({ data, onSetRequest, className = '', isReadOnly, editable, errorMessages }) => {
    const [form] = Form.useForm();
    const { description, userArea, contractType, contractDateTimestamp, expirationDateTimestamp } =
      data;
    const savedData = {
      [requestProperty.CONTRACT_DATE]: convertTimeStampToDate(contractDateTimestamp),
      [requestProperty.EXPIRATION_DATE]: convertTimeStampToDate(expirationDateTimestamp),
      [requestProperty.USER_AREA]: userArea,
      [requestProperty.CONTRACT_TYPE]: contractType,
      [requestProperty.DESCRIPTION]: description,
    };
    const fields = form.getFieldsError();
    const errorForm = fields.some((e) => e.errors.length > 0);

    useEffect(() => {
      if (errorMessages) {
        form.submit();
      } else {
        form.resetFields();
      }
    }, [errorMessages]);

    const onFormStateChange = (field, value) => {
      const propertyContract = {
        DATE: requestProperty.CONTRACT_DATE,
        DATE_CALENDAR: 'contractDateCalendar',
        DATE_TIMESTAMP: 'contractDateTimestamp',
      };
      const propertyExpiration = {
        DATE: requestProperty.EXPIRATION_DATE,
        DATE_CALENDAR: 'expirationDateCalendar',
        DATE_TIMESTAMP: 'expirationDateTimestamp',
      };

      if (field === requestProperty.CONTRACT_DATE || field === requestProperty.EXPIRATION_DATE) {
        const dateValue = formatDate(new Date(value * 1000), MomentFormat.DD_MM_YYYY);
        const prop =
          field === requestProperty.CONTRACT_DATE ? propertyContract : propertyExpiration;
        onSetRequest(prop.DATE, dateValue);
        onSetRequest(prop.DATE_CALENDAR, getStringDateWithFormat(dateValue));
        onSetRequest(prop.DATE_TIMESTAMP, value);
      } else {
        onSetRequest(field, value);
      }
    };

    return (
      <Form name="detail-s1" autoComplete="off" initialValues={savedData} form={form}>
        <RequestDescription
          className={`${className} ${errorMessages && errorForm && 'border-error'}`}
          request={data}
          isReadOnly={isReadOnly}
          editable={editable}
          onFormStateChange={onFormStateChange}
          isNew={false}
        />
      </Form>
    );
  }
);

export default RequestDescriptionTemplate;
