import React, { useEffect, useState } from 'react';
import { Typography, Input, Button, Row, Col, Modal } from 'antd';
import { isEmpty } from 'lodash';

import CustomIcon from '../CustomIcon/CustomIcon';
import { checkValidEmail } from '../../utilities/Validation';

import './index.less';

const { Title, Text } = Typography;

const ModalEnterEmail = ({ onClose, visible, onShare, loading, emailsCounterpart = [] }) => {
  const [email, setEmail] = useState([]);
  const [newEmail, setNewEmail] = useState({});
  const [errorEmail, setErrorEmail] = useState(false);

  const onChangeEmail = (value, property) => {
    newEmail[property] = value.trim();
    setErrorEmail(false);
    setNewEmail({ ...newEmail });
  };
  const onSaveEmail = () => {
    if (!isEmpty(newEmail.email) && checkValidEmail(newEmail.email)) {
      email.push({ ...newEmail });
      setEmail([...email]);
      setNewEmail({});
    } else {
      setErrorEmail(true);
    }
  };
  const onDeleteEmail = (index) => {
    email.splice(index, 1);
    setEmail([...email]);
  };

  useEffect(() => {
    if (!isEmpty(emailsCounterpart)) setEmail(emailsCounterpart);
  }, [emailsCounterpart]);
  return (
    <Modal visible={visible} footer={null} className="modalEnterEmail-container">
      <CustomIcon className="modalEnterEmail-iconClose" type="closeGrey" onClick={onClose} />
      <Text className="modalEnterEmail-titleOne">Ingresar </Text>
      <Title className="modalEnterEmail-titleTwo">Correo de contraparte</Title>
      <Text type="primary" className="modalEnterEmail-subtitle">
        Ingresa el correo de la contraparte para que pueda hacer la validación del contrato
        realizado
      </Text>
      <Row gutter={15} className="modalEnterEmail-RowEmail">
        {email?.map((item, index) => (
          <>
            <Col span={16} className="modalEnterEmail-colEmail">
              <Text>{item.email}</Text>
            </Col>
            <Col span={1} className="modalEnterEmail-colEmail">
              <CustomIcon
                className="modalEnterEmail-addDeleteIcon"
                type="deleteEmail"
                onClick={() => onDeleteEmail(index)}
              />
            </Col>
          </>
        ))}
      </Row>
      <Row gutter={15} className="modalEnterEmail-rowInputEmail modalEnterEmail-RowEmail">
        <Col span={16}>
          <Input
            className="modalEnterEmail-inputEmail"
            placeholder="Ingrese correo"
            value={newEmail.email}
            onChange={(e) => onChangeEmail(e.target.value, 'email')}
            style={errorEmail ? { borderColor: '#ff6565' } : {}}
          />
          {errorEmail && <Text style={{ color: '#ff6565' }}>Error, correo inválido</Text>}
        </Col>
        <Col span={1}>
          <CustomIcon
            className="modalEnterEmail-addDeleteIcon"
            type="addEmail"
            onClick={() => onSaveEmail()}
          />
        </Col>
      </Row>
      <Row gutter={30} className="modalEnterEmail-rowButtons">
        <Col>
          <Button className="modalEnterEmail-bn-cancelar" onClick={onClose}>
            Cancelar
          </Button>
        </Col>
        <Col>
          <Button
            disabled={email.length < 1}
            className="modalEnterEmail-bn-share"
            onClick={() => onShare(email, setEmail)}
            loading={loading}
            style={email.length < 1 ? { opacity: 0.5 } : {}}
          >
            Compartir documento
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalEnterEmail;

ModalEnterEmail.defaultProps = {
  visible: false,
};
