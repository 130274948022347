import React from 'react';
import { Table } from 'antd';
import './index.less';

const WCustomDynamicTable = ({
  headerData,
  bodyData,
  minWidth,
  maxHeight,
  currentPage,
  pageSize,
  total,
  showPagination = true,
  isLoading = false,
  onChangePage,
}) => {
  return (
    <Table
      showSorterTooltip
      columns={headerData}
      dataSource={bodyData}
      scroll={{ x: minWidth, y: maxHeight }}
      loading={isLoading}
      pagination={
        showPagination
          ? {
              position: ['bottomRight'],
              current: currentPage,
              pageSize,
              total,
            }
          : false
      }
      onChange={onChangePage}
    />
  );
};
export default WCustomDynamicTable;
