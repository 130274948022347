import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
// import { CAPTCHA_KEY } from '../../utilities/Constant';
import './index.less';

const Captcha = React.forwardRef(
  ({ className, onChange, badge, size, tabindex, theme, onErrored, onExpired }, ref) => {
    return (
      <div className={`captcha-components ${className}`}>
        <ReCAPTCHA
          ref={ref}
          sitekey="123"
          onChange={onChange}
          badge={badge}
          size={size}
          tabindex={tabindex}
          theme={theme}
          onErrored={onErrored}
          onExpired={onExpired}
        />
      </div>
    );
  }
);

Captcha.displayName = 'PSCaptcha';

Captcha.defaultProps = {
  className: null,
  badge: 'inline',
  size: 'normal',
  tabindex: 0,
  theme: 'light',
  onErrored: () => {
    console.log('onErrored');
  },
  onExpired: () => {
    console.log('onExpired');
  },
  onChange: () => {
    console.log('onChange');
  },
};

Captcha.propTypes = {
  className: PropTypes.string,
  badge: PropTypes.string,
  size: PropTypes.string,
  tabindex: PropTypes.number,
  theme: PropTypes.string,
  onChange: PropTypes.func,
  onErrored: PropTypes.func,
  onExpired: PropTypes.func,
};

export default Captcha;
