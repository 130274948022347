/* eslint-disable prettier/prettier */
import { doPost, doPut, doGet } from '../../data/api/ApiService';

import { Error } from '../../data/entities/Error';
import { Request } from '../../data/entities/Request';

export default class RequestRepository {
  static async saveRequest(request) {
    let data = [];
    let error = null;

    try {
      const response = await doPost(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}`,
        JSON.stringify(request)
      );
      const { status = {} } = response?.data;

      if (status?.id === 201) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async updateRequest(request, id) {
    let data = [];
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES}/requests/${id}`,
        request
      );
      const dataResponse = response?.data;

      if (dataResponse?.status?.id === 201 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  /* static async editRequest(request, id, header) {
    let data = [];
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES}/requests/${id}`,
        request,
        header
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  } */

  static async getRequestList() {
    let data = [];
    let error = null;

    try {
      const response = await doGet(`${process.env.REACT_APP_BASE_URL_SERVICES_NEW}`);
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse?.data) {
        data = dataResponse.data.map((item) => new Request(item));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async getRequestById(requestId) {
    let data = {};
    let error = null;

    try {
      const response = await doGet(`${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}`);
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse?.data) {
        data = new Request(dataResponse.data);
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async addObservations(requestId, observations) {
    let data = {};
    let error = null;

    try {
      const response = await doPost(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/observations`,
        observations,
        { workflow: 'add_observation' }
      );
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async reassignLegalResponsible(requestId, jsonData) {
    let data = {};
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/reasign`,
        jsonData
      );
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  /** * ENVIAR CONTRATO ** */
  static async sendContract(requestId) {
    let data = [];
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/sendcontract`
      );
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async shareDocument(requestId, jsonData) {
    let data = [];
    let error = null;
    const response = await doPut(
      `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/share`,
      jsonData
    );
    try {
      const dataResponse = response?.data;

      if (dataResponse?.status?.id === 201 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async confirmRequest(requestId) {
    let data = [];
    let error = null;
    const response = await doPut(
      `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/confirm`
    );
    try {
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async approval(requestId) {
    let data = [];
    let error = null;
    const response = await doPut(
      `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/vistobueno`
    );
    try {
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async cancel(requestId, payload) {
    let data = [];
    let error = null;
    const response = await doPut(
      `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/cancel`,
      { ...payload }
    );
    try {
      if (response.status === 200) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async validate(requestId) {
    let data = [];
    let error = null;
    const response = await doPut(
      `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/validate`
    );
    try {
      const dataResponse = response?.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
