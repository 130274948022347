import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import isBoolean from 'lodash/isBoolean';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { isEmpty } from 'lodash';
import { validationType } from './Constant';

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /^(?!.*@.*@)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
// const DNI_REGEX = /^\d{8}(?:[-\s]\d{4})?$/;
const DNI_REGEX = /^[0-9]{8}$/;
const RUC_REGEX = /^(10|20)[0-9]{9}/;
const RUC_LEGAL_REGEX = /^(20)[0-9]{9}/;
const RUC_NATURAL_REGEX = /^(10)[0-9]{9}/;

export function checkDNI(value) {
  return DNI_REGEX.test(value?.toLowerCase());
}

export function checkRUC(value) {
  return RUC_REGEX.test(value?.toLowerCase());
}

export function checkRUCLegal(value) {
  return RUC_LEGAL_REGEX.test(value?.toLowerCase());
}

export function checkRUCNatural(value) {
  return RUC_NATURAL_REGEX.test(value?.toLowerCase());
}

export function checkValidEmail(value) {
  return EMAIL_REGEX.test(value?.toLowerCase());
}

export function checkRequired(value) {
  if (isArray(value)) {
    return reduce(value, (acc, n) => acc || (n && n.length > 0));
  }
  if (isBoolean(value)) {
    return true;
  }
  if (!Number.isNaN(parseFloat(value)) && Number.isFinite(value)) {
    return parseFloat(value) >= 0;
  }
  if (isObject(value)) {
    return !isEmpty(value);
  }

  return value !== undefined && value !== null && value.length > 0;
}

export const validationMap = {
  [validationType.REQUIRED]: checkRequired,
  [validationType.DNI]: checkDNI,
  [validationType.RUC_LEGAL]: checkRUCLegal,
  [validationType.RUC_NATURAL]: checkRUCNatural,
  [validationType.EMAIL]: checkValidEmail,
};

export function validateData(data, validationObj) {
  let error = {};
  let er;
  if (isArray(validationObj)) {
    // validationObj = [{ field1: string[], field2: string[], field3: object[], ... }, {...}]
    error = [];
    forEach(validationObj, (valObject, dataIndex) => {
      error.push({ ...validateData(data[dataIndex], valObject) });
    });
  } else if (isObject(validationObj)) {
    // validationObj = { field1: string[], field2: string[], field3: v[], ...}
    forEach(validationObj, (validations, key) => {
      if (isArray(validations)) {
        // validations = string[] or object[]
        error[key] = [];
        forEach(validations, (validation, index) => {
          if (isString(validation)) {
            // validation = string
            er = false;
            if (!validationMap[validation](data[key])) {
              er = true;
            }
            error[key].push(er);
          } else if (isObject(validation)) {
            // validation = object
            error[key].push({ ...validateData(data[key][index], validation) });
          }
        });
      }
    });
  }
  return error;
}

export function hasError(errorObj) {
  let result = false;
  // eslint-disable-next-line consistent-return
  if (isArray(errorObj)) {
    forEach(errorObj, (errors) => {
      if (result) {
        return;
      }
      if (hasError(errors)) {
        result = true;
      }
    });
  } else if (isObject(errorObj)) {
    // { field1: [true], field2: [false] }
    forEach(errorObj, (errors) => {
      if (result) {
        return;
      }
      // [true], [false]
      forEach(errors, (error) => {
        if (result) {
          return;
        }

        if (isBoolean(error)) {
          if (error) {
            result = true;
          }
        } else if (isObject(error)) {
          if (hasError(error)) {
            result = true;
          }
        }
      });
    });
  }

  return result;
}
