// eslint-disable-next-line max-classes-per-file
import moment from 'moment';
import { forEach, keys, pick } from 'lodash';
import {
  contractModelType,
  MomentFormat,
  personType,
  representativeProperty,
} from '../../app/utilities/Constant';

class Company {
  constructor(props) {
    this.name = props.name;
    this.ruc = props.ruc;
    this.socialReason = props.socialReason;
    this.type = props.type;
    this.address = props.address;
    this.electronicCertificate = props.electronicCertificate;
    this.registerOffice = props.registerOffice;
    this.email = props.email;
    this.legalRepresentatives = props.legalRepresentatives.map((re) => {
      return pick(re, [
        representativeProperty.NAME,
        representativeProperty.MIDDLE_NAME,
        representativeProperty.FAMILY_NAME,
        representativeProperty.GENDER,
        representativeProperty.DOCUMENT_TYPE,
        representativeProperty.DOCUMENT_NUMBER,
        representativeProperty.POWER_VIGENCY,
      ]);
    });
  }
}

export class Justify {
  constructor(props) {
    this.description = props.description;
    this.id = props.id;
    this.modelName = props.modelName;
    this.documents = props.documents?.map(({ id, filename }) => {
      return { id, filename, saved: true };
    });
  }
}
export class ContractModelExternal {
  constructor(props) {
    this.origin = props.origin;
    this.justify = new Justify(props.justify);
  }
}

export class ContractModelOrigin {
  constructor(props) {
    this.origin = props.origin;
    this.contractDrive = props.contractDrive;
    this.justify = {
      description: null,
      documents: [],
    };
  }
}

export function getCounterPart(counterPart) {
  const counterPartToSend = [];
  forEach(counterPart, (cp) => {
    if (cp.type === personType.LEGAL) {
      const companies = [];
      forEach(cp.companies, (company) => {
        if (keys(company).length > 3) {
          companies.push(new Company(company));
        }
      });
      if (companies.length > 0) counterPartToSend.push({ type: cp.type, companies });
    } else if (cp.type === personType.NATURAL) {
      const legalRepresentatives = [];
      forEach(cp.legalRepresentatives, (representative) => {
        if (keys(representative).length > 1) {
          legalRepresentatives.push(
            pick(representative, [
              representativeProperty.NAME,
              representativeProperty.MIDDLE_NAME,
              representativeProperty.POWER_VIGENCY,
              representativeProperty.FAMILY_NAME,
              representativeProperty.GENDER,
              representativeProperty.DOCUMENT_TYPE,
              representativeProperty.DOCUMENT_NUMBER,
              representativeProperty.RUC,
              representativeProperty.ADDRESS,
              representativeProperty.EMAIL,
            ])
          );
        }
      });
      if (legalRepresentatives.length > 0)
        counterPartToSend.push({ type: cp.type, legalRepresentatives });
    }
  });
  return counterPartToSend;
}

export function getContractModel(contractModel) {
  if (contractModel.origin === contractModelType.REPOSITORY) {
    return new ContractModelOrigin(contractModel);
  }

  return new ContractModelExternal(contractModel);
}

export class RequestJson {
  constructor(props) {
    this.type = props.type;
    this.legalOwner = props.legalOwner;
    this.contractDate = props.contractDate
      ? moment(moment.unix(props.contractDate)).format(MomentFormat.DD_MM_YYYY)
      : '';
    this.expirationDate = props.expirationDate
      ? moment(moment.unix(props.expirationDate)).format(MomentFormat.DD_MM_YYYY)
      : '';
    this.userArea = props.userArea;
    this.contractType = props.contractType;
    this.description = props.description;
    this.attached = props.attached;
    // eslint-disable-next-line prefer-destructuring
    this.counterPart = getCounterPart(props.counterPart);
    this.contractModel = getContractModel(props.contractModel);
  }
}
