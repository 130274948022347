import React from 'react';
import { DatePicker } from 'antd';
import { MomentFormat } from '../../utilities/Constant';
import './index.less';

const WDatepicker = ({
  placeholder,
  className = 'custom-datepicker',
  suffixIcon = null,
  format = MomentFormat.YYYY_MM_DD,
  disabledDate = () => {},
  ...props
}) => {
  return (
    <DatePicker
      className={className}
      placeholder={placeholder}
      suffixIcon={suffixIcon}
      format={format}
      disabledDate={disabledDate}
      {...props}
    />
  );
};

export default WDatepicker;
