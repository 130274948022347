import React, { useState, useEffect } from 'react';
import './index.less';

import { Typography, Button, Input, Select, Row, Col, Modal, Form } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Text } = Typography;
const { TextArea } = Input;

const defaultOptions = [
  { label: 'Option 1', value: 'option 1' },
  { label: 'Option 2', value: 'option 2' },
  { label: 'Option 3', value: 'option 3' },
];

const ObservationModal = ({ onClickClose, onClickButton, visible, options, observation }) => {
  const INITIAL_OBSERVATION = {
    justification: '',
  };

  const [form] = Form.useForm();

  const [infObservation, setInfObservation] = useState({ ...INITIAL_OBSERVATION });
  const [loading, setLoading] = useState(false);

  const onReset = () => {
    setInfObservation({ ...INITIAL_OBSERVATION });
    form.resetFields();
  };
  const onSaveObs = (value, prop) => {
    infObservation[prop] = value;
    setInfObservation({ ...infObservation });
  };

  const onAccept = async () => {
    setLoading(true);
    try {
      await onClickButton(infObservation);
      onReset();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInfObservation(observation ? { ...observation } : { ...INITIAL_OBSERVATION });
  }, [observation]);

  useEffect(() => {
    if (visible) onReset();
  }, [visible]);
  return (
    <Modal visible={visible} footer={null}>
      <Form form={form} onFinish={onAccept} autoComplete="off" layout="vertical">
        <div className="container-observationModal">
          <div className="container-title-observationModal">
            <div>
              <Text className="first-title">Ingresar</Text>
              <Text className="second-title">Observación</Text>
            </div>
            <CustomIcon
              className="observationModal-iconClose"
              type="closeGrey"
              onClick={onClickClose}
            />
          </div>

          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: 'Selecciona una categoría',
              },
            ]}
          >
            <Select
              value={infObservation.type}
              options={options}
              className="observationModal-select"
              placeholder="Selecciona categoría"
              disabled={loading}
              onChange={(value) => {
                onSaveObs(value, 'type');
              }}
            />
          </Form.Item>

          {infObservation.type && <Text className="observationModal-selectLabel">Categoría</Text>}
          <Form.Item
            name="justification"
            rules={[
              {
                required: true,
                message: 'Escribe tus observaciones',
              },
            ]}
          >
            <TextArea
              value={infObservation.justification}
              className="input-comments"
              placeholder="Escribe tus observaciones..."
              onChange={(e) => {
                onSaveObs(e.target.value, 'justification');
              }}
              disabled={loading}
            />
          </Form.Item>

          <Row gutter={18} className="container-bn-observationModal">
            <Col>
              <Button
                className="bn-observationModal observationModal-cancel "
                onClick={onClickClose}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button className="bn-observationModal" loading={loading} htmlType="submit">
                {loading ? 'Cargando' : 'Aceptar'}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};
ObservationModal.display = 'ObservationModal';

ObservationModal.defaultOptions = {
  options: defaultOptions,
  observation: null,
};

export default ObservationModal;
