import React, { createContext, useEffect, useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { addTokenCognito, provider, signOutUrl } from './constant';
import { showErrorToast } from '../../utilities/Constant';
import { changeLoading, loginFedereation } from '../../redux/ducks/user';
import { decodeJwt } from '../../utilities/Functions';
import 'react-toastify/dist/ReactToastify.css';

export const AwsContext = createContext();
export const AwsContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const oauthGoogle = async () => {
    try {
      dispatch(changeLoading(true));
      await Auth.federatedSignIn({ provider: provider.google });
    } catch (e) {
      dispatch(changeLoading(false));
      console.error(e);
    }
  };

  const logoutFederation = async () => {
    try {
      await Auth.signOut();

      window.location.href = signOutUrl;
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };
  const saveTokenToLocalStorage = () => {
    const idToken = addTokenCognito();

    if (idToken) {
      const decoded = decodeJwt(idToken);
      const nickname = decoded?.nickname;
      if (nickname) setEmail(nickname);
      else {
        dispatch(changeLoading(false));
        showErrorToast('Error autenticación');
      }
    } else {
      dispatch(changeLoading(false));
    }
  };
  useEffect(() => {
    if (email && typeof email === 'string') {
      try {
        dispatch(loginFedereation(email));
      } catch (e) {
        dispatch(changeLoading(false));
        showErrorToast('Error en la autenticación');
      }
    } else if (email === undefined) {
      dispatch(changeLoading(false));
    }
  }, [email]);

  useEffect(() => {
    try {
      saveTokenToLocalStorage();
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <AwsContext.Provider value={{ oauthGoogle, logoutFederation }}>{children}</AwsContext.Provider>
  );
};

export const useAws = () => {
  return useContext(AwsContext);
};
