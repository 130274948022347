import React, { useEffect, useState } from 'react';
import './index.less';
import { Button, Modal, Typography, Row, Col, Input, Radio, Form } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';
import { notificationTypes } from '../../utilities/Constant';

const { Title, Text } = Typography;
const { TextArea } = Input;

const getClassSize = (option) => {
  switch (option) {
    case null:
      return 'modalEnter-size';
    case 1:
      return 'modalEnter-size-optionOne';
    case 2:
      return 'modalEnter-size-optionTwo';
    default:
      return 'modalEnter-size';
  }
};

const notificationInitial = {
  type: '',
  subtype: '',
  description: '',
};

const radioOptions = [
  {
    label: 'Link no funciona',
    value: 'notWorkingLink',
  },
  {
    label: 'Modelo usado no es el mismo al elegido',
    value: 'incorrectModel',
  },
  {
    label: 'Otros',
    value: 'otros',
  },
];

const ModalEnterNotification = ({
  onClose,
  visible,
  onOptionNotification,
  notification,
  documentReturn,
}) => {
  const [option, setOption] = useState(null);
  const [infoNotification, setInfoNotification] = useState({ ...notificationInitial });
  const { type, description, subtype } = infoNotification;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onOption = (opt) => {
    setOption(opt);
  };

  const handleErrorDocument = async () => {
    setLoading(true);
    try {
      await onOptionNotification(infoNotification);
      onClose();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const selectNotificationType = async (opt) => {
    if (opt === 3) {
      setLoading(true);
      await documentReturn();
      onClose();
      setLoading(false);
    } else {
      const newNotification = { ...infoNotification };
      newNotification.type = notificationTypes[opt];
      setInfoNotification(newNotification);
    }
  };

  const onSaveNotification = (value, prop) => {
    infoNotification[prop] = value;
    setInfoNotification({ ...infoNotification });
  };
  const onRestet = () => {
    setInfoNotification({ ...notificationInitial });
    form.resetFields();
  };

  useEffect(() => {
    setInfoNotification(notification ? { ...notification } : { ...notificationInitial });
  }, [notification]);
  return (
    <Modal className={`${getClassSize(option)} modal-notification`} visible={visible} footer={null}>
      <div className="container-modalEnter">
        <Title level={4} className="modalEnter-titleOne">
          Ingresar
        </Title>
        <Title className="modalEnter-titleTwo">Notificación</Title>
        <CustomIcon
          className="modalEnter-closeIcon"
          type="closeGrey"
          onClick={() => {
            onClose();
            setInfoNotification({ ...notificationInitial });
          }}
        />
        {!type && (
          <>
            <Text type="primary" className="modalEnter-subTitle">
              Selecciones el tipo de notificación que se enviar al encargado de la solicitud
            </Text>
            <Row gutter={20}>
              <Col>
                <div
                  className={
                    option === 1
                      ? 'modalEnter-cards modalEnter-cards-typeOne cards-typeOne-selected'
                      : 'modalEnter-cards modalEnter-cards-typeOne'
                  }
                  onClick={() => onOption(1)}
                >
                  <span className="modalEnter-cardtext">Corrección Documento</span>
                </div>
              </Col>
              <Col>
                <div
                  className={
                    option === 2
                      ? 'modalEnter-cards modalEnter-cards-typeTwo cards-typeTwo-selected'
                      : 'modalEnter-cards modalEnter-cards-typeTwo'
                  }
                  onClick={() => onOption(2)}
                >
                  <span className="modalEnter-cardtext">Errores Documento</span>
                </div>
              </Col>
              <Col>
                <div
                  className={
                    option === 3
                      ? 'modalEnter-cards modalEnter-cards-typeThree cards-typeThree-selected'
                      : 'modalEnter-cards modalEnter-cards-typeThree'
                  }
                  onClick={() => onOption(3)}
                >
                  <span className="modalEnter-cardtext">Devolución Documento</span>
                </div>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col>
                <Button className="modalEnter-footer-bn footer-bn-cancelar" onClick={onClose}>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  className="modalEnter-footer-bn"
                  onClick={() => selectNotificationType(option)}
                  loading={loading}
                >
                  {option === 3 ? 'Enviar' : 'Continuar'}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {type === notificationTypes[1] && (
          <>
            <Text className="modalEnter-Option-subtitle">Corregir el documento</Text>
            <Form form={form} onFinish={handleErrorDocument}>
              <Form.Item
                name="description"
                rules={[
                  {
                    name: 'description',
                    required: true,
                    message: 'La descripción es requerido',
                  },
                ]}
              >
                <TextArea
                  className="modalEnter-firstOption-input"
                  placeholder="Escribir aquí."
                  autoSize={{ minRows: 10 }}
                  onChange={(e) => {
                    onSaveNotification(e.target.value, 'description');
                  }}
                  disabled={loading}
                  value={description}
                />
              </Form.Item>

              <Row className="modalEnter-footer-buttons">
                <Col>
                  <Button
                    className="modalEnter-footer-bn footer-bn-cancelar"
                    onClick={() => onRestet()}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button className="modalEnter-footer-bn" htmlType="submit" loading={loading}>
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
        {type === notificationTypes[2] && (
          <>
            <Text className="modalEnter-Option-subtitle">Errores en el documento</Text>
            {/* <div className="modalEnter-container-checkbox">
              <Checkbox className="modalEnter-checkbox" />
              <span className="modalEnter-checkbox-label">Lorem ipsum</span>
            </div> */}
            <Form form={form} onFinish={handleErrorDocument}>
              <Form.Item
                name="subtype"
                rules={[{ required: true, message: 'Es necesario seleccionar una opción' }]}
              >
                <Radio.Group
                  className="notification-subtype"
                  options={radioOptions}
                  onChange={(e) => onSaveNotification(e.target.value, 'subtype')}
                  disabled={loading}
                  value={subtype}
                />
              </Form.Item>

              <Form.Item
                name="description"
                rules={[
                  {
                    name: 'description',
                    required: true,
                    message: 'Es necesario ingresar una descripción',
                  },
                ]}
              >
                <TextArea
                  className="modalEnter-secondOption-input"
                  placeholder="Ingrese detalles"
                  onChange={(e) => {
                    onSaveNotification(e.target.value, 'description');
                  }}
                  value={description}
                  disabled={loading}
                />
              </Form.Item>
              <Row className="modalEnter-footer-buttons">
                <Col>
                  <Button
                    className="modalEnter-footer-bn footer-bn-cancelar"
                    onClick={() => onRestet()}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" loading={loading} className="modalEnter-footer-bn">
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalEnterNotification;

ModalEnterNotification.defaultProps = {
  visible: 'false',
};
