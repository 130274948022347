import React from 'react';
import { Layout } from 'antd';

import MenuUser from '../MenuUser/MenuUser';

import './index.less';

const { Sider } = Layout;

const SidebarUser = ({ isAdmin, selected }) => {
  return (
    <Sider theme="light" align="center" className="sidebarUser">
      <MenuUser isAdmin={isAdmin} selected={selected} />
    </Sider>
  );
};

export default SidebarUser;
