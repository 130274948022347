import { doGet, doPost, doPut, doDelete } from '../../data/api/ApiService';

import { Error } from '../../data/entities/Error';
import { Notification } from '../../data/entities/Notification';

export default class NotificationRepository {
  static async getNotifications(requestId) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/notifications`
      );
      const dataResponse = response.data.data;

      if (response.status === 200 && dataResponse) {
        data = dataResponse.map((item) => new Notification(item));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async addNotification(requestId, notificationData) {
    let data = [];
    let error = null;
    try {
      const response = await doPost(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/notifications`,
        notificationData
      );
      const dataResponse = response?.data;
      const { status = {} } = dataResponse;
      if (status?.id === 201 && response?.data) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async approveNotification(requestId, notificationId) {
    let data = [];
    let error = null;
    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/notifications/${notificationId}/approve`
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async rejectNotification(requestId, notificationId) {
    let data = [];
    let error = null;
    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/notifications/${notificationId}/reject`
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async deleteNotification(requestId, notificationId) {
    let data = [];
    let error = null;
    try {
      const response = await doDelete(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/notifications/${notificationId}`
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async updateNotification(requestId, notificationId, jsonNotification) {
    let data = [];
    let error = null;
    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/notifications/${notificationId}`,
        jsonNotification
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async documentReturn(requestId) {
    let data = [];
    let error = null;
    try {
      const response = await doPut(
        `${process.env.REACT_APP_BASE_URL_SERVICES_NEW}/${requestId}/sendback`
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
