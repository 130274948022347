import React, { useState } from 'react';
import './index.less';
import { Typography, Row, Col } from 'antd';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import WTextInput from '../TextInput';
import { getRepresentatives, getValueDefault } from '../../utilities/Functions';
// eslint-disable-next-line import/named
import {
  businessTypes,
  companyProperty,
  personType,
  REPRESENTATIVE,
  validationType,
} from '../../utilities/Constant';
import WSelectInput from '../SelectInput';
import RepresentativeCard from '../RepresentativeCard/RepresentativeCard';
// eslint-disable-next-line camelcase

const { Title } = Typography;

const CompanyCard = ({
  company,
  className = '',
  title = '',
  onSetCompanies = () => {},
  companyIndex,
  isReadOnly,
}) => {
  const {
    name = '',
    address = '',
    electronicCertificate = '',
    registerOffice = '',
    ruc = '',
    socialReason = '',
    type = '',
    legalRepresentatives,
    email,
  } = company;

  const [selectedRepresentative, setSelectedRepresentative] = useState(`${REPRESENTATIVE} 1`);
  const [selectedIndexRepresentative, setSelectedIndexRepresentative] = useState(0);

  const onSelectRepresentative = (value, index) => {
    unstable_batchedUpdates(() => {
      setSelectedRepresentative(value);
      setSelectedIndexRepresentative(index);
    });
  };

  const currentLegalRepresentative = legalRepresentatives[selectedIndexRepresentative];
  const representatives = getRepresentatives(legalRepresentatives);

  const onChangeValue = (value, property) => {
    const companyToUpdate = { ...company };
    companyToUpdate[property] = value;
    onSetCompanies(companyToUpdate, companyIndex);
  };

  const onSetRepresentative = (representative, representativeIndex) => {
    const companyToUpdate = { ...company };
    companyToUpdate.legalRepresentatives[representativeIndex] = representative;
    onSetCompanies(companyToUpdate, companyIndex);
  };

  return (
    <div className={`company-card-container ${className}`}>
      <Title className="company-card-title">{title}</Title>
      <Row gutter={10} className="company-card-row company-firstSection">
        <Col>
          <WTextInput
            placeholder="Nombre de la empresa"
            value={getValueDefault(name)}
            onChange={(value) => onChangeValue(value, companyProperty.NAME)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Número de Ruc"
            value={getValueDefault(ruc)}
            onChange={(value) => onChangeValue(value, companyProperty.RUC)}
            type="number"
            maxLength={11}
            validation={validationType.RUC_LEGAL}
            errorMessage="Inválido RUC"
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Razón social"
            value={getValueDefault(socialReason)}
            onChange={(value) => onChangeValue(value, companyProperty.SOCIAL_REASON)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WSelectInput
            options={businessTypes}
            placeholder="Tipo de empresa"
            value={type}
            onChange={(value) => onChangeValue(value, companyProperty.TYPE)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Partida Electrónica"
            value={getValueDefault(electronicCertificate)}
            onChange={(value) => onChangeValue(value, companyProperty.ELECTRONIC_CERTIFICATE)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Domicilio Fiscal"
            value={getValueDefault(address)}
            onChange={(value) => onChangeValue(value, companyProperty.ADDRESS)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Sede de la Oficina Registral"
            value={getValueDefault(registerOffice)}
            onChange={(value) => onChangeValue(value, companyProperty.REGISTER_OFFICE)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Correo de contacto"
            value={getValueDefault(email)}
            onChange={(value) => onChangeValue(value, companyProperty.EMAIL)}
            readOnly={isReadOnly}
          />
        </Col>
      </Row>
      <RepresentativeCard
        className="detailRequest-containerContent-without-border"
        title={`Información Representante ${selectedIndexRepresentative + 1}`}
        representative={currentLegalRepresentative}
        selectedIndexRepresentative={selectedIndexRepresentative}
        representatives={representatives}
        selectedRepresentative={selectedRepresentative}
        onSelectRepresentative={onSelectRepresentative}
        person={personType.LEGAL}
        onSetRepresentative={onSetRepresentative}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default CompanyCard;
