/* eslint-disable prettier/prettier */
import {UserDetail} from './UserDetail'

export class Comment {
  constructor(props) {
    this.id = props.commentId;
    this.observationId = props.observationId;
    this.commentary = props.commentary;
    this.updateDate = props.updateDate;
    this.creationDate = props.creationDate;
    this.createdBy = props.createdBy;
    this.userDetails = new UserDetail(props.userDetails)
  }
}