import React from 'react';
import { Amplify } from 'aws-amplify';

import RoutesPage from './app/configuration/routes/Router';
import awsConfiguration from './configuration/aws-exports';

Amplify.configure(awsConfiguration);

function App() {
  return <RoutesPage />;
}

export default App;
