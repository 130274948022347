/* eslint-disable spaced-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
import React, { useState, useRef } from 'react';
import './index.less';
// import { pick } from 'lodash';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line camelcase,import/order
import { unstable_batchedUpdates } from 'react-dom';
import {
  CustomIcon,
  ReturnPage,
  CustomTab,
  ButtonIcon,
  ConfirmationModal,
  InformativeModal,
  ObservationModal,
  ModelObservationModal,
} from '../../components';

import ChronologyTabRequest from './ChronologyTabRequest';
import DetailTabRequest from './DetailTabRequest';
import ObservationTabRequest from './ObservationTabRequest';
import NotificationTabRequest from './NotificationTabRequest';
import RequestServices from '../../../domain/usecases/RequestServices';
import { Routes } from '../../configuration/routes/Routes';
import ModalEnterEmail from '../../components/ModalEnterEmail/ModalEnterEmail';
import { observationOptions, personType } from '../../utilities/Constant';
import ModalEnterNotification from '../../components/ModalEnterNotification/ModalEnterNotification';
import NotificationServices from '../../../domain/usecases/NotificationServices';
import { getCounterPart } from '../../../data/entities/RequestJson';

// import { requestProperty } from '../NewRequest/constants';
// import {personType} from "../../utilities/Constant";
// import RequestServices from "../../../domain/usecases/RequestServices";
const defaultShippingMessage = 'Se enviará el documento al usuario para su revisión y validación';

const DetailRequestsTemplate = ({
  requestId,
  requestDetail,
  onBack,
  observations = [],
  addComment,
  isNotUser,
  approveObservation,
  sendObservation,
  rejectObservation,
  deleteObservation,
  getRequestDetail,
  getObservations,
  updateObservation,
  isAdmin,
  isLegalOwner,
  isApplicant,
  notifications = [],
  setNotifications = () => {},
  getNotifications = () => {},
  onSetRequest,
  requestUpdate,
}) => {
  const [tabSelectedNumber, setTabSelectedNumber] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationApprovalModal, setConfirmationApprovalModal] = useState(false);
  const [confirmationValidationModal, setConfirmationValidationModal] = useState(false);
  const [confirmationCancelModal, setConfirmationCancelModal] = useState(false);
  const [informativeModal, setInformativeModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [counterPartModal, setCounterPartModal] = useState(false);
  const [confirmAnswerModal, setConfirmAnswerModal] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [showModalObservation, setShowModalObservation] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModelObservationModal, setShowModelObservationModal] = useState(false);
  const [modalEnterNotification, setModalEnterNotification] = useState(false);
  const [pendingObservationsModal, setPendingObservationsModal] = useState(false);
  const [shippingMessage, setShippingMessage] = useState(defaultShippingMessage);
  const [finishedDocumentModal, setFinishedDocumentModal] = useState(false);
  const [canceledDocumentModal, setCanceledDocumentModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showErrorShareModal, setShowErrorShareModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onObsSelected, setOnObsSelected] = useState(0);
  const SUGESTION_MODEL = observations?.some(
    (x) => x.typeId === 1 && x.state !== 'Rejected' && x.state !== 'Approved'
  );
  const { state, emailSent = false, counterpartAnswer = false, contractId = '' } = requestDetail;
  const onChangeTab = (value) => {
    setTabSelectedNumber(parseInt(value, 10));
  };

  const companyRef = useRef();
  const representativeRef = useRef();
  const requestDescriptionRef = useRef();
  const observationTabRef = useRef();
  const history = useHistory();

  const getEmailCounterPart = () => {
    const countLegal = requestDetail?.counterPart?.find((e) => e.type === personType.LEGAL);
    const countNatural = requestDetail?.counterPart?.find((e) => e.type === personType.NATURAL);
    let listEmail = [];

    if (countLegal) {
      const legalList = countLegal?.companies
        .filter((company) => company.email)
        .map((company) => company.email);
      listEmail = [...legalList];
    }

    if (countNatural) {
      const naturalList = countNatural?.legalRepresentatives
        .filter((representative) => representative.email)
        .map((representative) => representative.email);
      listEmail = [...listEmail, ...naturalList];
    }

    listEmail = [...new Set([...listEmail])];
    const countEmails = listEmail.map((email) => ({ email, registrationData: true }));
    return countEmails;
  };

  const handleCancelation = (cancelData) => {
    cancelRequest(cancelData);
  };

  const sendContract = async () => {
    setLoading(true);
    const [data, error] = await RequestServices.sendContract(requestId);

    if (data && error === null) {
      unstable_batchedUpdates(() => {
        setConfirmationModal(false);
        setInformativeModal(true);
      });
      setTimeout(() => {
        setInformativeModal(false);
        history.push(Routes.REQUEST.LIST);
      }, 2000);
    } else {
      //  todo manage the errors
    }
    setLoading(false);
  };

  const updateContract = async () => {
    try {
      setConfirmUpdateModal(false);
      const update = { ...requestUpdate };
      if (update.counterPart) {
        update.counterPart = getCounterPart(update.counterPart);
      }

      const [data, error] = await RequestServices.updateRequest(update, requestId);
      if (data && error === null) {
        setUpdateModal(true);
        setTimeout(() => {
          setUpdateModal(false);
          history.push(Routes.REQUEST.LIST);
        }, 2000);
      } else {
        setShowErrorUpdateModal(true);
        setTimeout(() => {
          setShowErrorUpdateModal(false);
        }, 2200);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const shareDocument = async (email, callback) => {
    setLoading(true);
    if (email.length > 0) {
      const emailArray = email.map((x) => x.email);
      const jsonData = { emails: emailArray };
      const [data, error] = await RequestServices.shareDocument(requestId, jsonData);
      if (data && error === null) {
        unstable_batchedUpdates(() => {
          setCounterPartModal(false);
          setInformativeModal(true);
        });
        callback([]);
        setTimeout(() => {
          setInformativeModal(false);
          getRequestDetail();
        }, 2500);
      } else {
        //  todo manage the errors
        setCounterPartModal(false);
        setShowErrorShareModal(true);
        setTimeout(() => {
          setShowErrorShareModal(false);
        }, 2500);
      }
    }
    setLoading(false);
  };

  const confirmRequest = async () => {
    setLoading(true);
    const [data, error] = await RequestServices.confirmRequest(requestId);
    if (data && error === null) {
      setConfirmAnswerModal(false);
      getRequestDetail();
    } else {
      //  todo manage the errors
    }
    setLoading(false);
  };

  const addObservation = async (observation) => {
    const [data, error] = await RequestServices.addObservations(requestDetail.requestId, {
      observations: [observation],
    });

    if (data && error === null) {
      unstable_batchedUpdates(() => {
        setShowModalObservation(false);
        getObservations();
      });
    } else {
      //  todo manage the errors
    }
  };

  const getObservationFunction = () => {
    const optionSelected = observationTabRef?.current?.optionSelected ?? 0;
    if (optionSelected === 0) {
      setShowModalObservation(true);
    } else if (optionSelected === 1) {
      //alert("Show Observation Model ==> Modal ")
      setShowModalConfirmation(true);
    }
  };

  const handleNotification = async (notification) => {
    const [data, error] = await NotificationServices.addNotification(requestId, notification);
    if (data && error === null) {
      setModalEnterNotification(false);
      getRequestDetail();
      getNotifications();
    } else {
      //  todo manage the errors
      setShowErrorModal(true);
      setTimeout(() => {
        setShowErrorModal(false);
      }, 2000);
    }
  };

  const documentReturn = async () => {
    const [data, error] = await NotificationServices.documentReturn(requestId);
    if (data && error === null) {
      getRequestDetail();
      getNotifications();
    } else {
      //  todo manage the errors
      setShowErrorModal(true);
      setTimeout(() => {
        setShowErrorModal(false);
      }, 2000);
    }
  };

  const approvalRequest = async () => {
    const [data, error] = await RequestServices.approvalRequest(requestId);
    if (data && error === null) {
      setLoading(false);
      setConfirmationApprovalModal(false);
      setInformativeModal(true);
      setShippingMessage(
        'Se enviará el documento a la sub gerencia del Área Legal para su validación'
      );
      setTimeout(() => {
        setInformativeModal(false);
        setShippingMessage(defaultShippingMessage);
        getRequestDetail();
      }, 2000);
    } else {
      //  todo manage the errors
    }
  };

  const handleApproval = async () => {
    setLoading(true);
    const pendingObservations = observations.filter((x) => x.state === 'New');
    if (pendingObservations.length > 0) {
      setPendingObservationsModal(true);
      setTimeout(() => {
        setPendingObservationsModal(false);
      }, 2000);
    } else {
      await approvalRequest();
    }
  };

  const validateRequest = async () => {
    setLoading(true);
    const [data, error] = await RequestServices.validate(requestId);
    if (data && error === null) {
      setConfirmationValidationModal(false);
      setFinishedDocumentModal(true);
      setTimeout(() => {
        setFinishedDocumentModal(false);
        history.push(Routes.REQUEST.LIST);
      }, 2000);
    } else {
      //  todo manage the errors
    }
    setLoading(false);
  };
  const cancelRequest = async (cancelData) => {
    setLoading(true);
    const [data, error] = await RequestServices.cancel(requestId, cancelData);
    if (data && error === null) {
      setConfirmationCancelModal(false);
      setCanceledDocumentModal(true);
      setTimeout(() => {
        setCanceledDocumentModal(false);
        history.push(Routes.REQUEST.LIST);
      }, 2000);
    } else {
      //  todo manage the errors
    }
    setLoading(false);
  };

  const handleObsSelected = (option) => {
    setOnObsSelected(option);
  };
  const activeObservationsQuantity = observations.filter((x) => x.state === 'New').length;
  const activeNotificationsQuantity = notifications.filter((x) => x.state === 'New').length;

  const enabledButton =
    isNotUser &&
    tabSelectedNumber === 1 &&
    ['Process'].includes(state) &&
    activeObservationsQuantity === 0 &&
    activeNotificationsQuantity === 0;
  return (
    <>
      <div className="container-detailRequest">
        <CustomTab
          onChange={onChangeTab}
          leftContent={
            <div className="detailRequest-return">
              <ReturnPage className="detailRequest-returnPage" onChangeType={onBack} />
              <span className="title">{`Solicitud #${contractId}`}</span>
            </div>
          }
          rightContent={
            <>
              {/** * Responsable legal tab 1** */}
              {isLegalOwner && tabSelectedNumber === 1 && ['Process'].includes(state) && (
                <div className={`detailRequest-bnCounterpart ${!enabledButton && 'disabled'}`}>
                  <ButtonIcon
                    label="Enviar Contrato"
                    disabled={!enabledButton}
                    onClick={() => setConfirmationModal(true)}
                  />
                </div>
              )}
              {isLegalOwner && tabSelectedNumber === 3 && (
                <div className="detailRequest-bnCounterpart">
                  {onObsSelected === 0 || !SUGESTION_MODEL ? (
                    <ButtonIcon label="Observar" onClick={getObservationFunction} />
                  ) : null}
                </div>
              )}
              {/*** Admin ***/}
              {isAdmin && tabSelectedNumber === 1 && ['Validation'].includes(state) && (
                <div className="detailRequest-bnCounterpart left">
                  <ButtonIcon
                    label="Validar"
                    icon="validate"
                    onClick={() => setConfirmationValidationModal(true)}
                  />
                </div>
              )}
              {isAdmin && tabSelectedNumber === 1 && !['Finalized', 'Cancelled'].includes(state) && (
                <div className="detailRequest-bnCounterpart">
                  <ButtonIcon
                    label="Cancelar"
                    icon="cancel"
                    onClick={() => setConfirmationCancelModal(true)}
                  />
                </div>
              )}
              {/** * Solicitante tab 1** */}
              {!isNotUser &&
                tabSelectedNumber === 1 &&
                ['Review'].includes(state) &&
                !counterpartAnswer && (
                  <div className="detailRequest-bnCounterpart">
                    <ButtonIcon
                      label="Contraparte"
                      icon="send"
                      onClick={() => setCounterPartModal(true)}
                      disabled={emailSent}
                      style={emailSent ? { opacity: 0.5 } : {}}
                    />
                  </div>
                )}
              {!isNotUser &&
                tabSelectedNumber === 1 &&
                ['Review'].includes(state) &&
                counterpartAnswer && (
                  <div className="detailRequest-bnCounterpart">
                    <ButtonIcon
                      label="Visto bueno"
                      icon="approval"
                      onClick={() => setConfirmationApprovalModal(true)}
                    />
                  </div>
                )}
              {/** * Solicitante tab 4** */}
              {!isNotUser && tabSelectedNumber === 4 && ['Review', 'Validation'].includes(state) && (
                <div className="detailRequest-bnCounterpart">
                  <ButtonIcon label="Notificar" onClick={() => setModalEnterNotification(true)} />
                </div>
              )}
            </>
          }
          tabs={[
            {
              id: 1,
              key: 'chronology',
              title: (
                <div className="tab-custom">
                  <div className="v-line" />
                  <CustomIcon type="tabChronology" className="icon" />
                  <span>Cronología</span>
                </div>
              ),
              children: (
                <ChronologyTabRequest
                  requestDetail={requestDetail}
                  observations={observations}
                  confirmAction={() => setConfirmAnswerModal(true)}
                  observationQuantity={activeObservationsQuantity}
                  isNotUser={isNotUser}
                  isAdmin={isAdmin}
                  isApplicant={isApplicant}
                  getRequestDetail={getRequestDetail}
                  getNotifications={getNotifications}
                />
              ),
            },
            {
              id: 2,
              key: 'detail',
              title: (
                <div className="tab-custom">
                  <div className="v-line" />
                  <CustomIcon type="tabDetail" className="icon" />
                  <span>Detalles</span>
                </div>
              ),
              children: (
                <DetailTabRequest
                  requestDetail={requestDetail}
                  companyRef={companyRef}
                  representativeRef={representativeRef}
                  requestDescriptionRef={requestDescriptionRef}
                  observations={observations}
                  updateContract={() => setConfirmUpdateModal(true)}
                  onSetRequest={onSetRequest}
                  requestUpdate={requestUpdate}
                />
              ),
            },
            {
              id: 3,
              key: 'observation',
              title: (
                <div className="tab-custom">
                  <div className="v-line" />
                  <CustomIcon type="tabObservation" className="icon" />
                  <span>Observaciones</span>
                  <div className="quantity">
                    <div className="quantity-item">{activeObservationsQuantity}</div>
                  </div>
                </div>
              ),
              children: (
                <ObservationTabRequest
                  requestId={requestId}
                  requestObservations={observations}
                  addComment={addComment}
                  isLegalOwner={isLegalOwner}
                  isNotUser={isNotUser}
                  isAdmin={isAdmin}
                  isApplicant={isApplicant}
                  approveObservation={approveObservation}
                  sendObservation={sendObservation}
                  rejectObservation={rejectObservation}
                  deleteObservation={deleteObservation}
                  ref={observationTabRef}
                  updateObservation={updateObservation}
                  onOptionObsSelected={handleObsSelected}
                />
              ),
            },
            {
              id: 4,
              key: 'notification',
              title: (
                <div className="tab-custom">
                  <div className="v-line" />
                  <CustomIcon type="tabNotification" className="icon" />
                  <span>Notificaciones</span>
                  <div className="quantity">
                    <div className="quantity-item">{activeNotificationsQuantity}</div>
                  </div>
                </div>
              ),
              children: (
                <NotificationTabRequest
                  requestId={requestId}
                  isNotUser={isNotUser}
                  setNotificationsInParent={setNotifications}
                  tabSelectedNumber={tabSelectedNumber}
                  isLegalOwner={isLegalOwner}
                />
              ),
            },
          ]}
        />
        <ConfirmationModal
          visible={confirmationModal}
          icon="alertNotification"
          title="Confirmación de envío"
          subtitle="¿Estás seguro de enviar el contrato?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmationModal(false)}
          onClickBnOne={() => setConfirmationModal(false)}
          onClickBnTwo={() => sendContract(requestId)}
          isLoading={loading}
        />
        <ConfirmationModal
          visible={confirmationApprovalModal}
          icon="alertNotification"
          title="Confirmación de envío"
          subtitle="¿Estás seguro de dar el visto bueno?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmationApprovalModal(false)}
          onClickBnOne={() => setConfirmationApprovalModal(false)}
          onClickBnTwo={() => handleApproval()}
          isLoading={loading}
        />
        <ConfirmationModal
          visible={confirmationValidationModal}
          icon="alertNotification"
          title="Confirmación de validación"
          subtitle="¿Estás seguro de validar el contrato?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmationValidationModal(false)}
          onClickBnOne={() => setConfirmationValidationModal(false)}
          onClickBnTwo={() => validateRequest()}
          isLoading={loading}
        />
        <ConfirmationModal
          visible={confirmationCancelModal}
          icon="alertNotification"
          title="Confirmación de cancelación"
          subtitle="¿Estás seguro de cancelar el contrato?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmationCancelModal(false)}
          onClickBnOne={() => setConfirmationCancelModal(false)}
          onOptionCancelation={(info) => handleCancelation(info)}
          onClickBnTwo={() => false}
          textArea
          isLoading={loading}
        />
        <InformativeModal
          title="Documentado enviado"
          visible={informativeModal}
          subtitle={shippingMessage}
          icon="sentNotification"
        />
        <InformativeModal
          title="Edición exitosa"
          visible={updateModal}
          subtitle="Cambios guardados con éxito."
        />
        <InformativeModal
          title="¡Error!"
          visible={showErrorModal}
          subtitle="No se pudo enviar el contrato"
          icon="alert"
        />
        <InformativeModal
          title="¡Error!"
          visible={showErrorUpdateModal}
          subtitle="No se pudo actualizar el contrato"
          icon="alert"
        />
        <InformativeModal
          title="¡Error!"
          visible={showErrorShareModal}
          subtitle="No se pudo compartir el contrato a la contraparte"
          icon="alert"
        />
        <ModalEnterEmail
          visible={counterPartModal}
          onClose={() => setCounterPartModal(false)}
          onShare={shareDocument}
          loading={loading}
          emailsCounterpart={getEmailCounterPart()}
        />
        <ConfirmationModal
          visible={confirmAnswerModal}
          icon="alertNotification"
          title="Confirmación de respuesta"
          subtitle="¿La contraparte revisó el documento? Si revisó el contrato y recibió una respuesta continue."
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmAnswerModal(false)}
          onClickBnOne={() => setConfirmAnswerModal(false)}
          onClickBnTwo={() => confirmRequest(requestId)}
          isLoading={loading}
        />
        <ConfirmationModal
          visible={confirmUpdateModal}
          icon="alertNotification"
          title="Confirmación de edición"
          subtitle="¿Desea guardar los cambios?"
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setConfirmUpdateModal(false)}
          onClickBnOne={() => setConfirmUpdateModal(false)}
          onClickBnTwo={() => updateContract()}
        />
        <ObservationModal
          visible={showModalObservation}
          onClickClose={() => setShowModalObservation(false)}
          onClickButton={addObservation}
          options={observationOptions}
        />
        <ConfirmationModal
          visible={showModalConfirmation}
          icon="alertNotification"
          title="Observación de modelo"
          subtitle="¿Desea observar el modelo? La solicitud no podrá desarrollarse hasta tener una respuesta por parte del usuario."
          buttonOne="Cancelar"
          buttonTwo="Aceptar"
          onClose={() => setShowModalConfirmation(false)}
          onClickBnOne={() => setShowModalConfirmation(false)}
          onClickBnTwo={() => {
            setShowModalConfirmation(false);
            setShowModelObservationModal(true);
          }}
        />
        <ModelObservationModal
          visible={showModelObservationModal}
          cancel={() => setShowModelObservationModal(false)}
          sendObservation={addObservation}
        />
        <ModalEnterNotification
          visible={modalEnterNotification}
          onClose={() => setModalEnterNotification(false)}
          onOptionNotification={handleNotification}
          documentReturn={documentReturn}
        />
        <InformativeModal
          title="Observaciones pendientes"
          visible={pendingObservationsModal}
          subtitle="Hay observaciones pendientes, corrígelas y continue."
          icon="obsNotification"
        />
        <InformativeModal
          title="Documento finalizado"
          visible={finishedDocumentModal}
          subtitle="Se finalizó con el contrato solicitado"
          icon="finishedNotification"
        />
        <InformativeModal
          title="Documento cancelado"
          visible={canceledDocumentModal}
          subtitle="Se canceló el contrato"
          icon="obsNotification"
        />
      </div>
    </>
  );
};

DetailRequestsTemplate.propTypes = {};

export default DetailRequestsTemplate;
