import './index.less';
import React from 'react';
import { Button, Row } from 'antd';
import { ReactSVG } from 'react-svg';
import { pathServer } from '../../utilities/Constant';

const WButtonModel = ({ user }) => {
  const { icon, descripcion } = user;
  return (
    <Button>
      <Row className="container">
        <ReactSVG className="icon-svg" src={`${pathServer.PATH_ICONS}${icon || 'ic_add.svg'}`} />
        <span className="descripcion">{descripcion}</span>
      </Row>
    </Button>
  );
};
WButtonModel.prototype = {};
export default WButtonModel;
