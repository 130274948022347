import React, { useEffect, useState } from 'react';
import { findIndex, forEach } from 'lodash';
import { Col, Row, Form } from 'antd';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';

import { requestProperty } from '../constants';
import { COMPANY, companyProperty, personType, REPRESENTATIVE } from '../../../utilities/Constant';
import { setInitialValue } from './constant';
import { maxRepresentative } from '../../../utilities/Functions';

import {
  ContainerCard,
  CustomIcon,
  LegalRepresentativeCard,
  NaturalRepresentativeCard,
  Toggle,
} from '../../../components';
import HeaderCompany from '../../../components/HeaderCompany';

import './index.less';

const counterPartTypeMap = {
  0: personType.LEGAL,
  1: personType.NATURAL,
};

const FourthStepRequestTemplate = ({ fourthStepData }) => {
  const { counterPart, onSetRequest, selectedPersonType, errorMessages } = fourthStepData;
  const [optionSelected, setOptionSelected] = useState(
    selectedPersonType === personType.LEGAL ? 0 : 1
  );
  const [selectedCompany, setSelectedCompany] = useState(`${COMPANY} 1`);
  const [selectedIndexCompany, setSelectedIndexCompany] = useState(0);
  const [selectedRepresentative, setSelectedRepresentative] = useState(`${REPRESENTATIVE} 1`);
  const [selectedIndexRepresentative, setSelectedIndexRepresentative] = useState(0);
  const [cleanValues, setCleanValues] = useState(false);
  const personLegal = counterPart.find((cp) => cp.type === personType.LEGAL) ?? {};
  const personNatural = counterPart.find((cp) => cp.type === personType.NATURAL);
  const companies = personLegal.companies.map((x) => x.company);
  const currentCompany = personLegal.companies[selectedIndexCompany];
  const currentLegalRepresentative =
    currentCompany[companyProperty.LEGAL_REPRESENTATIVES][selectedIndexRepresentative];
  const person = optionSelected === 0 ? currentCompany : personNatural;
  const representatives = person[companyProperty.LEGAL_REPRESENTATIVES].map(
    (re) => re.representative
  );
  const quantityNatural = personNatural.legalRepresentatives.length;
  const [form] = Form.useForm();
  const savedData = setInitialValue(personLegal, personNatural);

  const onOptionSelected = (value) => {
    setOptionSelected(value);
    onSetRequest(requestProperty.CURRENT_COUNTER_PART, counterPartTypeMap[value]);
  };

  useEffect(() => {
    if (errorMessages) {
      form.validateFields();
    }
  }, [selectedRepresentative, selectedCompany, quantityNatural]);

  useEffect(() => {
    if (errorMessages) {
      form.submit();
    } else {
      form.resetFields();
    }
  }, [errorMessages, optionSelected]);

  const onDeleteCompany = () => {
    const counterPartToUpdate = [...counterPart];
    const companyInitial = counterPartToUpdate[optionSelected].companies[0];
    let companiesFiltered;
    forEach(counterPartToUpdate, (cp) => {
      if (cp.type === personType.LEGAL) {
        companiesFiltered = cp.companies.filter((co) => {
          return co.company !== selectedCompany;
        });
        // eslint-disable-next-line no-param-reassign
        cp.companies = companiesFiltered;
        if (companiesFiltered?.length === 1) {
          // eslint-disable-next-line no-param-reassign
          cp.lastCompany = 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          cp.lastCompany = maxRepresentative(cp.companies, 'company');
        }
      }
    });

    unstable_batchedUpdates(() => {
      setSelectedCompany(companyInitial.company);
      setSelectedIndexCompany(0);
      setSelectedIndexRepresentative(0);
      setSelectedRepresentative(companyInitial.legalRepresentatives[0].representative);
    });
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };
  const onResetCompany = () => {
    const counterPartToUpdate = [...counterPart];
    const companyInitial = counterPartToUpdate[optionSelected].companies[0];

    let companiesFiltered;
    forEach(counterPartToUpdate, (cp) => {
      if (cp.type === personType.LEGAL) {
        if (cp.companies.some((co) => co.company === selectedCompany)) {
          // eslint-disable-next-line no-param-reassign
          cp.companies = cp.companies.map((co) => {
            if (co.company === selectedCompany) {
              return {
                company: selectedCompany,
                [companyProperty.LEGAL_REPRESENTATIVES]: [
                  {
                    representative: `${REPRESENTATIVE} 1`,
                  },
                ],
                lastRepresentative: 1,
              };
            }
            return co;
          });
        }
        if (companiesFiltered?.length === 1) {
          // eslint-disable-next-line no-param-reassign
          cp.lastCompany = 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          cp.lastCompany = maxRepresentative(cp.companies, 'company');
        }
      }
    });

    unstable_batchedUpdates(() => {
      setSelectedCompany(selectedCompany);
      setSelectedIndexCompany(selectedIndexCompany);
      setSelectedIndexRepresentative(0);
      setSelectedRepresentative(companyInitial.legalRepresentatives[0].representative);
    });
    setCleanValues(!cleanValues);
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const onDeleteRepresentative = (representative) => {
    const counterPartToUpdate = [...counterPart];
    let representativesList;
    if (optionSelected === 0) {
      // legal person
      representativesList =
        counterPartToUpdate[optionSelected].companies[selectedIndexCompany][
          companyProperty.LEGAL_REPRESENTATIVES
        ];
      representativesList = representativesList.filter(
        (re) => re.representative !== representative
      );
      counterPartToUpdate[optionSelected].companies[selectedIndexCompany][
        companyProperty.LEGAL_REPRESENTATIVES
      ] = representativesList;
      const repQuantity = representativesList.length;
      if (repQuantity === 1) {
        counterPartToUpdate[optionSelected].companies[selectedIndexCompany].lastRepresentative = 1;
      } else {
        counterPartToUpdate[optionSelected].companies[selectedIndexCompany].lastRepresentative =
          maxRepresentative(representativesList);
      }
    } else {
      // natural person
      representativesList =
        counterPartToUpdate[optionSelected][companyProperty.LEGAL_REPRESENTATIVES];
      representativesList = representativesList.filter(
        (re) => re.representative !== representative
      );
      counterPartToUpdate[optionSelected][companyProperty.LEGAL_REPRESENTATIVES] =
        representativesList;
      const repQuantity = representativesList.length;
      if (repQuantity === 1) {
        counterPartToUpdate[optionSelected].lastRepresentative = 1;
      } else {
        counterPartToUpdate[optionSelected].lastRepresentative =
          maxRepresentative(representativesList);
      }
    }

    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const onDeleteRepresentativeFromCompany = (representative) => {
    const legalRepresentative =
      counterPart[optionSelected].companies[selectedIndexCompany][
        companyProperty.LEGAL_REPRESENTATIVES
      ][0];
    unstable_batchedUpdates(() => {
      setSelectedIndexRepresentative(0);
      setSelectedRepresentative(legalRepresentative.representative);
    });
    onDeleteRepresentative(representative);
  };

  const addCompany = () => {
    const counterPartToUpdate = [...counterPart];
    const legalPerson = counterPartToUpdate[optionSelected];
    const quantity = legalPerson.lastCompany + 1;
    const company = `${COMPANY} ${quantity}`;
    counterPartToUpdate[optionSelected].companies.push({
      company,
      [companyProperty.LEGAL_REPRESENTATIVES]: [
        {
          representative: `${REPRESENTATIVE} 1`,
        },
      ],
      lastRepresentative: 1,
    });
    counterPartToUpdate[optionSelected].lastCompany = quantity;
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const addRepresentativeToCompany = () => {
    const counterPartToUpdate = [...counterPart];
    const legalPerson = counterPartToUpdate[optionSelected];
    const quantity = legalPerson.companies[selectedIndexCompany].lastRepresentative + 1;
    const representative = `${REPRESENTATIVE} ${quantity}`;
    counterPartToUpdate[optionSelected].companies[selectedIndexCompany][
      companyProperty.LEGAL_REPRESENTATIVES
    ].push({
      representative,
    });
    counterPartToUpdate[optionSelected].companies[selectedIndexCompany].lastRepresentative =
      quantity;
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const addRepresentative = () => {
    const counterPartToUpdate = [...counterPart];
    const quantity = counterPartToUpdate[optionSelected].lastRepresentative + 1;
    const representative = `${REPRESENTATIVE} ${quantity}`;
    counterPartToUpdate[optionSelected][companyProperty.LEGAL_REPRESENTATIVES].push({
      representative,
    });
    counterPartToUpdate[optionSelected].lastRepresentative = quantity;
    onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const onSelectCompany = (company) => {
    const indexFound = findIndex(
      counterPart[optionSelected].companies,
      (co) => co.company === company
    );
    const legalRepresentative =
      counterPart[optionSelected].companies[indexFound][companyProperty.LEGAL_REPRESENTATIVES][0];
    unstable_batchedUpdates(() => {
      setSelectedCompany(company);
      setSelectedIndexCompany(indexFound);
      setSelectedIndexRepresentative(0);
      setSelectedRepresentative(legalRepresentative.representative);
    });
  };

  const onSelectRepresentative = (representative) => {
    const companyFiltered = counterPart[optionSelected].companies[selectedIndexCompany];
    const indexFound = findIndex(companyFiltered[companyProperty.LEGAL_REPRESENTATIVES], (re) => {
      return re.representative === representative;
    });
    unstable_batchedUpdates(() => {
      setSelectedRepresentative(representative);
      setSelectedIndexRepresentative(indexFound);
    });
  };
  const resetItemValues = (index) => {
    const quantity = index + 1;
    const representative = `${REPRESENTATIVE} ${quantity}`;
    const updatedRepresentatives = [
      ...counterPart[optionSelected][companyProperty.LEGAL_REPRESENTATIVES],
    ];
    updatedRepresentatives[index] = {
      representative,
    };
    const updatedCounterPart = [...counterPart];
    updatedCounterPart[optionSelected][companyProperty.LEGAL_REPRESENTATIVES] =
      updatedRepresentatives;
    onSetRequest(requestProperty.COUNTER_PART, updatedCounterPart);
  };
  const deleteRepresentative = (index) => {
    const updatedCounterPart = [...counterPart];
    let representativesList;

    if (optionSelected === 0) {
      // Persona legal
      representativesList =
        updatedCounterPart[optionSelected].companies[selectedIndexCompany][
          companyProperty.LEGAL_REPRESENTATIVES
        ];
      representativesList.splice(index, 1);

      // Actualizar lastRepresentative
      if (representativesList.length === 1) {
        updatedCounterPart[optionSelected].companies[selectedIndexCompany].lastRepresentative = 1;
      } else {
        updatedCounterPart[optionSelected].companies[selectedIndexCompany].lastRepresentative =
          maxRepresentative(representativesList);
      }
    } else {
      // Persona natural
      representativesList =
        updatedCounterPart[optionSelected][companyProperty.LEGAL_REPRESENTATIVES];
      representativesList.splice(index, 1);

      // Actualizar lastRepresentative
      if (representativesList.length === 1) {
        updatedCounterPart[optionSelected].lastRepresentative = 1;
      } else {
        updatedCounterPart[optionSelected].lastRepresentative =
          maxRepresentative(representativesList);
      }
    }

    // Actualizar el estado
    onSetRequest(requestProperty.COUNTER_PART, updatedCounterPart);
  };

  return (
    <>
      <Form name="step-4" autoComplete="off" form={form} initialValues={savedData}>
        <Toggle
          className="fouthStepRequest-toggle"
          title="Contraparte"
          subtitle="Ingrese descripción de la contraparte"
          options={[
            { label: 'Persona Jurídica', value: 'Persona Jurídica' },
            { label: 'Persona Natural', value: 'Persona Natural' },
          ]}
          onChange={onOptionSelected}
          optionSelected={optionSelected}
        />

        {optionSelected === 0 && (
          <Row>
            <Col span={20}>
              <HeaderCompany
                options={companies}
                selectedOption={selectedCompany}
                onSelect={onSelectCompany}
                onClick={addCompany}
              />
            </Col>
            <Col span={4} className="btn-delete-company">
              <button type="button" className="buttonClean" onClick={onResetCompany}>
                <CustomIcon className="refresh_icon" type="refresh" />
                Restablecer campos
              </button>
              {selectedCompany !== `${COMPANY} 1` && (
                <>
                  <CustomIcon
                    className="icon-delete"
                    onClick={onDeleteCompany}
                    type="deleteRepresentative"
                  />
                </>
              )}
            </Col>
          </Row>
        )}

        {optionSelected === 0 ? (
          <div className="fouthStepRequest-containerRepresentative">
            <LegalRepresentativeCard
              personTypeIndex={optionSelected}
              onFormStateChange={onSetRequest}
              counterPart={counterPart}
              currentCompany={currentCompany}
              selectedIndexCompany={selectedIndexCompany}
              representatives={representatives}
              selectedRepresentative={selectedRepresentative}
              onSelectRepresentative={onSelectRepresentative}
              addRepresentative={addRepresentativeToCompany}
              selectedIndexRepresentative={selectedIndexRepresentative}
              currentLegalRepresentative={currentLegalRepresentative}
              onDeleteRepresentative={onDeleteRepresentativeFromCompany}
              cleanValues={cleanValues}
              errorMessages={errorMessages}
            />
          </div>
        ) : (
          counterPart[optionSelected][companyProperty.LEGAL_REPRESENTATIVES].map((item, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`representative-${index}`}
              className="fouthStepRequest-containerRepresentative"
            >
              <ContainerCard className="firstRepresentativeCard-container">
                <NaturalRepresentativeCard
                  title={item.representative}
                  personTypeIndex={optionSelected}
                  onFormStateChange={onSetRequest}
                  counterPart={counterPart}
                  selectedIndexRepresentative={index}
                  currentRepresentative={item}
                  errorMessages={errorMessages}
                  cleanValues={cleanValues}
                  resetItemValues={() => resetItemValues(index)}
                  handleDeleteRepresentative={() => deleteRepresentative(index)}
                />
              </ContainerCard>
            </div>
          ))
        )}

        {optionSelected === 1 && (
          <span className="add_representative-orange" onClick={addRepresentative}>
            Agregar representante
            <CustomIcon className="add_icon" type="addRepresentative" />
          </span>
        )}
      </Form>
    </>
  );
};

FourthStepRequestTemplate.propTypes = {};

export default FourthStepRequestTemplate;
