import React from 'react';
import { Row, Col } from 'antd';

import WSelectInput from '../SelectInput';
import WButtonIcon from '../ButtonIcon';

import './index.less';

const WHeaderCompany = ({
  options,
  selectedOption,
  onSelect,
  buttonText = 'Crear empresa',
  onClick = () => {},
  showAdd = true,
}) => {
  return (
    <Row className="header-company-row">
      <Col className="select-col-company">
        <WSelectInput
          dropdownClassName="dropdown-company"
          options={options}
          value={selectedOption}
          onChange={(value) => onSelect(value)}
        />
      </Col>
      {showAdd && (
        <Col className="select-col-btn">
          <WButtonIcon
            className="btn-add-circle button-icon"
            label={<span className="title-label">{buttonText}</span>}
            icon="addCircle"
            onClick={onClick}
          />
        </Col>
      )}
    </Row>
  );
};

WHeaderCompany.propTypes = {};

export default WHeaderCompany;
