import { toast } from 'react-toastify';

export const pathServer = {
  PATH_ICONS: `${process.env.PUBLIC_URL}/icons/`,
  PATH_IMG: `${process.env.PUBLIC_URL}/img/`,
};
export const CAPTCHA_KEY = 'USAR SU PROPIO API-KEY DE SU DOMINIO';
export const FILE_DONE = 'done';

export const ROL_CODE = {
  legalOwner: '1628723213',
  applicant: '1628723212',
  admin: '1628723211',
};
export const SECTION_DETAIL = {
  request: 'request',
  counterPart: 'counterPart',
  attached: 'attached',
  contractModel: 'contractModel',
};
export const justifyEmpty = {
  justify: {
    description: null,
    documents: [],
  },
};
export const FIELD_NEXTTIME_LOGIN = 'nextTime';

export const Icons = {
  default: {
    url: `${pathServer.PATH_ICONS}ic_email.svg`,
  },
  successfull: {
    url: `${pathServer.PATH_ICONS}ic_successfull.svg`,
  },
  alert: {
    url: `${pathServer.PATH_ICONS}ic_alert.svg`,
  },
  close: {
    url: `${pathServer.PATH_ICONS}ic_close.svg`,
  },
  upload: {
    url: `${pathServer.PATH_ICONS}ic_upload.svg`,
  },
  doc: {
    url: `${pathServer.PATH_ICONS}ic_doc.svg`,
  },
  deleteWhite: {
    url: `${pathServer.PATH_ICONS}ic_deleteWhite.svg`,
  },
  ic_alert_withoutBackg: {
    url: `${pathServer.PATH_ICONS}ic_alert_withoutBackg.svg`,
  },
  doc_big: {
    url: `${pathServer.PATH_ICONS}ic_doc_big.svg`,
  },
  doc_small: {
    url: `${pathServer.PATH_ICONS}ic_doc_small.svg`,
  },
  zoom: {
    url: `${pathServer.PATH_ICONS}ic_zoom.svg`,
  },
  file: {
    url: `${pathServer.PATH_ICONS}ic_file.svg`,
  },
  fileGray: {
    url: `${pathServer.PATH_ICONS}ic_file_gray.svg`,
  },
  zoomOrange: {
    url: `${pathServer.PATH_ICONS}ic_zoom_orange.svg`,
  },
  closeBlue: {
    url: `${pathServer.PATH_ICONS}ic_close_blue.svg`,
  },
  closeGrey: {
    url: `${pathServer.PATH_ICONS}ic_close_grey.svg`,
  },
  alertNotification: {
    url: `${pathServer.PATH_ICONS}ic_alertNotification.svg`,
  },
  obsNotification: {
    url: `${pathServer.PATH_ICONS}ic_obsNotification.svg`,
  },
  successNotification: {
    url: `${pathServer.PATH_ICONS}ic_successNotification.svg`,
  },
  sentNotification: {
    url: `${pathServer.PATH_ICONS}ic_sentNotification.svg`,
  },
  session_expired: {
    url: `${pathServer.PATH_ICONS}ic_session_expired.svg`,
  },
  finishedNotification: {
    url: `${pathServer.PATH_ICONS}ic_finishedNotification.svg`,
  },
  deleteEmail: {
    url: `${pathServer.PATH_ICONS}ic_deleteEmail.svg`,
  },
  addEmail: {
    url: `${pathServer.PATH_ICONS}ic_addEmail.svg`,
  },
  arrowLeft: {
    url: `${pathServer.PATH_ICONS}arrowLeft.svg`,
  },
  requestAll: {
    url: `${pathServer.PATH_ICONS}ic_request_all.svg`,
  },
  requestWait: {
    url: `${pathServer.PATH_ICONS}ic_request_wait.svg`,
  },
  requestFinalized: {
    url: `${pathServer.PATH_ICONS}ic_request_finalized.svg`,
  },
  requestObservation: {
    url: `${pathServer.PATH_ICONS}ic_request_observation.svg`,
  },
  requestProcess: {
    url: `${pathServer.PATH_ICONS}ic_request_process.svg`,
  },
  requestRevision: {
    url: `${pathServer.PATH_ICONS}ic_request_revision.svg`,
  },
  requestValidation: {
    url: `${pathServer.PATH_ICONS}ic_request_validation.svg`,
  },
  hidePassword: {
    url: `${pathServer.PATH_ICONS}ic_hide_password.svg`,
  },
  showPassword: {
    url: `${pathServer.PATH_ICONS}ic_show_password.svg`,
  },
  notification: {
    url: `${pathServer.PATH_ICONS}ic_notification.svg`,
  },
  listUser: {
    url: `${pathServer.PATH_ICONS}ic_user.svg`,
  },
  showRow: {
    url: `${pathServer.PATH_ICONS}ic_show_row.svg`,
  },
  download: {
    url: `${pathServer.PATH_ICONS}ic_download.svg`,
  },
  calendar: {
    url: `${pathServer.PATH_ICONS}ic_calendar.svg`,
  },
  blueCalendar: {
    url: `${pathServer.PATH_ICONS}ic_calendarBlue.svg`,
  },

  selectCalendar: {
    url: `${pathServer.PATH_ICONS}ic_select_calendar.svg`,
  },
  pdf: {
    url: `${pathServer.PATH_ICONS}ic_pdf.svg`,
  },
  refresh: {
    url: `${pathServer.PATH_ICONS}ic_refresh.svg`,
  },
  jpg: {
    url: `${pathServer.PATH_ICONS}ic_jpg.svg`,
  },
  xls: {
    url: `${pathServer.PATH_ICONS}ic_xls.svg`,
  },
  contract: {
    url: `${pathServer.PATH_ICONS}ic_contract.svg`,
  },
  showDoc: {
    url: `${pathServer.PATH_ICONS}ic_show_doc.svg`,
  },
  downloadDoc: {
    url: `${pathServer.PATH_ICONS}ic_download_doc.svg`,
  },
  downloadFile: {
    url: `${pathServer.PATH_ICONS}ic_download_file.svg`,
  },
  uploadFile: {
    url: `${pathServer.PATH_ICONS}ic_upload_file.svg`,
  },
  deleteDoc: {
    url: `${pathServer.PATH_ICONS}ic_delete_doc.svg`,
  },
  checkGreen: {
    url: `${pathServer.PATH_ICONS}ic_check_green.svg`,
  },
  folderOrange: {
    url: `${pathServer.PATH_ICONS}ic_folder_orange.svg`,
  },
  folderGrey: {
    url: `${pathServer.PATH_ICONS}ic_folder_grey.svg`,
  },
  drive: {
    url: `${pathServer.PATH_ICONS}ic_drive.svg`,
  },
  deleteRed: {
    url: `${pathServer.PATH_ICONS}ic_delete_red.svg`,
  },
  deleteOrange: {
    url: `${pathServer.PATH_ICONS}ic_delete_orange.svg`,
  },
  rejectRed: {
    url: `${pathServer.PATH_ICONS}ic_reject_red.svg`,
  },
  editNotification: {
    url: `${pathServer.PATH_ICONS}ic_edit_notification.svg`,
  },
  editRequest: {
    url: `${pathServer.PATH_ICONS}ic_editRequest.svg`,
  },
  requestTable: {
    url: `${pathServer.PATH_ICONS}ic_requestTable.svg`,
  },
  menuMiembros: {
    url: `${pathServer.PATH_ICONS}ic_menuMiembros.svg`,
  },
  menuRepositorio: {
    url: `${pathServer.PATH_ICONS}ic_menuRepositorio.svg`,
  },
  menuSolicitudes: {
    url: `${pathServer.PATH_ICONS}ic_menuSolicitudes.svg`,
  },
  menuHome: {
    url: `${pathServer.PATH_ICONS}ic_menuHome.svg`,
  },
  repositoryRecents: {
    url: `${pathServer.PATH_ICONS}ic_repositoryRecents.svg`,
  },
  repositoryFavorites: {
    url: `${pathServer.PATH_ICONS}ic_repositoryFavorites.svg`,
  },
  repositoryShared: {
    url: `${pathServer.PATH_ICONS}ic_repositoryShared.svg`,
  },
  repositoryRepository: {
    url: `${pathServer.PATH_ICONS}ic_repositoryRepository.svg`,
  },
  collapsed: {
    url: `${pathServer.PATH_ICONS}ic_collapsed.svg`,
  },
  noCollapsed: {
    url: `${pathServer.PATH_ICONS}ic_noCollapsed.svg`,
  },
  seeProfile: {
    url: `${pathServer.PATH_ICONS}ic_seeProfile.svg`,
  },
  successAlert: {
    url: `${pathServer.PATH_ICONS}ic_successAlert.svg`,
  },
  warningAlert: {
    url: `${pathServer.PATH_ICONS}ic_warningAlert.svg`,
  },
  errorAlert: {
    url: `${pathServer.PATH_ICONS}ic_errorAlert.svg`,
  },
  leftCalendar: {
    url: `${pathServer.PATH_ICONS}ic_leftCalendar.svg`,
  },
  rightCalendar: {
    url: `${pathServer.PATH_ICONS}ic_rightCalendar.svg`,
  },
  successAlert_big: {
    url: `${pathServer.PATH_ICONS}ic_successAlert_big.svg`,
  },
  listUserBig: {
    url: `${pathServer.PATH_ICONS}ic_listUser_big.svg`,
  },
  editObservation: {
    url: `${pathServer.PATH_ICONS}ic_editObservation.svg`,
  },
  deleteObservation: {
    url: `${pathServer.PATH_ICONS}ic_deleteObservation.svg`,
  },
  deleteJustification: {
    url: `${pathServer.PATH_ICONS}ic_deleteJustification.svg`,
  },
  tabDetail: {
    url: `${pathServer.PATH_ICONS}ic_tabDetail.svg`,
  },
  tabChronology: {
    url: `${pathServer.PATH_ICONS}ic_tabChronology.svg`,
  },
  tabNotification: {
    url: `${pathServer.PATH_ICONS}ic_tabNotification.svg`,
  },
  tabObservation: {
    url: `${pathServer.PATH_ICONS}ic_tabObservation.svg`,
  },
  send: {
    url: `${pathServer.PATH_ICONS}ic_send.svg`,
  },
  deleteNotification: {
    url: `${pathServer.PATH_ICONS}ic_deleteNotification.svg`,
  },
  deleteRepresentative: {
    url: `${pathServer.PATH_ICONS}ic_deleteRepresentative.svg`,
  },
  closeResponsableModal: {
    url: `${pathServer.PATH_ICONS}ic_closeResponsableModal.svg`,
  },
  addCircle: {
    url: `${pathServer.PATH_ICONS}ic_add.svg`,
  },
  arrowBottom: {
    url: `${pathServer.PATH_ICONS}ic_arrowBottom.svg`,
  },
  addRepresentative: {
    url: `${pathServer.PATH_ICONS}ic_add_alt.svg`,
  },
  warningTriangle: {
    url: `${pathServer.PATH_ICONS}ic_warningTriangle.svg`,
  },
  search: {
    url: `${pathServer.PATH_ICONS}ic_search.svg`,
  },
  cancel: {
    url: `${pathServer.PATH_ICONS}ic_cancel.svg`,
  },
  validate: {
    url: `${pathServer.PATH_ICONS}ic_validate.svg`,
  },
  approval: {
    url: `${pathServer.PATH_ICONS}ic_search_alt.svg`,
  },
  google: {
    url: `${pathServer.PATH_ICONS}ic_google.svg`,
  },
  clip: {
    url: `${pathServer.PATH_ICONS}ic_clip.svg`,
  },
  editRepresentative: {
    url: `${pathServer.PATH_ICONS}ic_edit_rep.svg`,
  },
  deleteRepresentativeDetail: {
    url: `${pathServer.PATH_ICONS}ic_delete_rep.svg`,
  },
};

export const MomentFormat = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_FROM_MMMM_YYYY: 'DD [de] MMMM [del] YYYY',
  DD_MM_YYYY: 'DD[/]MM[/]YYYY',
};

export const fileType = {
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  GDOC: 'application/vnd.google-apps.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
};

export const fileExtension = {
  PDF: 'pdf',
  DOC: 'doc',
  DOCX: 'docx',
  JPEG: 'jpeg',
  JPG: 'jpg',
  XLS: 'xls',
  XLSX: 'xlsx',
};

export const companyProperty = {
  NAME: 'name',
  RUC: 'ruc',
  SOCIAL_REASON: 'socialReason',
  TYPE: 'type',
  ELECTRONIC_CERTIFICATE: 'electronicCertificate',
  ADDRESS: 'address',
  REGISTER_OFFICE: 'registerOffice',
  LEGAL_REPRESENTATIVES: 'legalRepresentatives',
  EMAIL: 'email',
};
export const sectionDetail = {
  ID_CONTRACT_MODEL: 1,
  ID_REQUEST_INFORMATION: 2,
  ID_COUNTERPART_INFORMATION: 3,
  ID_ANNEXES: 4,
};

export const representativeProperty = {
  NAME: 'name',
  MIDDLE_NAME: 'middleName',
  FAMILY_NAME: 'familyName',
  GENDER: 'gender',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  RUC: 'ruc',
  ADDRESS: 'address',
  EMAIL: 'email',
  POWER_VIGENCY: 'powerVigency',
};

export const COMPANY = 'Empresa';
export const REPRESENTATIVE = 'Representante';

export const personType = {
  LEGAL: 'juridica',
  NATURAL: 'natural',
};

export const validationType = {
  REQUIRED: 'required',
  RUC: 'ruc',
  EMAIL: 'email',
  DNI: 'dni',
  RUC_LEGAL: 'rucLegal',
  RUC_NATURAL: 'rucNatural',
};

export const representativeValidation = {
  [representativeProperty.NAME]: [validationType.REQUIRED],
  [representativeProperty.MIDDLE_NAME]: [validationType.REQUIRED],
  [representativeProperty.FAMILY_NAME]: [validationType.REQUIRED],
  [representativeProperty.GENDER]: [validationType.REQUIRED],
  [representativeProperty.DOCUMENT_TYPE]: [validationType.REQUIRED],
  [representativeProperty.DOCUMENT_NUMBER]: [validationType.REQUIRED],
};

export const companyValidation = {
  [companyProperty.NAME]: [validationType.REQUIRED],
  [companyProperty.RUC]: [validationType.REQUIRED, validationType.RUC_LEGAL],
  [companyProperty.SOCIAL_REASON]: [validationType.REQUIRED],
  [companyProperty.TYPE]: [validationType.REQUIRED],
  [companyProperty.ELECTRONIC_CERTIFICATE]: [validationType.REQUIRED],
  [companyProperty.ADDRESS]: [validationType.REQUIRED],
  [companyProperty.REGISTER_OFFICE]: [validationType.REQUIRED],
  [companyProperty.EMAIL]: [validationType.REQUIRED, validationType.EMAIL],
};

export const contractModelType = {
  REPOSITORY: 'REPOSITORY',
  EXTERNAL: 'EXTERNAL',
};
export const ROLE_LEGAL_OWNER = 'Responsable Legal';
export const ROLE_APPLICANT = 'Solicitante';
export const ROLE_ADMINISTRATOR = 'Administrador';

export const contractTypes = [
  { label: 'Contratos de Comercialización', value: '0' },
  { label: 'Contratos de Prestación de Servicios', value: '1' },
  { label: 'Contratos de Compra Venta', value: '2' },
  { label: 'Contratos de Arrendamiento / Usufructo', value: '3' },
  { label: 'Acuerdo de Confidencialidad', value: '4' },
];
export const areaTypes = [
  { label: 'Auditoría Interna', value: '4' },
  { label: 'Riesgos Operativos y LAFT', value: '5' },
  { label: 'Oficial de Cumplimiento', value: '6' },
  { label: 'Analítica y Riesgos Técnicos', value: '7' },
  { label: 'Contabilidad General', value: '8' },
  { label: 'Riesgos de Inversión y Planeamiento Financiero', value: '9' },
  { label: 'Back Office', value: '10' },
  { label: 'Tesorería', value: '11' },
  { label: 'Tecnología de la información', value: '12' },
  { label: 'Operaciones Masivos', value: '13' },
  { label: 'Operaciones Rentas', value: '14' },
  { label: 'Soluciones de Clientes', value: '15' },
  { label: 'Gestión y Control', value: '16' },
  { label: 'Siniestros', value: '17' },
  { label: 'Recaudación y Cobranzas', value: '18' },
  { label: 'Actuarial', value: '19' },
  { label: 'Inversiones Inmobiliarias', value: '20' },
  { label: 'Inversiones Financieras', value: '21' },
  { label: 'Comercial Masivos', value: '22' },
  { label: 'Comercial Rentas', value: '23' },
  { label: 'Marketing y Experiencia de Clientes', value: '24' },
  { label: 'Legal y Compliance', value: '25' },
  { label: 'Administración, Compras y Facilities', value: '26' },
  { label: 'Cultura Corporativa', value: '27' },
  { label: 'Técnica', value: '28' },
];
export const businessTypes = ['S.A.', 'S.A.C.', 'S.R.L.', 'E.I.R.L.', 'S.A.A.'];
export const genderOptions = ['Masculino', 'Fememino'];

export const validationDocTypes = {
  DNI: validationType.DNI,
  RUC: validationType.RUC_NATURAL,
};

export const maxLengthDocTypes = {
  DNI: 8,
  RUC: 11,
};

export const docTypes = ['DNI', 'RUC'];

export const valueInSpanish = {
  Process: 'Proceso',
  Observed: 'Observado',
  New: 'Nuevo',
  Review: 'Revisión',
  Validation: 'Validación',
  Finalized: 'Finalizado',
  Cancelled: 'Cancelado',
};

export const requestTypeInSpanish = {
  Contract: 'Contrato',
};

export const emptyFunction = () => {};

export const notificationTypes = {
  1: 'cd',
  2: 'ed',
  3: 'dd',
};

export const observationOptions = [
  { label: 'Información de Solicitud', value: 2 },
  { label: 'Contraparte', value: 3 },
  { label: 'Anexos', value: 4 },
];

export const configurationPicker = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
  viewId: 'DOCS',
  showUploadView: true,
  showUploadFolders: true,
  supportDrives: true,
  multiselect: false,
  customScopes: [
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/drive.file',
  ],
  setParentFolder: process.env.REACT_APP_FOLDER_ID,
};

export const validateSpace = /^\S*$/;
export const validateLowerCase = /^(?=.*[a-zñ])\S{8,64}$/;
export const validateUpperCase = /^(?=.*[A-ZÑ])\S{8,64}$/;
export const validateNumber = /^(?=.*[0-9])\S{8,64}$/;
export const validateSpecialCharacter = /^(?=.*[.,;:=+-/_/*(){}"'<>!?¿¡/#@$%&])\S{8,64}$/;
export const showErrorToast = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
