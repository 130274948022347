import React from 'react';
import { Row, Col } from 'antd';
import WTextInput from '../TextInput/index';

import './index.less';

const WResponsableDetailsCard = ({ responsable }) => {
  const { firstName, fatherLastName, motherLastName, email } = responsable;

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col flex={263}>
          <WTextInput placeholder="Nombre" value={firstName} disabled />
        </Col>
        <Col flex={263}>
          <WTextInput placeholder="Apellido Paterno" value={fatherLastName} disabled />
        </Col>
        <Col flex={263}>
          <WTextInput placeholder="Apellido Materno" value={motherLastName} disabled />
        </Col>
        <Col flex="382px">
          <WTextInput placeholder="Correo electrónico" value={email} disabled />
        </Col>
      </Row>
    </>
  );
};

export default WResponsableDetailsCard;
