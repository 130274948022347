import React from 'react';
import './index.less';
import { Typography, Row, Col, Modal } from 'antd';
import MyUnit from '../MyUnit/MyUnit';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Title, Text } = Typography;

const ModalMyUnit = ({ files, onClose, visible }) => {
  return (
    <Modal visible={visible} className="modalMyUnit-container" footer={null}>
      <>
        <Row className="modalMyUnit-header">
          <Col span={23}>
            <Text className="modalMyUnit-title">Seleccionar</Text>
            <Title className="modalMyUnit-title" level={3}>
              Modelo de Contrato
            </Title>
          </Col>
          <Col span={1} onClick={onClose}>
            <CustomIcon className="ModalMyUnit-bnClose" type="close" />
          </Col>
        </Row>
        <MyUnit files={files} />
      </>
    </Modal>
  );
};
export default ModalMyUnit;

ModalMyUnit.defaultProps = {
  files: {},
  visible: false,
};
