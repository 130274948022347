import NotificationRepository from '../repositories/NotificationRepository';

export default class NotificationServices {
  static async getNotifications(requestId) {
    const [data, error] = await NotificationRepository.getNotifications(requestId);
    return [data, error];
  }

  static async addNotification(requestId, notificationData) {
    const [data, error] = await NotificationRepository.addNotification(requestId, notificationData);
    return [data, error];
  }

  static async approveNotification(requestId, notificationId) {
    const [data, error] = await NotificationRepository.approveNotification(
      requestId,
      notificationId
    );
    return [data, error];
  }

  static async rejectNotification(requestId, notificationId) {
    const [data, error] = await NotificationRepository.rejectNotification(
      requestId,
      notificationId
    );
    return [data, error];
  }

  static async deleteNotification(requestId, notificationId) {
    const [data, error] = await NotificationRepository.deleteNotification(
      requestId,
      notificationId
    );
    return [data, error];
  }

  static async updateNotification(requestId, notificationId, jsonNotification) {
    const [data, error] = await NotificationRepository.updateNotification(
      requestId,
      notificationId,
      jsonNotification
    );
    return [data, error];
  }

  static async documentReturn(requestId) {
    const [data, error] = await NotificationRepository.documentReturn(requestId);
    return [data, error];
  }
}
