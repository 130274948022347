import React from 'react';
import { Card, Typography, Col, Row } from 'antd';
import './index.less';
import { ReactSVG } from 'react-svg';
import { pathServer } from '../../utilities/Constant';

const { Title } = Typography;
const WCardRequest = ({ user }) => {
  const { title, area, dateContract, datevalidity, infoExtra, infoExtra2 } = user;
  return (
    <div className="box-container">
      <div className="info-container">
        <Title level={4}>{title}</Title>
      </div>
      <Card className="card-container">
        <Row className="object1-container ">
          <WParagraph subtitle="Área Usuaria" user={area} />
          <Row className="object1-container ">
            <Col span={12}>
              <WParagraph icon="ic_calendar.svg" subtitle="Contrato" user={dateContract} />
            </Col>
            <Col style={{ paddingLeft: ' 10px' }} span={12}>
              <WParagraph icon="ic_calendar.svg" subtitle="Vigencia" user={datevalidity} />
            </Col>
          </Row>
          <WParagraph subtitle="Lorem" user={infoExtra} />
          <WParagraph subtitle="Sobre la solicitud" user={infoExtra2} />
        </Row>
      </Card>
    </div>
  );
};

const WParagraph = ({ subtitle, user, icon }) => {
  return (
    <>
      <Row>
        <Col span={24} className="subtitle">
          <Row>
            <ReactSVG
              className="icon-svg"
              src={`${pathServer.PATH_ICONS}${icon}`}
              style={{ display: `${icon ? '' : 'none'}` }}
            />
            {subtitle}
          </Row>
        </Col>
        <Col span={24} className="descripcion">
          {user}
        </Col>
      </Row>
    </>
  );
};

WCardRequest.propTypes = {};

export default WCardRequest;
