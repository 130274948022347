import { Col, Typography, Radio } from 'antd';
import React, { useState } from 'react';
import './index.less';

const { Title } = Typography;
const defaultOptions = [
  { label: 'Contrato', value: 'Contrato' },
  { label: 'Adenda', value: 'Adenda' },
  { label: 'Resolución', value: 'Resolucion' },
];
const WButtontypeReq = ({ state, options, onChangeState, title, showFotter = false }) => {
  const { checked } = state;
  const [value, setValue] = useState(checked);
  const [descripcion, setDescripcion] = useState('un nuevo contrato');

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeState(e.target.value);
    const ORIGIN = {
      Contrato: 'un nuevo contrato',
      Adenda: 'una nueva adenda',
      Resolucion: 'una nueva resolución',
    };
    setDescripcion(ORIGIN[e.target.value]);
  };
  return (
    <Col className="request-container">
      <Col className="title" span={24}>
        <Title level={3}>{title}</Title>
      </Col>
      <Radio.Group
        className="radio"
        options={options}
        optionType="button"
        onChange={onChange}
        value={value}
        buttonStyle="solid"
      />

      {showFotter && (
        <Col className="descripcion-container">
          <span>*Se creara {descripcion}</span>
        </Col>
      )}
    </Col>
  );
};

WButtontypeReq.defaultProps = {
  options: defaultOptions,
  onChangeState: () => {},
};

export default WButtontypeReq;
