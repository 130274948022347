import React from 'react';
import { RequestType } from '../../../components';
import { requestProperty } from '../constants';

const FirstStepRequestTemplate = ({ firstStepData }) => {
  const { type, onSetRequest, errorMessages } = firstStepData;

  const onSelectOption = (item) => {
    onSetRequest(requestProperty.TYPE, item.value);
  };

  return <RequestType type={type} onSelectOption={onSelectOption} errorMessages={errorMessages} />;
};

FirstStepRequestTemplate.propTypes = {};

export default FirstStepRequestTemplate;
