import * as axios from 'axios/index';

export function doGet(url, params = {}, header = {}) {
  const idToken = localStorage.getItem('token') || '';

  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      ...header,
    },
    data: params,
  }).catch((error) => {
    console.error('error', error);
  });
}

export function doPost(url, params = {}) {
  const idToken = localStorage.getItem('token') || '';

  try {
    return axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      data: params,
    });
  } catch (error) {
    console.log('errorDoPost', error);
    throw error;
  }
}

export function doPut(url, params = {}, header = {}) {
  const idToken = localStorage.getItem('token') || '';
  return axios({
    url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      ...header,
    },
    data: params,
  });
}

export function doDelete(url, params = {}) {
  const idToken = localStorage.getItem('token') || '';

  return axios({
    url,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    data: params,
  });
}
