import React from 'react';
import { Typography, Divider, Row, Col } from 'antd';

import { downloadFileUrl } from '../../utilities/Functions';

import FileItem from '../FilteItem/FileItem';
import CustomIcon from '../CustomIcon/CustomIcon';

import './index.less';

const { Title } = Typography;
const defaultFiles = [
  {
    type: 'doc',
    name: 'Lorem ipsum.doc',
    size: '13',
  },
  {
    type: 'pdf',
    name: 'Lorem ipsum.pdf',
    size: '13',
  },
  {
    type: 'jpg',
    name: 'Lorem ipsum.jpg',
    size: '13',
  },
  {
    type: 'xls',
    name: 'Lorem ipsum.xls',
    size: '13',
  },
];
const Annexes = ({ title, files, isReadOnly }) => {
  return (
    <div className="annexes-container">
      <Row className="row-desc-header">
        <Col flex="1 1 auto">
          <Title className="annexes-title">{title}</Title>
        </Col>
        {!isReadOnly && (
          <Col flex="0 0 10px" className="icon-edit">
            <CustomIcon type="editRequest" />
          </Col>
        )}
      </Row>
      {files.map((file, index) => (
        <div key={`file-${file?.id}`}>
          {file?.href ? (
            <FileItem file={file} download={() => window.open(file?.href, '_blank')} />
          ) : (
            <FileItem file={file} download={downloadFileUrl} />
          )}
          {index !== files.length - 1 && <Divider className="annexes-divider" />}
        </div>
      ))}
    </div>
  );
};

Annexes.defaultProps = {
  title: 'Anexos',
  files: defaultFiles,
};
export default Annexes;
