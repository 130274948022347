/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import './index.less';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const WCustomTab = ({ leftContent, rightContent, tabs, onChange = () => {} }) => {
  const OperationsSlot = {
    left: leftContent,
    right: rightContent,
  };

  return (
    <Tabs tabBarExtraContent={OperationsSlot} defaultActiveKey="1" onChange={onChange}>
      {tabs.map(item => (
        <TabPane tab={item.title} key={item.id}>
          {item.children}
        </TabPane>
      ))}
    </Tabs>
  );
};

WCustomTab.propTypes = {};

export default WCustomTab;