import React from 'react';
import { Typography } from 'antd';
import './index.less';

const { Title, Text } = Typography;
const defaultOptions = [
  { label: 'Contrato', value: '1', disabled: false },
  { label: 'Adenda', value: '2', disabled: true },
  { label: 'Resolución', value: '3', disabled: true },
];
const RequestType = ({ options, type, onSelectOption, errorMessages }) => {
  return (
    <div className="requestType-container">
      <div className="requestType-headContainer">
        <Title className="requestType-title">Tipo de solicitud</Title>
        <Text>Seleccione el tipo de solicitud que desea</Text>
      </div>
      <div className="requestType-container-options">
        {options.map((item) => (
          <div
            key={item.value}
            className={
              item.value === type
                ? 'requestType-optionContainer requestType-optionContainer-selected'
                : 'requestType-optionContainer'
            }
            onClick={() => !item.disabled && onSelectOption(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
      {!type && errorMessages && (
        <div className="container-error">
          <span>Selecciona el tipo de solicitud </span>
        </div>
      )}
    </div>
  );
};
RequestType.defaultProps = {
  options: defaultOptions,
};
export default RequestType;
