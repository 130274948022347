import React from 'react';
import { Typography, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../configuration/routes/Routes';
import './index.less';
import { Calendar } from '../../../components';
import { pathServer } from '../../../utilities/Constant';

const { Title, Text } = Typography;

export default function HomeAdminTemplate({ name }) {
  const history = useHistory();

  const goRequestList = () => {
    history.push(Routes.REQUEST.LIST);
  };

  return (
    <div className="homeAdmin-container">
      <Title className="home-title">{`¡Bienvenido de nuevo,${name}!`}</Title>
      <div className="header-container">
        <Text className="home-subtitle">Hagamos lo mejor hoy</Text>
        <img src={`${pathServer.PATH_IMG}ic_welcome.png`} />
      </div>
      <hr />
      <div className="main-container">
        <div className="container-subtitle">
          <Text className="subtitle">Resumen de solicitudes</Text>
          <Text className="link" onClick={goRequestList}>
            Ver todos
          </Text>
        </div>
        <div className="v-line" />
        <div style={{ display: 'flex' }}>
          <Divider type="vertical" />
          <div className="home-calendar">
            <Calendar type={1} />
            <Divider className="divider-calendar" />
          </div>
        </div>
      </div>
    </div>
  );
}
