import React, { useState } from 'react';
import { Row, Col, DatePicker, Checkbox, Divider } from 'antd';
import { ReactSVG } from 'react-svg';
import WBigButton from '../BigButton/index';
import WTextButton from '../TextButton/index';
import WSearchInput from '../SearchInput/index';
import Calendar from '../../../resources/svg/Icon-Calendar.svg';
import './index.less';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const initialState = {
  formQuery: '',
  formStates: [],
  formDates: null,
};

const WFilters = ({ stateList, onSubmitFilters }) => {
  const [form, setForm] = useState(initialState);
  const { formQuery, formStates, formDates } = form;

  const onChangeQuery = (e) => setForm({ ...form, formQuery: e.target.value });
  const onChangeCheckBoxList = (e) => setForm({ ...form, formStates: e });
  const onChangeDates = (dates) => setForm({ ...form, formDates: dates });

  const onClearFilters = () => {
    setForm(initialState);
    onSubmitFilters(initialState);
  };

  const onSubmit = () => {
    onSubmitFilters(form);
  };

  return (
    <Col className="filters">
      <h2 className="filters-title">Filtros</h2>
      <div className="filters-search-container">
        <WSearchInput placeholder="Buscar solicitud" onChange={onChangeQuery} value={formQuery} />
      </div>
      <WStateCheckBoxList
        stateList={stateList}
        formStates={formStates}
        onChange={onChangeCheckBoxList}
      />
      <Divider className="filters-divider" />
      <WRangeDatePicker dates={formDates} onChangeDates={onChangeDates} />
      <Row align="middle" justify="space-between">
        <WTextButton label="Limpiar" onClick={onClearFilters} />
        <WBigButton label="Buscar" onClick={onSubmit} />
      </Row>
    </Col>
  );
};

const WStateCheckBoxList = ({ stateList, formStates, onChange }) => {
  return (
    <Col justify="center">
      <h3 className="filters-subtitle">Estado de la solicitud</h3>
      <CheckboxGroup options={stateList} value={formStates} onChange={onChange} />
    </Col>
  );
};

const WRangeDatePicker = ({ dates, onChangeDates }) => {
  return (
    <Col justify="center">
      <h3 className="filters-subtitle">Fecha</h3>
      <RangePicker
        placeholder={['Desde', 'Hasta']}
        showToday
        suffixIcon={<ReactSVG src={Calendar} />}
        className="range-date-picker"
        separator={<ReactSVG src={Calendar} />}
        value={dates}
        onChange={onChangeDates}
      />
    </Col>
  );
};

export default WFilters;
