import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography, Radio, Input, Spin, Form } from 'antd';
import useDrivePicker from 'react-google-drive-picker';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { configurationPicker, contractModelType } from '../../utilities/Constant';
import { requestProperty } from '../../templates/NewRequest/constants';
import { downloadFileUrl, onSelectJustifyFile, openFile } from '../../utilities/Functions';

import CustomIcon from '../CustomIcon/CustomIcon';
import WUploadFile from '../UploadFile';
import FileItemFolder from '../FileItemFolder/FileItemFolder';

import './index.less';

const { Title, Text } = Typography;
const { TextArea } = Input;

const UploadModel = ({
  title,
  isReadOnly,
  file,
  handleUpload,
  origin,
  justify,
  onSetRequest,
  contractModel,
  errorMessages,
}) => {
  const isExternal = origin === contractModelType.EXTERNAL;
  const { title: titleJustify, description: descriptionJustify, files: filesJustify } = justify;
  const [openPicker, data] = useDrivePicker();
  const [loadingJustify, setLoadingJustify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const errorUpload = errorMessages && !file?.filename;
  const borderError =
    errorMessages && ((isExternal && isEmpty(descriptionJustify)) || !file?.filename);
  const savedData = {
    justificationDescription: descriptionJustify,
  };
  useEffect(() => {
    if (errorMessages) {
      form.submit();
    } else {
      form.resetFields();
    }
  }, [errorMessages, isExternal]);

  const onUploadFile = async (params) => {
    setLoading(true);
    await handleUpload(params);
    setLoading(false);
  };

  const changeTypeModel = (e) => {
    const contractModelToUpdate = { ...contractModel };
    contractModelToUpdate.origin = e.target.value;
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  const handleOpenPicker = () => {
    const googleToken = localStorage.getItem('pickerToken');
    openPicker({
      ...configurationPicker,
      ...(googleToken ? { token: googleToken } : {}),
      callbackFunction: (res) => {
        if (res.action === 'picked') {
          handleUpload(res.docs[0]);
        }
      },
    });
  };

  const onSelectJFile = async (option) => {
    try {
      setLoadingJustify(true);
      const { onSuccess, file: fileJustify } = option;
      await onSelectJustifyFile(fileJustify, contractModel, onSetRequest);
      onSuccess();
    } finally {
      setLoadingJustify(false);
    }
  };

  const onDeleteDocJustify = (idDoc) => {
    const contractModelToUpdate = { ...contractModel };
    const documentsAll = contractModelToUpdate.justify.documents;
    contractModelToUpdate.justify.documents = documentsAll.filter((doc) => doc.id !== idDoc);
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  const onDownloadFileJustify = (id) => {
    downloadFileUrl({ id });
  };

  const onChangeTextArea = (value) => {
    const contractModelToUpdate = { ...contractModel };
    contractModelToUpdate.justify.description = value;
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem('pickerToken', data?.access_token);
    }
  }, [data]);

  return (
    <Form name="detail-s4" autoComplete="off" form={form} initialValues={savedData}>
      <div className={`contract-modal-container  ${borderError && 'border-error'}`}>
        <div className="section-model">
          <Row>
            <Col flex="1 1 auto">
              <Title className="title-section" level={5}>
                {title}
              </Title>
            </Col>
            {!isReadOnly && (
              <Col flex="0 0 10px" className="icon-edit">
                <CustomIcon type="editRequest" />
              </Col>
            )}
          </Row>
          <Row className="row-type-model">
            <Radio.Group
              onChange={changeTypeModel}
              value={origin}
              className={`${isReadOnly && 'event-none'}`}
            >
              <Radio value={contractModelType.REPOSITORY}>Modelo de repositorio</Radio>
              <Radio value={contractModelType.EXTERNAL}>Modelo Externo</Radio>
            </Radio.Group>
          </Row>
          <Row className="row-file-model">
            {!isReadOnly ? (
              <>
                {isExternal ? (
                  <div className="upload-file">
                    <WUploadFile
                      title="Subir archivo"
                      handleUpload={onUploadFile}
                      icon={<CustomIcon type="uploadFile" />}
                      errorMessage={errorUpload && 'Debe seleccionar un archivo'}
                      accept=".doc,.docx"
                    />
                  </div>
                ) : (
                  <div className="upload-repository">
                    <Button onClick={handleOpenPicker}>
                      <CustomIcon type="uploadFile" />
                      Subir archivo
                    </Button>
                    {errorUpload && (
                      <label className="error-file">Debe seleccionar un archivo</label>
                    )}
                  </div>
                )}
                {loading ? (
                  <div>
                    <Spin size="small" />
                    <Text> Subiendo archivo</Text>
                  </div>
                ) : (
                  file?.filename && (
                    <div className="contract-model">
                      <Row onClick={() => openFile(file)}>
                        <Text className="fileItem-text">{file?.filename}</Text>
                        <Col flex="0 0 26px">
                          <CustomIcon type="downloadFile" />
                        </Col>
                      </Row>
                    </div>
                  )
                )}
              </>
            ) : (
              file?.filename && (
                <div className="contract-model">
                  <Row onClick={() => openFile(file)}>
                    <Text className="fileItem-text">{file?.filename}</Text>
                    <Col flex="0 0 26px">
                      <CustomIcon type="downloadFile" />
                    </Col>
                  </Row>
                </div>
              )
            )}
          </Row>
        </div>
        {isExternal && (
          <div className="section-justify">
            <Row>
              <Col flex="1 1 auto">
                <Title className="title-section" level={5}>
                  {titleJustify}
                </Title>
              </Col>

              {!isReadOnly && (
                <div className="upload-file">
                  <WUploadFile title="+ Subir archivos" handleUpload={onSelectJFile} icon={null} />
                </div>
              )}

              <div className="justify-files">
                {filesJustify?.documents.length === 0 && !loadingJustify && (
                  <Text>No hay ningún archivo de justificación</Text>
                )}
                {filesJustify?.documents?.map((fileItem) => (
                  <FileItemFolder
                    key={`document-${fileItem.id}`}
                    file={fileItem}
                    onDownload={onDownloadFileJustify}
                    onDelete={onDeleteDocJustify}
                    showIcons={{ download: true, remove: !isReadOnly && fileItem.saved !== true }}
                  />
                ))}

                {loadingJustify && (
                  <div>
                    <Spin size="small" />
                    <Text> Subiendo archivo</Text>
                  </div>
                )}
              </div>
              <div className="justify-description">
                <Form.Item
                  name="justificationDescription"
                  rules={[{ required: true, message: 'Ingrese la justificación' }]}
                >
                  <TextArea
                    className={`${isReadOnly && 'event-none'}`}
                    placeholder="Ingresar justificación "
                    value={descriptionJustify}
                    onChange={({ target: { value } }) => {
                      onChangeTextArea(value);
                    }}
                  />
                </Form.Item>
              </div>
            </Row>
          </div>
        )}
      </div>
    </Form>
  );
};
UploadModel.displayName = 'UploadModel';

UploadModel.defaultProps = {
  onSetRequest: () => {},
  isReadOnly: true,
};

UploadModel.propTypes = {
  onSetRequest: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default UploadModel;
