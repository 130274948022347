/* eslint-disable react/no-array-index-key */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const WCalendar = ({ type, startDate, finishDate }) => {
  const date1 = startDate !== '' && new Date(startDate);
  const date2 = startDate !== '' && new Date(finishDate);

  const currentDate = type === 1 ? new Date() : new Date(date1);
  const local = 'es';
  const intl = new Intl.DateTimeFormat(local, { month: 'long' });

  const weekdays = [...Array(7).keys()];
  const intlWeekDay = new Intl.DateTimeFormat(local, { weekday: 'long' });

  const currentDay = currentDate.getDate();
  const [monthNumber, setMonthNumber] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  const daysOfMonth = new Date(currentYear, monthNumber + 1, 0).getDate();
  const startDay = new Date(currentYear, monthNumber, 1);
  const startsOn = startDay.getDay() - 1 === -1 ? 7 : startDay.getDay();

  const days = [...Array(daysOfMonth).keys()];

  const nextMonth = () => {
    if (monthNumber !== 11) {
      setMonthNumber(monthNumber + 1);
    } else {
      setMonthNumber(0);
      setCurrentYear(currentYear + 1);
    }
  };

  const lastMonth = () => {
    if (monthNumber !== 0) {
      setMonthNumber(monthNumber - 1);
    } else {
      setMonthNumber(11);
      setCurrentYear(currentYear - 1);
    }
  };

  const dayWeek = (day) => {
    const start = new Date(currentYear, monthNumber, day);
    return start.getDay() - 1 === -1 ? 7 : start.getDay();
  };

  const weekYear = (day) => {
    const dayMilliseconds = 1000 * 60 * 60 * 24;
    const weekDays = 7;
    const thursday = 4;

    const date = new Date(currentYear, monthNumber, day);
    let dayOfWeek = date.getDay();
    if (dayOfWeek === 0) dayOfWeek = 7;

    date.setUTCDate(date.getUTCDate() - dayOfWeek + thursday);
    const initialYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    const differenceOfDatesInMilliseconds = date - initialYear;
    return Math.ceil((differenceOfDatesInMilliseconds / dayMilliseconds + 1) / weekDays);
  };

  const weekYearDay = weekYear(currentDay);
  const dayOfMonth = (day) => new Date(currentYear, monthNumber, day);

  return (
    <>
      <div className="calendar">
        <div className="calendar-header">
          <div className="calendar-info">
            <div className="month">{intl.format(new Date(currentYear, monthNumber))}</div>
            <div className="year">{currentYear}</div>
          </div>
          <div className="calendar-arrow">
            {type === 2 && (
              <>
                <CustomIcon className="arrow-left" type="leftCalendar" onClick={lastMonth} />
                <CustomIcon type="rightCalendar" onClick={nextMonth} />
              </>
            )}
          </div>
        </div>
        <div className="calendar-week">
          {weekdays.map((weekDayIndex) => {
            return (
              <div key={`weekday-${weekDayIndex}`} className="dayWeek">
                {intlWeekDay.format(new Date(2022, 10, weekDayIndex)).toString().substring(0, 2)}
              </div>
            );
          })}
        </div>
        <div className="calendar-dates">
          {days.map((day, index) => {
            const isStartDate = dayOfMonth(day + 1).getTime() === date1.getTime();
            const isEndDate = dayOfMonth(day + 1).getTime() === date2.getTime();

            return type === 2 ? (
              isStartDate || isEndDate ? (
                <div
                  key={`day-${index}`}
                  className={isStartDate ? 'container-startDate' : 'container-endDate'}
                >
                  <div
                    className="day startDate"
                    style={{ gridColumnStart: index === 0 ? startsOn : 'initial' }}
                  >
                    {day + 1}
                  </div>
                </div>
              ) : dayOfMonth(day + 1).getTime() > date1.getTime() &&
                dayOfMonth(day + 1).getTime() < date2.getTime() ? (
                <div
                  key={`day-${index}`}
                  className="day rangeDate"
                  style={{ gridColumnStart: index === 0 ? startsOn : 'initial' }}
                >
                  {day + 1}
                </div>
              ) : (
                <div
                  key={`day-${index}`}
                  className="day"
                  style={{ gridColumnStart: index === 0 ? startsOn : 'initial' }}
                >
                  {day + 1}
                </div>
              )
            ) : (
              weekYear(day + 1) === weekYearDay && (
                <div
                  key={`day-${index}`}
                  className={`day ${day + 1 === currentDay && 'today'}`}
                  style={{
                    gridColumnStart: day + 1 === currentDay ? dayWeek(day + 1) : 'initial',
                  }}
                >
                  {day + 1}
                </div>
              )
            );
          })}
        </div>
      </div>
    </>
  );
};

WCalendar.propTypes = {
  type: PropTypes.number, //Expect type: 1 or 2
  startDate: PropTypes.string, //Expect that format: MM-DD-YYYY
  finishDate: PropTypes.string, //Expect that format: MM-DD-YYYY
};

export default WCalendar;
