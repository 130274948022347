import React, { useState, forwardRef, useEffect } from 'react';
import { Col, Row, Typography, Form } from 'antd';
import { findIndex, forEach } from 'lodash';

import { COMPANY, personType, REPRESENTATIVE, companyProperty } from '../../utilities/Constant';
import CustomIcon from '../../components/CustomIcon/CustomIcon';
import WHeaderCompany from '../../components/HeaderCompany';
import { requestProperty } from '../NewRequest/constants';
import { setInitialValue } from '../NewRequest/FourthStepRequest/constant';
import { maxRepresentative } from '../../utilities/Functions';

import CompanyCardEdit from '../../components/CompanyCardEdit/CompanyCardEdit';
import RepresentativeCardEdit from '../../components/RepresentativeCardEdit/RepresentativeCardEdit';

import './index.less';

const { Title } = Typography;

const CompanyCardTemplate = forwardRef(
  ({ counterPart, data, isReadOnly, errorMessages, onSetRequest, form }) => {
    const companies = data;
    const [selectedCompany, setSelectedCompany] = useState(`${COMPANY} 1`);
    const [selectedIndexCompany, setSelectedIndexCompany] = useState(0);
    const [optionsCompanies, setOptionsCompanies] = useState([]);
    const indexLegal = counterPart.findIndex((e) => e.type === personType.LEGAL);
    const currentCompany = companies[selectedIndexCompany];

    useEffect(() => {
      const legalPerson = counterPart[indexLegal];
      const companiesAll = legalPerson.companies.map((x) => x?.company);
      setOptionsCompanies(companiesAll);
    }, [counterPart]);

    useEffect(() => {
      if (errorMessages) {
        form.validateFields();
      }
    }, [selectedIndexCompany]);

    const addCompany = () => {
      const counterPartToUpdate = [...counterPart];
      const legalPerson = counterPartToUpdate[indexLegal];
      const quantity = legalPerson.lastCompany + 1;
      const company = `${COMPANY} ${quantity}`;
      counterPartToUpdate[indexLegal].companies.push({
        company,
        [companyProperty.LEGAL_REPRESENTATIVES]: [
          {
            representative: `${REPRESENTATIVE} 1`,
          },
        ],
        lastRepresentative: 1,
      });
      counterPartToUpdate[indexLegal].lastCompany = quantity;
      onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
    };

    const onDeleteCompany = () => {
      const counterPartToUpdate = [...counterPart];
      let companiesFiltered;
      forEach(counterPartToUpdate, (cp) => {
        if (cp.type === personType.LEGAL) {
          companiesFiltered = cp.companies.filter((co) => {
            return co.company !== selectedCompany;
          });
          // eslint-disable-next-line no-param-reassign
          cp.companies = companiesFiltered;
          if (companiesFiltered?.length === 1) {
            // eslint-disable-next-line no-param-reassign
            cp.lastCompany = 1;
          } else {
            // eslint-disable-next-line no-param-reassign
            cp.lastCompany = maxRepresentative(cp.companies, 'company');
          }
        }
      });

      const companyInitial = counterPartToUpdate[indexLegal].companies[0];
      onSelectCompany(companyInitial.company);
      setSelectedIndexCompany(0);
      onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
    };

    const onSelectCompany = (company) => {
      const indexFound = findIndex(companies, (co) => co.company === company);
      if (indexFound !== -1) {
        setSelectedCompany(company);
        setSelectedIndexCompany(indexFound);
      }
    };
    const onSetCompanies = (company, index) => {
      const counterPartToUpdate = [...counterPart];
      counterPartToUpdate[indexLegal].companies[index] = company;
      onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
    };
    return (
      companies.length > 0 && (
        <div
          className={`detailRequest-representativeCard-container ${
            errorMessages && 'border-error'
          }`}
        >
          <Row className="row-desc-header">
            <Col>
              <Title className="detailRequest-containerContent-title">
                Información de Contraparte - Persona Jurídica
              </Title>
            </Col>
            {!isReadOnly && (
              <Col span={1} className="icon-edit">
                <CustomIcon type="editRequest" />
              </Col>
            )}
          </Row>

          <Row className="row-desc-company">
            <Col>
              <WHeaderCompany
                options={optionsCompanies}
                selectedOption={selectedCompany}
                onSelect={onSelectCompany}
                onClick={addCompany}
                showAdd={!isReadOnly}
              />
            </Col>
            {!isReadOnly && optionsCompanies.length > 1 && (
              <Col className="btn-delete-company">
                <CustomIcon
                  type="deleteRepresentativeDetail"
                  className="cursor-pointer"
                  onClick={onDeleteCompany}
                />
              </Col>
            )}
          </Row>

          <CompanyCardEdit
            key={`company-card-${selectedIndexCompany}`}
            company={currentCompany}
            title={currentCompany.company}
            companyIndex={selectedIndexCompany}
            onSetCompanies={onSetCompanies}
            isReadOnly={isReadOnly}
            className="company-container"
            counterPart={counterPart}
            onSetRequest={onSetRequest}
            errorMessages={errorMessages}
            form={form}
          />
        </div>
      )
    );
  }
);

const RepresentativeCardTemplate = forwardRef(
  ({ counterPart, onSetRequest, data, isReadOnly, errorMessages, form }) => {
    const legalRepresentatives = data;
    const [editItemRepresentative, setEditRepresentative] = useState(null);
    const indexNatural = counterPart.findIndex((e) => e.type === personType.NATURAL);

    useEffect(() => {
      if (errorMessages) {
        form.validateFields();
      }
    }, [editItemRepresentative]);

    const onSetRepresentative = (representative, representativeIndex) => {
      const counterPartToUpdate = [...counterPart];
      counterPartToUpdate[indexNatural].legalRepresentatives[representativeIndex] = representative;
      onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
    };

    const editRepresentantive = (representative) => {
      const currentRepresentative = representative.representative;
      if (editItemRepresentative === currentRepresentative) setEditRepresentative(null);
      else setEditRepresentative(currentRepresentative);
    };

    const onDeleteRepresentative = (representative) => {
      let representativesList;
      const counterPartToUpdate = [...counterPart];
      // natural person
      representativesList =
        counterPartToUpdate[indexNatural][companyProperty.LEGAL_REPRESENTATIVES];
      representativesList = representativesList.filter(
        (re) => re.representative !== representative.representative
      );
      counterPartToUpdate[indexNatural][companyProperty.LEGAL_REPRESENTATIVES] =
        representativesList;
      const lengthRepresentatives = representativesList.length;
      if (lengthRepresentatives === 1) {
        counterPartToUpdate[indexNatural].lastRepresentative = 1;
      } else {
        counterPartToUpdate[indexNatural].lastRepresentative =
          maxRepresentative(representativesList);
      }
      onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
    };

    const addRepresentative = () => {
      const counterPartToUpdate = [...counterPart];
      const quantity = counterPartToUpdate[indexNatural].lastRepresentative + 1;
      const representative = `${REPRESENTATIVE} ${quantity}`;
      counterPartToUpdate[indexNatural][companyProperty.LEGAL_REPRESENTATIVES].push({
        representative,
      });
      counterPartToUpdate[indexNatural].lastRepresentative = quantity;
      onSetRequest(requestProperty.COUNTER_PART, counterPartToUpdate);
      setEditRepresentative(representative);
    };

    useEffect(() => {
      if (legalRepresentatives.length === 1) {
        setEditRepresentative(legalRepresentatives[0].representative);
      }
    }, [data]);

    return (
      legalRepresentatives.length > 0 && (
        <div
          className={`detailRequest-representativeCard-container ${
            errorMessages && 'border-error'
          }`}
        >
          <Row className="row-desc-header">
            <Col>
              <Title className="detailRequest-containerContent-title">
                Información de Contraparte - Persona Natural
              </Title>
            </Col>
            {!isReadOnly && (
              <Col span={1} className="icon-edit">
                <CustomIcon type="editRequest" />
              </Col>
            )}
          </Row>
          {legalRepresentatives.map((rep, indexRep) => (
            <RepresentativeCardEdit
              key={`${personType.NATURAL}${rep.representative}`}
              className="detailRequest-containerContent-with-border"
              title={rep.representative}
              representative={rep}
              selectedIndexRepresentative={indexRep}
              onSetRepresentative={onSetRepresentative}
              isReadOnly={isReadOnly}
              showDetail={editItemRepresentative === rep.representative}
              editRepresentantive={editRepresentantive}
              onDeleteRepresentative={onDeleteRepresentative}
              person={personType.NATURAL}
              showDelete={legalRepresentatives.length > 1}
              errorMessages={errorMessages}
            />
          ))}
          {!isReadOnly && (
            <button type="button" className="buttonAddRepresentative" onClick={addRepresentative}>
              Agregar representante
              <CustomIcon type="addCircle" />
            </button>
          )}
        </div>
      )
    );
  }
);

const CounterpartEditTemplate = ({
  counterPart = {},
  companyRef,
  representativeRef,
  isReadOnly,
  onSetRequest,
  errorMessages,
}) => {
  const counterPartLegal = counterPart.find((x) => x.type === personType.LEGAL);
  const counterPartNatural = counterPart.find((x) => x.type === personType.NATURAL);
  const { legalRepresentatives = [] } = counterPartNatural ?? {};
  const { companies = [] } = counterPartLegal ?? {};
  const [form] = Form.useForm();
  const savedData = setInitialValue(counterPartLegal, counterPartNatural);

  useEffect(() => {
    if (errorMessages) {
      form.submit();
    } else {
      form.resetFields();
    }
  }, [errorMessages]);

  return (
    <>
      <Form name="detail-s2" autoComplete="off" form={form} initialValues={savedData}>
        {counterPartLegal && (
          <CompanyCardTemplate
            counterPart={counterPart}
            data={companies}
            ref={companyRef}
            isReadOnly={isReadOnly}
            onSetRequest={onSetRequest}
            errorMessages={errorMessages}
            form={form}
          />
        )}
        {counterPartNatural && (
          <RepresentativeCardTemplate
            data={legalRepresentatives}
            counterPart={counterPart}
            ref={representativeRef}
            isReadOnly={isReadOnly}
            onSetRequest={onSetRequest}
            errorMessages={errorMessages}
            form={form}
          />
        )}
      </Form>
    </>
  );
};

export default CounterpartEditTemplate;
