import React from 'react';
import { Radio, Row } from 'antd';
import './index.less';

const WCircleSelect = ({ label, options, value, onChange }) => {
  return (
    <Row align="middle" justify="center" className="circle-select" wrap={false}>
      <span className="circle-select-label">{label}</span>
      <Radio.Group
        options={options}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
    </Row>
  );
};

export default WCircleSelect;
