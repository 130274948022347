import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Col, Row, Typography } from 'antd';
import './index.less';
import { personType, REPRESENTATIVE } from '../../utilities/Constant';
// eslint-disable-next-line camelcase,import/order
import { unstable_batchedUpdates } from 'react-dom';
import { getRepresentatives } from '../../utilities/Functions';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import { RepresentativeCard } from '../../components';
import CustomIcon from '../../components/CustomIcon/CustomIcon';

const { Title } = Typography;

const CompanyCardTemplate = forwardRef(({ data, isReadOnly }, ref) => {
  const [companies, setCompanies] = useState(data);

  useImperativeHandle(ref, () => {
    return {
      companies,
    };
  });

  const onSetCompanies = (company, index) => {
    const companiesToUpdate = [...companies];
    companiesToUpdate[index] = company;
    setCompanies(companiesToUpdate);
  };

  return (
    companies.length > 0 && (
      <div className="detailRequest-representativeCard-container">
        <Row className="row-desc-header">
          <Col>
            <Title className="detailRequest-containerContent-title">
              Información de Contraparte - Persona Jurídica
            </Title>
          </Col>
          {!isReadOnly && (
            <Col span={1} className="icon-edit">
              <CustomIcon type="editRequest" />
            </Col>
          )}
        </Row>
        {companies.map((company, companyIndex) => {
          return (
            <CompanyCard
              // eslint-disable-next-line react/no-array-index-key
              key={`company-card-${companyIndex}`}
              company={company}
              title={`Información de empresa ${companyIndex + 1}`}
              companyIndex={companyIndex}
              onSetCompanies={onSetCompanies}
              isReadOnly={isReadOnly}
            />
          );
        })}
      </div>
    )
  );
});

const RepresentativeCardTemplate = forwardRef(({ data, isReadOnly }, ref) => {
  const [legalRepresentatives, setLegalRepresentatives] = useState(data);
  const [selectedRepresentative, setSelectedRepresentative] = useState(`${REPRESENTATIVE} 1`);
  const [selectedIndexRepresentative, setSelectedIndexRepresentative] = useState(0);

  useImperativeHandle(ref, () => {
    return {
      legalRepresentatives,
    };
  });

  const onSelectRepresentative = (value, index) => {
    unstable_batchedUpdates(() => {
      setSelectedRepresentative(value);
      setSelectedIndexRepresentative(index);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onSetRepresentative = (representative, index) => {
    const legalRepresentativesToUpdate = [...legalRepresentatives];
    legalRepresentativesToUpdate[selectedIndexRepresentative] = representative;
    setLegalRepresentatives(legalRepresentativesToUpdate);
  };

  const currentLegalRepresentative = legalRepresentatives[selectedIndexRepresentative];
  const representatives = getRepresentatives(legalRepresentatives);

  return (
    legalRepresentatives.length > 0 && (
      <div className="detailRequest-representativeCard-container">
        <Row className="row-desc-header">
          <Col>
            <Title className="detailRequest-containerContent-title">
              Información de Contraparte - Persona Natural
            </Title>
          </Col>
          {!isReadOnly && (
            <Col span={1} className="icon-edit">
              <CustomIcon type="editRequest" />
            </Col>
          )}
        </Row>
        <RepresentativeCard
          className="detailRequest-containerContent-mb20"
          title={`Información Representante ${selectedIndexRepresentative + 1}`}
          representative={currentLegalRepresentative}
          selectedIndexRepresentative={selectedIndexRepresentative}
          representatives={representatives}
          selectedRepresentative={selectedRepresentative}
          onSelectRepresentative={onSelectRepresentative}
          person={personType.NATURAL}
          onSetRepresentative={onSetRepresentative}
          isReadOnly={isReadOnly}
        />
      </div>
    )
  );
});

const CounterpartTemplate = ({
  companies,
  legalRepresentatives,
  companyRef,
  representativeRef,
  isReadOnly,
}) => {
  return (
    <>
      <CompanyCardTemplate data={companies} ref={companyRef} isReadOnly={isReadOnly} />
      <RepresentativeCardTemplate
        data={legalRepresentatives}
        ref={representativeRef}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

export default CounterpartTemplate;
