import React, { useState, useEffect } from 'react';
import NewRequestTemplate from '../../templates/NewRequest/NewRequestTemplate';
// eslint-disable-next-line import/named
import {
  COMPANY,
  contractModelType,
  personType,
  REPRESENTATIVE,
  ROL_CODE,
} from '../../utilities/Constant';
import UserServices from '../../../domain/usecases/UserServices';

function getInitialRequest() {
  return {
    type: '',
    attached: [
      {
        id: 1,
        description: 'Ficha RUC',
        files: [],
        required: false,
      },
      {
        id: 2,
        description: 'Documento de identidad de los representantes que suscriben el contrato',
        files: [],
        required: false,
      },
      {
        id: 3,
        description: 'Títulos pendientes de inscripción en la partida de la PJ',
        files: [],
        required: false,
      },
      {
        id: 4,
        description: 'Evaluación LAFT de conformidad a la Contraparte',
        files: [],
        required: false,
      },
      { id: 5, description: 'Condiciones comerciales', files: [], required: false },
      { id: 6, description: 'Anexos', files: [], required: false },
    ],
    counterPart: [
      {
        type: personType.LEGAL,
        companies: [
          {
            company: `${COMPANY} 1`,
            legalRepresentatives: [
              {
                representative: `${REPRESENTATIVE} 1`,
              },
            ],
            lastRepresentative: 1,
          },
        ],
        lastCompany: 1,
      },
      {
        type: personType.NATURAL,
        legalRepresentatives: [
          {
            representative: `${REPRESENTATIVE} 1`,
          },
        ],
        lastRepresentative: 1,
      },
    ],
    currentCounterPart: personType.LEGAL,
    contractModel: {
      origin: contractModelType.REPOSITORY,
      justify: {
        description: null,
        documents: [],
      },
      contractDrive: {},
    },
  };
}

export default function Requests() {
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [legalOwners, setLegalOwners] = useState([]);
  const [request, setRequest] = useState(getInitialRequest());

  const onSetRequest = (property, value) => {
    setRequest((prev) => ({ ...prev, [property]: value }));
  };

  useEffect(() => {
    const loadLegalOwners = async () => {
      const [data, error] = await UserServices.getUsersByRolId(ROL_CODE.legalOwner);
      if (data && error === null) {
        setLegalOwners([...data]);
      }
    };
    loadLegalOwners().then();
  }, []);

  return (
    <NewRequestTemplate
      currentStepIndex={currentStepIndex}
      setCurrentStepIndex={setCurrentStepIndex}
      secondStepData={legalOwners}
      onSetRequest={onSetRequest}
      request={request}
    />
  );
}
