import React from 'react';
import { Typography } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';
import './index.less';

const { Text } = Typography;

const defaultFile = {
  name: 'Lorem ipsum',
  size: '16',
  files: [
    {
      name: 'Lorem ipsum',
      size: '16',
    },
  ],
};

const QuickAccessFile = ({ file }) => {
  return (
    <div className="quickAccessFile-container">
      <CustomIcon className="quickAccessFile-icon" type="folderOrange" />
      <Text>{file.name}</Text>
      <div className="quickAccessFile-footer">
        <div className="quickAccessFile-length">
          <Text className="quickAccessFile-length-text">
            {file?.files ? file?.files?.length : '0'}
          </Text>

          <CustomIcon type="showDoc" />
        </div>
        <Text className="quickAccessFile-size">{file.size} GB</Text>
      </div>
    </div>
  );
};

QuickAccessFile.defaultProps = {
  file: defaultFile,
};
export default QuickAccessFile;
