import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { isEmpty } from 'lodash';

import { validationType } from '../../utilities/Constant';
import {
  checkDNI,
  checkRUC,
  checkRUCLegal,
  checkRUCNatural,
  checkValidEmail,
} from '../../utilities/Validation';

import './index.less';

const validationMap = {
  [validationType.DNI]: checkDNI,
  [validationType.RUC]: checkRUC,
  [validationType.RUC_LEGAL]: checkRUCLegal,
  [validationType.RUC_NATURAL]: checkRUCNatural,
  [validationType.EMAIL]: checkValidEmail,
};

const PTextInput = ({
  placeholder,
  value,
  onChange,
  type = 'text',
  maxLength = null,
  readOnly = false,
  disabled = false,
  validation = '',
  errorMessage = '',
  lowerCase = false,
}) => {
  const [error, setError] = useState('');

  const handleChange = (event) => {
    event.stopPropagation();
    let result = event?.target?.value;

    if (lowerCase) result = result?.toLowerCase();

    if (type === 'number') {
      result = result?.replace(/\D/g, '');
    }

    if (!isEmpty(error)) {
      setError('');
    }

    if (maxLength === null || (maxLength && result?.length <= maxLength)) {
      onChange(result);
    }
  };

  const handleBlur = () => {
    if (!readOnly && !isEmpty(validation)) {
      const test = validationMap[validation](value);
      if (!test) setError(errorMessage);
    }
  };
  useEffect(() => {
    if (errorMessage) handleBlur();
  }, [errorMessage]);

  return (
    <div className="text-input">
      <Input
        className={`text-input-value${isEmpty(error) ? '' : ' text-input-error'}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        type={type}
        required
        readOnly={readOnly}
        disabled={disabled}
      />
      <span className="text-input-label">{placeholder}</span>
      {!isEmpty(error) && <span className="error-label">{error}</span>}
    </div>
  );
};

export default PTextInput;
