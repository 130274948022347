import React from 'react';
import { Typography, Row, Col } from 'antd';
import { isEmpty } from 'lodash';

import { downloadFileUrl, getValueDefault } from '../../utilities/Functions';
import {
  docTypes,
  genderOptions,
  maxLengthDocTypes,
  personType,
  representativeProperty,
  validationDocTypes,
  validationType,
} from '../../utilities/Constant';

import WSelectInput from '../SelectInput';
import HeaderCompany from '../HeaderCompany';
import FileItem from '../FilteItem/FileItem';
import WTextInput from '../TextInput';

import './index.less';

const { Title } = Typography;

const defaultRepresentative = {
  title: 'Primer Representante',
  name: 'Lorem ipsum',
  lastNameOne: 'Lorem ipsum',
  lastNameTwo: 'Lorem ipsum',
  dni: '71732603',
  ruc: '71732603',
  businessName: 'Lorem ipsum',
  taxResidence: 'Lorem ipsum',
  electronicDeparture: '71732603',
  registryOffice: 'Lorem ipsum dolor sit amet.',
};

const RepresentativeCard = ({
  className = '',
  title = '',
  representative,
  selectedIndexRepresentative,
  representatives,
  selectedRepresentative,
  onSelectRepresentative,
  person,
  onSetRepresentative = () => {},
  isReadOnly,
}) => {
  const {
    name,
    middleName,
    familyName,
    gender,
    documentType,
    documentNumber,
    ruc,
    address,
    powerVigency,
    email,
  } = representative;

  const handleRepresentative = (value, property) => {
    const representativeToUpdate = { ...representative };
    representativeToUpdate[property] = value;
    onSetRepresentative(representativeToUpdate, selectedIndexRepresentative);
  };

  const handleSelectRepresentative = (value) => {
    const values = value.split(' ');
    const number = values[1];
    onSelectRepresentative(value, number - 1);
  };

  return (
    <div className={`representativeCard-container ${className}`}>
      <Row className="representativeCard-header">
        <Col span={12}>
          <Title className="representativeCard-title">{title}</Title>
        </Col>
        {representatives.length > 1 && (
          <div className="header-company-container">
            <HeaderCompany
              options={representatives}
              onSelect={handleSelectRepresentative}
              selectedOption={selectedRepresentative}
              showAdd={false}
            />
          </div>
        )}
      </Row>
      <Row className="representativeCard-row">
        <Col>
          <WTextInput
            placeholder="Nombres"
            value={getValueDefault(name)}
            onChange={(value) => handleRepresentative(value, representativeProperty.NAME)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Apellido Paterno"
            value={getValueDefault(middleName)}
            onChange={(value) => handleRepresentative(value, representativeProperty.MIDDLE_NAME)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Apellido Materno"
            value={getValueDefault(familyName)}
            onChange={(value) => handleRepresentative(value, representativeProperty.FAMILY_NAME)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WSelectInput
            options={genderOptions}
            placeholder="Género"
            value={gender}
            onChange={(value) => handleRepresentative(value, representativeProperty.GENDER)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WSelectInput
            options={docTypes}
            placeholder="Tipo de Doc"
            value={documentType}
            onChange={(value) => handleRepresentative(value, representativeProperty.DOCUMENT_TYPE)}
            readOnly={isReadOnly}
          />
        </Col>
        <Col>
          <WTextInput
            placeholder="Número de documento"
            value={getValueDefault(documentNumber)}
            onChange={(value) =>
              handleRepresentative(value, representativeProperty.DOCUMENT_NUMBER)
            }
            type="number"
            maxLength={
              isEmpty(getValueDefault(documentType)) ? 11 : maxLengthDocTypes[documentType]
            }
            validation={
              isEmpty(getValueDefault(documentType))
                ? validationType.RUC_NATURAL
                : validationDocTypes[documentType]
            }
            errorMessage={`Inválido ${documentType}`}
            readOnly={isReadOnly}
          />
        </Col>
        {person === personType.NATURAL && (
          <Col>
            <WTextInput
              placeholder="RUC"
              value={getValueDefault(ruc)}
              onChange={(value) => handleRepresentative(value, representativeProperty.RUC)}
              type="number"
              maxLength={11}
              validation={validationType.RUC_NATURAL}
              errorMessage="Inválido RUC"
              readOnly={isReadOnly}
            />
          </Col>
        )}
        {powerVigency && (
          <Col>
            <div className="justification-filesContainerColOne">
              <div>
                <FileItem file={powerVigency} download={downloadFileUrl} />
              </div>
            </div>
          </Col>
        )}
        {address && (
          <Col className="representative-address">
            <WTextInput
              placeholder="Dirección"
              value={getValueDefault(address)}
              onChange={(value) => handleRepresentative(value, representativeProperty.ADDRESS)}
              readOnly={isReadOnly}
            />
          </Col>
        )}
        {email && (
          <Col>
            <WTextInput
              placeholder="Correo de contacto"
              value={getValueDefault(email)}
              onChange={(value) => handleRepresentative(value, representativeProperty.EMAIL)}
              readOnly={isReadOnly}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
RepresentativeCard.defaultProps = {
  representative: defaultRepresentative,
};
export default RepresentativeCard;
