import React from 'react';
import { Row, Col } from 'antd';
import { ReactSVG } from 'react-svg';
import './index.less';

import TypeBadge from '../../../resources/svg/Icon-bookmark.svg';
import Calendar from '../../../resources/svg/Icon-Calendar.svg';
import Paperclip from '../../../resources/svg/Icon-paperclip.svg';

const WUserInfoCard = ({ user, configuration }) => {
  const { name, date, state, type, filesQuantity, requestNumber, imgUrl } = user;
  const { typeBadgeColor, stateColor } = configuration;

  return (
    <Col className="user-info-card">
      <Row justify="space-between" align="bottom">
        <WTypeBadge type={type} color={typeBadgeColor} />
        <WStateBadge state={state} color={stateColor} />
      </Row>
      <h3 className="user-request">
        Solicitud N° <span>{requestNumber}</span>
      </h3>
      <WUserData name={name} date={date} imgUrl={imgUrl} />
      <Row justify="end" align="middle" gutter={7}>
        <WUserFiles filesQuantity={filesQuantity} />
      </Row>
    </Col>
  );
};

const WTypeBadge = ({ type, color }) => {
  return (
    <Row justify="center" align="middle" gutter={[8, 0]}>
      <ReactSVG
        src={TypeBadge}
        beforeInjection={(svg) => {
          svg.setAttribute('fill', color);
        }}
      />
      <span style={{ color }}>{type}</span>
    </Row>
  );
};

const WStateBadge = ({ state, color }) => {
  return (
    <Row justify="center" align="middle">
      <div className="state-badge-ball" style={{ backgroundColor: color }} />
      <span>{state}</span>
    </Row>
  );
};

const WUserData = ({ name, date, imgUrl }) => {
  return (
    <Row justify="start" align="start">
      <img className="user-image" src={imgUrl} alt="" />
      <Col justify="center" align="middle">
        <h4 className="user-name">{name}</h4>
        <Row justify="start" align="middle" gutter={[6, 0]}>
          <ReactSVG src={Calendar} />
          <span className="user-date">{date}</span>
        </Row>
      </Col>
    </Row>
  );
};

const WUserFiles = ({ filesQuantity }) => {
  return (
    <>
      <ReactSVG src={Paperclip} />
      <span>{filesQuantity}</span>
    </>
  );
};

export default WUserInfoCard;
