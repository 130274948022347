import React from 'react';
import { Layout } from 'antd';

import WMenu from '../Menu';
import WUserInfo from '../UserInfo/index';

import './index.less';

const { Sider } = Layout;

const WSidebar = ({ collapsed, ...props }) => {
  return (
    <Sider
      width={250}
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      theme="light"
      align="center"
      collapsedWidth={0}
      trigger={null}
      className="sidebar-container"
    >
      <WUserInfo />
      <WMenu {...props} />
    </Sider>
  );
};

WSidebar.propTypes = {};

export default WSidebar;
