export const requestProperty = {
  TYPE: 'type',
  LEGAL_OWNER: 'legalOwner',
  CONTRACT_DATE: 'contractDate',
  EXPIRATION_DATE: 'expirationDate',
  USER_AREA: 'userArea',
  CONTRACT_TYPE: 'contractType',
  DESCRIPTION: 'description',
  ATTACHED: 'attached',
  COUNTER_PART: 'counterPart',
  CURRENT_COUNTER_PART: 'currentCounterPart',
  CONTRACT_MODEL: 'contractModel',
};
