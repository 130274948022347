import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import DetailRequestTemplate from '../../templates/DetailRequest/DetailRequestTemplate';
import DetailRequestNewTemplate from '../../templates/DetailRequestNew/DetailRequestNew';
import RequestServices from '../../../domain/usecases/RequestServices';
import { Routes } from '../../configuration/routes/Routes';
import ObservationServices from '../../../domain/usecases/ObservationServices';
import { ROL_CODE } from '../../utilities/Constant';
import UserServices from '../../../domain/usecases/UserServices';
import NotificationServices from '../../../domain/usecases/NotificationServices';

export default function DetailRequest(props) {
  const { isNotUser } = props;
  const { id: requestId } = useParams();
  const history = useHistory();

  const [requestDetail, setRequestDetail] = useState({});
  const [requestUpdate, setRequestUpdate] = useState({});
  const [loading, setLoading] = useState(true);
  const [legalOwners, setLegalOwners] = useState([]);
  const [observations, setObservations] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const { isLegalOwner, isAdmin, isApplicant } = useSelector((state) => state.user);
  const [notifications, setNotifications] = useState([]);

  const onBack = () => {
    history.push(Routes.REQUEST.LIST);
  };

  const getRequestDetail = useCallback(async () => {
    setLoading(true);
    const [data, error] = await RequestServices.getRequestById(requestId);

    if (data && error === null) {
      setRequestDetail(data);
    } else {
      //  todo manage the errors
    }
    setLoading(false);
  }, []);

  const loadLegalOwners = useCallback(async () => {
    const [data, error] = await UserServices.getUsersByRolId(ROL_CODE.legalOwner);
    if (data && error === null) {
      setLegalOwners([...data]);
    } else {
      //  todo manage the errors
    }
  }, []);

  const getApplicants = useCallback(async () => {
    const [data, error] = await UserServices.getUsersByRolId(ROL_CODE.applicant);
    if (data && error === null) {
      setApplicants([...data]);
    } else {
      //  todo manage the errors
    }
  }, []);

  const getObservations = useCallback(async () => {
    const [data, error] = await ObservationServices.getObservations(requestId);
    if (data && error === null) {
      setObservations(data);
    } else {
      //  todo manage the errors
    }
  }, []);

  const addComment = async (commentJson, observationId) => {
    const [data, error] = await ObservationServices.saveComment(
      commentJson,
      observationId,
      requestId
    );

    if (data && error === null) {
      await getObservations();
    } else {
      //  todo manage the errors
    }
  };

  const approveObservation = async (observationId) => {
    const [data, error] = await ObservationServices.approveObservation(requestId, observationId);

    if (data && error === null) {
      await getObservations();
      await getRequestDetail();
    } else {
      //  todo manage the errors
    }
  };

  const sendObservation = async (observationId) => {
    const [data, error] = await ObservationServices.sendObservation(requestId, observationId);
    if (data && error === null) {
      await getObservations();
    } else {
      //  todo manage the errors
    }
  };

  const rejectObservation = async (observationId) => {
    const [data, error] = await ObservationServices.rejectObservation(requestId, observationId);

    if (data && error === null) {
      await getObservations();
      await getRequestDetail();
    } else {
      //  todo manage the errors
    }
  };

  const deleteObservation = async (observationId) => {
    const [data, error] = await ObservationServices.deleteObservation(requestId, observationId);

    if (data && error === null) {
      await getObservations();
      await getRequestDetail();
    } else {
      //  todo manage the errors
    }
  };

  const updateObservation = async (observationId, jsonJustification) => {
    const [data, error] = await ObservationServices.updateObservation(
      requestId,
      observationId,
      jsonJustification
    );

    if (data && error === null) {
      await getObservations();
      await getRequestDetail();
    } else {
      //  todo manage the errors
    }
  };

  const getNotifications = useCallback(async () => {
    const [data, error] = await NotificationServices.getNotifications(requestId);
    if (data && error === null) {
      setNotifications(data);
    } else {
      //  todo manage the errors
    }
  }, []);

  const onSetRequest = (property, value) => {
    setRequestDetail((prev) => ({ ...prev, [property]: value }));
    setRequestUpdate((prev) => ({ ...prev, [property]: value }));
  };

  useEffect(() => {
    getRequestDetail().then();
  }, [getRequestDetail]);

  useEffect(() => {
    loadLegalOwners().then();
  }, [loadLegalOwners]);

  useEffect(() => {
    getObservations().then();
  }, [getObservations]);

  useEffect(() => {
    getApplicants();
  }, [getApplicants]);

  useEffect(() => {
    getNotifications().then();
  }, [getNotifications]);

  return loading ? (
    <Spin className="centered-spin" spinning={loading} tip="Cargando información..." size="large" />
  ) : (
    <>
      {isLegalOwner && requestDetail.state === 'New' ? (
        <DetailRequestNewTemplate
          requestDetail={requestDetail}
          requestId={requestId}
          onBack={onBack}
          legalOwners={legalOwners}
          applicants={applicants}
        />
      ) : (
        <DetailRequestTemplate
          requestDetail={requestDetail}
          requestId={requestId}
          onBack={onBack}
          observations={observations}
          addComment={addComment}
          isNotUser={isNotUser}
          approveObservation={approveObservation}
          sendObservation={sendObservation}
          rejectObservation={rejectObservation}
          deleteObservation={deleteObservation}
          getRequestDetail={getRequestDetail}
          getObservations={getObservations}
          updateObservation={updateObservation}
          isAdmin={isAdmin}
          isLegalOwner={isLegalOwner}
          isApplicant={isApplicant}
          notifications={notifications}
          setNotifications={setNotifications}
          getNotifications={getNotifications}
          requestUpdate={requestUpdate}
          onSetRequest={onSetRequest}
        />
      )}
    </>
  );
}
