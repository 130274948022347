import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
// eslint-disable-next-line camelcase

import LoginTemplate from '../../templates/Login/LoginTemplate';
import { login } from '../../redux/ducks/user';
import { Routes } from '../../configuration/routes/Routes';
import {
  ROL_CODE,
  validateLowerCase,
  validateNumber,
  validateSpace,
  validateSpecialCharacter,
  validateUpperCase,
} from '../../utilities/Constant';
import Loading from '../../components/Loading';

const MIN_CHARACTERS = 8;
const MAX_CHARACTERS = 24;

export default function Login() {
  const { error, roleId, loading, isProcessing } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      user: Yup.string().required('Usuario  es requerido'),
      password: Yup.string()
        .min(MIN_CHARACTERS, `Necesita mínimo ${MIN_CHARACTERS} caracteres`)
        .max(MAX_CHARACTERS, `Necesita máximo ${MAX_CHARACTERS} caracteres`)
        .matches(validateSpace, 'No incluir espacios en blanco')
        .matches(validateLowerCase, 'Mínimo 1 minúscula')
        .matches(validateUpperCase, 'Mínimo 1 mayúscula')
        .matches(validateNumber, 'Mínimo 1 número')
        .matches(validateSpecialCharacter, 'Mínimo 1 caracter especial')
        .required('La contraseña es requerida'),
    }),
    onSubmit(form) {
      const { user, password } = form;
      onLogin(user, password);
    },
  });

  const onLogin = (user, password) => {
    dispatch(login(user, password));
  };

  useEffect(() => {
    if (error && isEmpty(error)) {
      if (roleId === ROL_CODE.applicant) {
        history.push(Routes.HOME);
      } else {
        history.push(Routes.HOME_ADMIN);
      }
    }
  }, [error]);
  // TODO: hide google login

  // const getuserinfo = async () => {
  //   try {
  //     const currentSession = await Auth.currentSession();
  //     if (currentSession) {
  //       const email = currentSession.idToken?.payload?.email;
  //       const idToken = currentSession?.idToken?.jwtToken;
  //       localStorage.setItem('token', idToken);
  //       dispatch(login(email, null));
  //     }
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };

  // useEffect(() => {
  //   Hub.listen('auth', ({ payload: { event } }) => {
  //     console.log('eventevent ', event);
  //     switch (event) {
  //       case 'signIn':
  //         getuserinfo();
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   Auth.currentAuthenticatedUser()
  //     .then((e) => console.log('loguead', e))
  //     .catch((e) => {
  //       dispatch(changeLoading(false));
  //       console.log('Not signed in - Protecta', e);
  //     });
  // }, []);

  return loading ? (
    <Loading size="large" />
  ) : (
    <LoginTemplate onLogin={onLogin} formik={formik} error={error} isProcessing={isProcessing} />
  );
}
