import UserRepository from '../repositories/UserRepository';

export default class UserServices {
  static async getUsers() {
    const [data, error] = await UserRepository.getUsers();
    return [data, error];
  }

  static async getUsersByRolId(rolId) {
    const [data, error] = await UserRepository.getUsersByRolId(rolId);
    return [data, error];
  }

  static async getUserByEmail(email) {
    const [data, error] = await UserRepository.getUserByEmail(email);
    return [data, error];
  }
}
