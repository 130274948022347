import React from 'react';
import { Typography } from 'antd';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Paragraph } = Typography;
const defaultSteps = [
  'Tipo de solicitud',
  'Responsable',
  'Descripción',
  'Contraparte',
  'Modelo de contrato',
];

const WNewRequest = ({ numberState, steps }) => {
  return (
    <div className="container-newRequest">
      <div className="container-main">
        {steps.map((item, index) => {
          return (
            <div key={`request-step-${item}`} className="d-flex">
              <div className="step">
                <span className="ant-steps-sinicon">
                  {index < numberState - 1 ? (
                    <CustomIcon type="checkGreen" />
                  ) : (
                    <div
                      className={`${
                        index === numberState - 1
                          ? 'ant-steps-current'
                          : ' ant-steps-current ant-steps-mayor'
                      }`}
                    >
                      <span className="ant-steps-icon number-select">{index + 1}</span>
                    </div>
                  )}
                </span>
                <Paragraph className="step-title">{item}</Paragraph>
              </div>
              {index < steps.length - 1 && <div className="ant-steps-item-tails" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

WNewRequest.propTypes = {};

WNewRequest.defaultProps = {
  steps: defaultSteps,
};

export default WNewRequest;
