import { doGet } from '../../data/api/ApiService';
import { Error } from '../../data/entities/Error';

export default class RequestRepository {
  static async getPresingUrl(fileName) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(process.env.REACT_APP_BASE_URL_PRESING, null, {
        'file-name': fileName,
      });
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse?.data && dataResponse.status?.id === 201) {
        data = dataResponse.data;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async uploadFile(presingedUrl, data, type) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', type);

      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: data,
        redirect: 'follow',
      };

      const response = await fetch(presingedUrl, requestOptions);
      await response.text();
      return [true, null];
    } catch (error) {
      console.log('error ->', error);
      const err = new Error({ code: '500', message: error });
      return [null, err];
    }
  }

  static async downloadFileUrl(id) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(`${process.env.REACT_APP_BASE_URL_PRESING}/${id}`);
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse?.data && dataResponse.status?.id === 201) {
        data = dataResponse.data;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
