import React, { useState } from 'react';
import { Typography, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { useAws } from '../../context/aws/awsProvider';
import { pathServer } from '../../utilities/Constant';
import { CustomIcon } from '../../components';

import './index.less';

const { Title, Text } = Typography;

export default function LoginTemplate({ formik, error, isProcessing }) {
  const [hidePassword, setHidePassword] = useState(true);
  const { oauthGoogle } = useAws();

  const onHidePassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <div className="container-login">
      <section className="firstSection-login">
        <img src={`${pathServer.PATH_IMG}logo_login.svg`} />
        <div className="firstSection-subcontainer">
          <div className="firstSection-title">
            <Title level={2} className="titleOne-login">
              Bienvenido al Gestor de Contratos
            </Title>
            <Title className="titleTwo-login">Protecta Security</Title>
          </div>
          <img src={`${pathServer.PATH_IMG}login.svg`} />
        </div>
      </section>
      <form onSubmit={formik.handleSubmit} className="secondSection-login">
        <Row>
          <Col span={24} className="col-login">
            <Title level={2} className="titleThree-login">
              Iniciar Sesión
            </Title>
          </Col>

          <Col span={24} className="col-login">
            <div>
              <button className="google-button" type="button" onClick={() => oauthGoogle()}>
                <CustomIcon className="repository-menuItem-iconCollapsed" type="google" />
                <Text> Iniciar sesión con Google</Text>
              </button>
              <div>
                <div className="inputUser-login">
                  <input type="text" {...formik.getFieldProps('user')} />
                  <Text className="label-inputUser">Usuario</Text>
                </div>
                <Text className="alert-login">{formik.touched.user && formik.errors.user}</Text>
              </div>
              <div>
                <div className="inputUser-login">
                  <input
                    type={hidePassword ? 'password' : 'text'}
                    {...formik.getFieldProps('password')}
                  />
                  <CustomIcon
                    onClick={() => onHidePassword()}
                    className="icon-password"
                    type={hidePassword ? 'hidePassword' : 'showPassword'}
                  />
                  <Text className="label-inputUser">Contraseña</Text>
                </div>
                <Text className="alert-login">
                  {formik.touched.password && formik.errors.password}
                </Text>
              </div>
              {!isEmpty(error) && isProcessing && (
                <span className="alert-login">{`* ${error.message}`}</span>
              )}
              <button className="bnEnter-login" type="submit">
                Ingresar
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
}
