import { Col, Typography, Row } from 'antd';
import React from 'react';
import './index.less';

const { Title, Text } = Typography;

const Toggle = ({ options, title, subtitle, className, onChange, optionSelected }) => {
  return (
    <div className={`toggle-container ${className}`}>
      <Title className="toggle-title">{title}</Title>
      <Text className="toggle-subtitle">{subtitle}</Text>
      <Row gutter={30}>
        {options.map((option, index) => {
          return (
            <Col key={option.label || option.value}>
              <button
                type="button"
                className={
                  index === optionSelected
                    ? 'toggle-option-selected toggle-options'
                    : 'toggle-options'
                }
                onClick={() => onChange(index)}
              >
                {option.label}
              </button>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Toggle;

Toggle.defaultProps = {
  options: [{ name: 'Contrato' }, { name: 'Adenda' }, { name: 'Resolución' }],
  onChangeState: () => {},
};
