import React from 'react';
import { Typography } from 'antd';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Title, Paragraph } = Typography;
const WSummaryRequest = ({ user }) => {
  const { title, status, contractType, dateReceived, dateSent, requestUrl } = user;
  return (
    <div className="container-resumeRequest">
      <Title level={4} className="title">
        {title}
      </Title>
      <Paragraph className="mar-bot">Estado</Paragraph>
      <Paragraph className="status ant-tag-x">{status}</Paragraph>
      <Paragraph className="mar-bot">Tipo</Paragraph>
      <div className="container-contract">
        <CustomIcon type="contract" className="icon" />
        <Paragraph className="contract">{contractType}</Paragraph>
      </div>
      <div>
        <div className="container-contract date-contract">
          <CustomIcon type="selectCalendar" className="icon" />
          <Paragraph>Recibido: {dateReceived}</Paragraph>
        </div>
        <div className="container-contract date-contract">
          <CustomIcon type="selectCalendar" className="icon" />
          <Paragraph>Enviado: {dateSent}</Paragraph>
        </div>
      </div>
      <a href={requestUrl} target="_blank" style={{ marginTop: '15px' }} rel="noreferrer">
        Ver documento
      </a>
    </div>
  );
};

WSummaryRequest.propTypes = {};

export default WSummaryRequest;
