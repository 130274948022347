export class User {
  constructor(props) {
    this.id = props.Sort;
    this.email = props.userId;
    this.type = props.Id;
    this.firstName = props.name;
    this.fatherLastName = props.lastName;
    this.motherLastName = props.secondLastName;
    this.name = props.name;
    this.phone = props.phone;
    this.cargo = props.cargo;
    this.sede = props.sede;
    this.avatar = props.avatar;
    this.roleId = props.roleId;
  }
}
