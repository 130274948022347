import { isEmpty, isNull } from 'lodash';
import { getErrorByStep } from '../../NewRequest/NewRequestTemplate';
import { validateAttachement } from '../../../utilities/Functions';
import { SECTION_DETAIL } from '../../../utilities/Constant';

export const validationsDetail = (requestUpdate = {}, requestAll) => {
  let errorRequest = false;
  let errorCounterPart = false;
  let errorAnnexes = false;
  let errorModel = false;
  const sectionEdit = [];
  const { request, counterPart, attached, contractModel } = SECTION_DETAIL;

  const elememtsRequest = [
    'contractDateTimestamp',
    'expirationDateTimestamp',
    'userArea',
    'contractType',
    'description',
  ];
  const editRequest = elememtsRequest.some((item) => item in requestUpdate);

  if (editRequest) {
    errorRequest = requestValidations(requestAll);
    if (errorRequest) sectionEdit.push(request);
  }

  if (counterPart in requestUpdate) {
    errorCounterPart = counterPartValidations(requestAll);
    if (errorCounterPart) sectionEdit.push(counterPart);
  }
  if (attached in requestUpdate) {
    errorAnnexes = annexesValidations(requestAll);
    if (errorAnnexes) sectionEdit.push(attached);
  }
  if (contractModel in requestUpdate) {
    errorModel = modelValidations(requestAll);
    if (errorModel) sectionEdit.push(contractModel);
  }
  const hasError = errorRequest || errorCounterPart || errorAnnexes || errorModel;
  return [hasError, sectionEdit];
};

const requestValidations = (request) => {
  const { userArea, contractType, description, contractDateTimestamp, expirationDateTimestamp } =
    request;

  const error =
    !contractDateTimestamp ||
    !expirationDateTimestamp ||
    contractDateTimestamp >= expirationDateTimestamp ||
    isEmpty(userArea) ||
    isNull(contractType) ||
    isEmpty(description);

  return error;
};
const counterPartValidations = (request) => {
  return getErrorByStep(request, 4);
};
const annexesValidations = ({ attached }) => {
  const hasError = validateAttachement(attached);
  return hasError;
};

const modelValidations = (request) => {
  return getErrorByStep(request, 5);
};
