import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';
import './index.less';

const { Paragraph } = Typography;

const ReturnPage = ({ labelButton, onChangeType, className }) => {
  return (
    <div className={`returnPage-container ${className}`} onClick={onChangeType}>
      <CustomIcon type="arrowLeft" />
      <Paragraph className="returnPage-text">{labelButton}</Paragraph>
    </div>
  );
};

ReturnPage.displayName = 'ReturnPage';

ReturnPage.propTypes = {
  labelButton: PropTypes.string,
  onChangeType: PropTypes.func,
};

ReturnPage.defaultProps = {
  labelButton: 'Regresar',
  onChangeType: () => {},
};

export default ReturnPage;
