import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import CustomIcon from '../CustomIcon/CustomIcon';

import './index.less';

const { Text } = Typography;

const typeRequest = {
  all: {
    icon: 'requestAll',
    message: 'Todos',
  },
  new: {
    icon: 'requestWait',
    message: 'Nuevo',
  },
  process: {
    icon: 'requestProcess',
    message: 'Proceso',
  },
  revision: {
    icon: 'requestRevision',
    message: 'Revision',
  },
  validation: {
    icon: 'requestValidation',
    message: 'Validación',
  },
  finalized: {
    icon: 'requestFinalized',
    message: 'Finalizado',
  },
  observation: {
    icon: 'requestObservation',
    message: 'Observado',
  },
  cancelled: {
    icon: 'requestAll',
    message: 'Cancelado',
  },
};
const RequestStatus = ({ type, className, onClick }) => {
  return (
    <div className={`requestStatus-container ${className}`} onClick={onClick}>
      <CustomIcon type={typeRequest[type]?.icon || 'requestAll'} />
      <Text className="requestStatus-text">{typeRequest[type]?.message || ''}</Text>
    </div>
  );
};

RequestStatus.displayName = 'ReturnPage';

RequestStatus.propTypes = {
  type: PropTypes.string,
};

RequestStatus.defaultProps = {
  type: 'all',
};

export default RequestStatus;
