import React from 'react';
import { Typography, Modal, Spin } from 'antd';

import CustomIcon from '../CustomIcon/CustomIcon';
import { downloadFileUrl } from '../../utilities/Functions';
import FileItemFolder from '../FileItemFolder/FileItemFolder';
import WUploadFile from '../UploadFile';

import './index.less';

const { Paragraph, Text } = Typography;

const AnnexesModal = ({
  visible,
  detailFolder,
  title,
  onClose,
  isReadOnly,
  handleUpload,
  onDelete,
  showIcons,
  loading,
}) => {
  const onDownloadFile = (id) => {
    downloadFileUrl({ id });
  };
  return (
    <Modal visible={visible} footer={null}>
      <div className="container-annexesModal">
        <CustomIcon type="closeGrey" className="iconClose-annexesModal" onClick={onClose} />
        <Paragraph className="title-annexesModal">{title}</Paragraph>
        <div>
          {detailFolder.length === 0 && <Text>No hay ningún archivo en esta carpeta</Text>}
          {detailFolder?.map((at) => (
            <FileItemFolder
              key={at?.id}
              file={at}
              createForm={false}
              onDownload={onDownloadFile}
              onDelete={onDelete}
              showIcons={showIcons}
            />
          ))}
          {loading && (
            <div>
              <Spin size="small" />
              <Text> Subiendo archivo</Text>
            </div>
          )}
        </div>
        {!isReadOnly && (
          <div className="upload-file">
            <WUploadFile
              title="+ Subir archivo"
              fileList={detailFolder}
              handleUpload={handleUpload}
              icon={null}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AnnexesModal;

AnnexesModal.defaultProps = {
  visible: false,
  title: 'Anexos',
};
