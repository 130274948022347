import React from 'react';
import { Input } from 'antd';
import { ReactSVG } from 'react-svg';
import Zoom from '../../../resources/svg/Icon-zoom.svg';
import './index.less';

const WSearchInput = ({ placeholder, value, onChange }) => {
  return (
    <Input
      placeholder={placeholder}
      prefix={<ReactSVG src={Zoom} />}
      className="search-input"
      onChange={onChange}
      value={value}
    />
  );
};

export default WSearchInput;
