import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Routes } from './Routes';
import { ROL_CODE } from '../../utilities/Constant';

import withDashboard from '../../hocs/DashboardPage';
import Home from '../../pages/Home/index';
import HomeAdmin from '../../pages/Home/Admin/index';
import IconDocumentation from '../../pages/IconDocumentation';
import Login from '../../pages/Login/Login';
import Requests from '../../pages/Requests';
import NewRequest from '../../pages/NewRequest';
import DetailRequest from '../../pages/DetailRequest';
import Repository from '../../pages/Repository';
import Members from '../../pages/Members';

import '../../../resources/styles/base.less'; // or 'antd/dist/antd.less'

const RoutesPage = () => {
  const { errorLogin, roleId, isApplicant, isLegalOwner, isAdmin } = useSelector(
    (state) => state.user
  );
  const redirectByUserRole = () => {
    if (isApplicant) return Routes.HOME;
    if (isLegalOwner || isAdmin) return Routes.HOME_ADMIN;
    return Routes.LOGIN;
  };
  const PrivateRoute = ({ component, path, scope = [], ...props }) => {
    const hasAccess = scope.includes(roleId);
    if (isEmpty(errorLogin) && hasAccess) {
      return <Route component={component} path={path} {...props} />;
    }
    const redirect = redirectByUserRole();
    return <Redirect to={redirect} {...props} />;
  };

  return (
    <Router>
      <Switch>
        <PrivateRoute
          exact
          path={Routes.HOME}
          component={withDashboard(Home, '1')}
          scope={[ROL_CODE.applicant]}
        />
        <PrivateRoute
          exact
          path={Routes.HOME_ADMIN}
          component={withDashboard(HomeAdmin, '1')}
          scope={[ROL_CODE.legalOwner, ROL_CODE.admin]}
        />
        <PrivateRoute
          exact
          path={Routes.REQUEST.LIST}
          component={withDashboard(Requests, '2')}
          scope={[ROL_CODE.applicant, ROL_CODE.legalOwner, ROL_CODE.admin]}
        />
        <PrivateRoute
          exact
          path={Routes.REQUEST.NEW}
          component={withDashboard(NewRequest, '2')}
          scope={[ROL_CODE.applicant]}
        />
        <PrivateRoute
          exact
          path={`${Routes.REQUEST.DETAIL}/:id`}
          component={withDashboard(DetailRequest, '2')}
          scope={[ROL_CODE.applicant, ROL_CODE.legalOwner, ROL_CODE.admin]}
        />
        {/* admin */}
        <PrivateRoute
          exact
          path={Routes.REPOSITORY}
          component={withDashboard(Repository, '3')}
          scope={[ROL_CODE.legalOwner, ROL_CODE.admin]}
        />
        <PrivateRoute
          exact
          path={Routes.MEMBERS}
          component={withDashboard(Members, '4')}
          scope={[ROL_CODE.legalOwner, ROL_CODE.admin]}
        />
        <PrivateRoute exact path={Routes.ICONS} component={IconDocumentation} />
        <PrivateRoute
          exact
          path={Routes.MAIN}
          scope={[ROL_CODE.applicant, ROL_CODE.legalOwner, ROL_CODE.admin]}
          render={() => <Redirect to={redirectByUserRole()} />}
        />
        <Route exact path={Routes.LOGIN} component={Login} />
        <Route
          render={() => {
            const route = redirectByUserRole();
            return <Redirect to={route} />;
          }}
        />
      </Switch>
    </Router>
  );
};

export default RoutesPage;
