import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { Typography, Divider, Row, Col, Input, Button, Spin } from 'antd';
import moment from 'moment';

import { cutChainString, downloadFileUrl, formatDate } from '../../../utilities/Functions';
import ObservationServices from '../../../../domain/usecases/ObservationServices';

import { ConfirmationModal, CustomIcon } from '../../../components/index';

import './index.less';

const { Title, Text } = Typography;
const { TextArea } = Input;

const commentInitial = {
  commentary: '',
};

const ObservationTabRequestTemplate = forwardRef(
  (
    {
      requestObservations,
      isNotUser,
      isLegalOwner,
      isApplicant,
      approveObservation,
      sendObservation,
      requestId,
      rejectObservation,
      deleteObservation,
      updateObservation,
      onOptionObsSelected,
      isAdmin,
    },
    ref
  ) => {
    const infoObservations = [];
    const modelObservation = [];
    const counterPartObservation = [];
    const annexesObservations = [];

    const sortRequestObservations = requestObservations.sort((a, b) => {
      return moment(b.creationDate).diff(moment(a.creationDate));
    });
    // eslint-disable-next-line consistent-return
    sortRequestObservations.forEach((observation) => {
      const { type } = observation;
      const { id } = type;
      switch (id) {
        case 2:
          infoObservations.push(observation);
          break;
        case 1:
          modelObservation.push(observation);
          break;
        case 3:
          counterPartObservation.push(observation);
          break;
        case 4:
          annexesObservations.push(observation);
          break;
        default:
          return null;
      }
    });

    const getIdSelectedDefault = () => {
      let id;
      if (infoObservations.length > 0) {
        id = infoObservations[0]?.id;
      } else if (counterPartObservation.length > 0) {
        id = counterPartObservation[0]?.id;
      } else if (annexesObservations.length > 0) {
        id = annexesObservations[0]?.id;
      }
      return id;
    };

    const [optionSelected, setOptionSelected] = useState(0);
    const [observationSelectedId, setObservationSelectedId] = useState(getIdSelectedDefault());
    const [commentJson, setCommentJson] = useState({ ...commentInitial });
    const [comments, setComments] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [showCancelObsModal, setShowCancelObsModal] = useState(false);
    const [showApproveObsModal, setShowApproveObsModal] = useState(false);
    const [showSendObsModal, setShowSendObsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingComment, setLoadingComment] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        optionSelected,
      };
    });

    useEffect(() => {
      getComments();
    }, [observationSelectedId]);

    const getComments = async () => {
      const [data, error] = await ObservationServices.getComments(requestId, observationSelectedId);
      if (data && error === null) {
        setComments(data);
      } else {
        //  todo manage the errors
      }
    };

    const onChangeDescription = (evt) => {
      const newJsonComment = { ...commentJson };
      newJsonComment.commentary = evt.target.value;
      setCommentJson({ ...newJsonComment });
    };
    const onDownload = (id) => {
      downloadFileUrl({ id });
    };

    const selectTabOption = (index) => {
      onOptionObsSelected(index);
      setOptionSelected(index);
      setObservationSelectedId(index === 0 ? getIdSelectedDefault() : modelObservation[0]?.id);
      setCommentJson({ ...commentInitial });
    };

    const addComment = async () => {
      setLoadingComment(true);
      if (observationSelectedId) {
        const [data, error] = await ObservationServices.saveComment(
          commentJson,
          observationSelectedId,
          requestId
        );

        if (data && error === null) {
          setCommentJson({ ...commentInitial });
          getComments();
          setLoadingComment(false);
        } else {
          //  todo manage the errors
          setLoadingComment(false);
        }
      }
    };

    const currentObservationSelected = requestObservations.find(
      (x) => x.id === observationSelectedId
    );
    const isDelete = (optionSelected === 0 && !currentObservationSelected?.isEditable) || false;
    const [text, setText] = useState(currentObservationSelected?.justification ?? '');

    const getOnclick = async () => {
      setLoading(true);
      try {
        if (isNotUser && currentObservationSelected.state === 'New') {
          await approveObservation(observationSelectedId);
          setShowApproveObsModal(false);
        }

        if (!currentObservationSelected.isEditable) {
          await sendObservation(observationSelectedId);
          setShowSendObsModal(false);
        }
      } finally {
        setLoading(false);
      }
    };

    const getText = () => {
      let iconText = '';
      if (isNotUser) {
        if (currentObservationSelected.state === 'New') iconText = 'Aprobar';
      } else if (!currentObservationSelected.isEditable) iconText = 'Enviar';
      return iconText;
    };

    const getButtonCancelText = () => {
      let iconText = '';
      if (isDelete) {
        iconText = 'Eliminar';
      } else if (currentObservationSelected.state !== 'Rejected') {
        iconText = 'Rechazar';
      }
      return iconText;
    };

    const getCancelOnclick = async () => {
      setLoading(true);

      let onClickFunction = () => {};
      try {
        if (isDelete) {
          onClickFunction = deleteObservation(observationSelectedId);
          setObservationSelectedId(null);
        } else if (currentObservationSelected && currentObservationSelected.state !== 'Rejected') {
          onClickFunction = await rejectObservation(observationSelectedId);
        }
      } catch (error) {
        //  todo manage the errors
      } finally {
        setLoading(false);
      }

      return onClickFunction;
    };

    return (
      <div>
        <div className="container-head">
          <button
            type="button"
            className={optionSelected === 0 ? 'obsTag-bn-selected' : 'obsTag-bn'}
            onClick={() => {
              selectTabOption(0);
            }}
          >
            <CustomIcon type={optionSelected === 0 ? 'requestWait' : 'requestProcess'} />
            Observaciones de información
          </button>
          <button
            type="button"
            className={optionSelected === 1 ? 'obsTag-bn-selected' : 'obsTag-bn'}
            onClick={() => {
              selectTabOption(1);
            }}
          >
            <>
              <CustomIcon type={optionSelected === 1 ? 'requestWait' : 'requestProcess'} />{' '}
              Observación de modelo
            </>
          </button>
        </div>

        <div className="obsTag-container">
          <div className="observationTag-sideBar">
            {optionSelected === 0 ? (
              <>
                {infoObservations.length > 0 && (
                  <Title className="obsTag-sideBar-title">Información de Solicitud</Title>
                )}
                {infoObservations.map((item, index) => {
                  const { id } = item;
                  return (
                    <div
                      key={`info-observation ${id}`}
                      className={
                        id === observationSelectedId
                          ? 'obsTag-observationSelected'
                          : 'obsTag-observation'
                      }
                      onClick={() => setObservationSelectedId(id)}
                    >
                      <Title className="observation-title">{`Observación #${index + 1}`}</Title>
                      <Text className="observation-date">{formatDate(item.creationDate)}</Text>
                      <Text>{item.justification.substring(0, 28)}...</Text>
                    </div>
                  );
                })}
                {counterPartObservation.length > 0 && (
                  <Title className="obsTag-sideBar-title">Contraparte</Title>
                )}
                {counterPartObservation.map((item, index) => {
                  const { id } = item;
                  return (
                    <div
                      key={`counter-part-observation ${id}`}
                      className={
                        id === observationSelectedId
                          ? 'obsTag-observationSelected'
                          : 'obsTag-observation'
                      }
                      onClick={() => setObservationSelectedId(id)}
                    >
                      <Title className="observation-title">{`Observación #${
                        infoObservations.length + index + 1
                      }`}</Title>
                      <Text className="observation-date">{formatDate(item.creationDate)}</Text>
                      <Text>{item.justification.substring(0, 28)}...</Text>
                    </div>
                  );
                })}
                {annexesObservations.length > 0 && (
                  <Title className="obsTag-sideBar-title">Anexos</Title>
                )}
                {annexesObservations.map((item, index) => {
                  const { id } = item;
                  return (
                    <div
                      key={`info-observation ${id}`}
                      className={
                        id === observationSelectedId
                          ? 'obsTag-observationSelected'
                          : 'obsTag-observation'
                      }
                      onClick={() => setObservationSelectedId(id)}
                    >
                      <Title className="observation-title">{`Observación #${
                        infoObservations.length + counterPartObservation.length + index + 1
                      }`}</Title>
                      <Text className="observation-date">{formatDate(item.creationDate)}</Text>
                      <Text>{item.justification.substring(0, 28)}...</Text>
                    </div>
                  );
                })}
                {infoObservations.length ||
                counterPartObservation.length ||
                annexesObservations.length ? null : (
                  <p>No hay observaciones en la información.</p>
                )}
              </>
            ) : (
              <>
                {modelObservation.length > 0 ? (
                  <>
                    <Title className="obsTag-sideBar-title">Modelo sugerido</Title>
                    {modelObservation.map((item) => {
                      const { id, file } = item;

                      return (
                        <div
                          key={`info-observation ${id}`}
                          className={
                            id === observationSelectedId
                              ? 'obsTag-observationSelected'
                              : 'obsTag-observation'
                          }
                          onClick={() => setObservationSelectedId(id)}
                        >
                          <Title className="observation-title">Observación modelo</Title>

                          <Row gutter={18}>
                            <Col flex="1 1 50px">
                              <Text className="observation-date">
                                {formatDate(item.creationDate)}
                              </Text>
                            </Col>
                            {file && (
                              <Col flex="0 0 23px" className="fileItem-download">
                                <CustomIcon
                                  type="downloadFile"
                                  className="icon"
                                  onClick={() => onDownload(file.id)}
                                />
                              </Col>
                            )}
                          </Row>
                          <Row gutter={18}>
                            <Col flex="1 1 50px">
                              <Text>{item && cutChainString(item.justification, 28)}</Text>
                            </Col>
                            <Col flex="0 0 23px" className="fileItem-download">
                              {file && <Text className="fileItem-size">{file.size}kb</Text>}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <p>No hay un modelo de observación sugerido.</p>
                )}
              </>
            )}
          </div>
          {/** * Right container ** */}
          {observationSelectedId && (
            <div style={{ flexGrow: 1 }}>
              <div className="obsTag-content">
                <div className="obsTag-content-icons">
                  {/** * Usuario Solicitante** */}
                  {isApplicant && currentObservationSelected.state !== 'Rejected' && (
                    <>
                      <div
                        className="obsTag-content-icon"
                        onClick={() => {
                          if (
                            currentObservationSelected.state === 'New' &&
                            !currentObservationSelected.isEditable
                          )
                            setShowSendObsModal(true);
                        }}
                      >
                        <CustomIcon
                          className={
                            (currentObservationSelected.isEditable ||
                              currentObservationSelected.state === 'Approved') &&
                            'obsTag--state__icon'
                          }
                          type="successAlert_big"
                        />
                        <Text className="icon-label"> {getText()}</Text>
                      </div>
                      {showSendObsModal && (
                        <ConfirmationModal
                          visible={showSendObsModal}
                          icon="alertNotification"
                          title="Confirmación de envío"
                          subtitle="¿Estás seguro de enviar esta observación?"
                          buttonOne="Cancelar"
                          buttonTwo="Aceptar"
                          onClose={() => setShowSendObsModal(false)}
                          onClickBnOne={() => setShowSendObsModal(false)}
                          onClickBnTwo={getOnclick}
                          isLoading={loading}
                        />
                      )}
                    </>
                  )}
                  {isAdmin && currentObservationSelected.state === 'Approved' && (
                    <div className="obsTag-content-icon cursor-default">
                      <CustomIcon className="obsTag--state__icon" type="successAlert_big" />
                    </div>
                  )}
                  {(isApplicant || isAdmin) && currentObservationSelected.state === 'Rejected' && (
                    <>
                      <div className="obsTag-content-icon">
                        <CustomIcon
                          className={
                            currentObservationSelected.state === 'Reject' &&
                            'obsTag--state__icon reject'
                          }
                          type="rejectRed"
                        />
                      </div>
                    </>
                  )}
                  {/** * Representante Legal** */}
                  {isLegalOwner &&
                    currentObservationSelected.isEditable &&
                    ['New', 'Approved'].includes(currentObservationSelected.state) && (
                      <>
                        <div
                          className="obsTag-content-icon"
                          onClick={() => {
                            if (currentObservationSelected.state === 'New')
                              setShowApproveObsModal(true);
                          }}
                        >
                          <CustomIcon
                            className={
                              currentObservationSelected.state === 'Approved' &&
                              'obsTag--state__icon'
                            }
                            type="successAlert_big"
                          />
                          <Text className="icon-label">{getText()}</Text>
                        </div>
                        {showApproveObsModal && (
                          <ConfirmationModal
                            visible={showApproveObsModal}
                            icon="alertNotification"
                            title="Confirmación de aprobación"
                            subtitle="¿Estás seguro de aprobar esta observación?"
                            buttonOne="Cancelar"
                            buttonTwo="Aceptar"
                            onClose={() => setShowApproveObsModal(false)}
                            onClickBnOne={() => setShowApproveObsModal(false)}
                            onClickBnTwo={getOnclick}
                            isLoading={loading}
                          />
                        )}
                      </>
                    )}
                  {isLegalOwner && (
                    <>
                      {currentObservationSelected.state === 'New' &&
                        !currentObservationSelected.isEditable && (
                          <div
                            className="obsTag-content-icon"
                            onClick={() => {
                              setText(currentObservationSelected?.justification ?? '');
                              setIsEdit(true);
                            }}
                          >
                            <CustomIcon type="editNotification" />
                            <Text className="icon-label">Editar</Text>
                          </div>
                        )}
                      {['New', 'Rejected'].includes(currentObservationSelected.state) && (
                        <>
                          <div
                            className="obsTag-content-icon"
                            onClick={() => {
                              if (currentObservationSelected.state !== 'Rejected') {
                                setShowCancelObsModal(true);
                              }
                            }}
                          >
                            <CustomIcon
                              className={
                                currentObservationSelected.state === 'Rejected' &&
                                'obsTag--state__icon reject'
                              }
                              type="rejectRed"
                            />
                            <Text className="icon-label">{getButtonCancelText()}</Text>
                          </div>
                          {showCancelObsModal && (
                            <ConfirmationModal
                              visible={showCancelObsModal}
                              icon="alertNotification"
                              title={`Confirmación de ${isDelete ? 'eliminación' : 'rechazo'}`}
                              subtitle={`¿Estás seguro de ${
                                isDelete ? 'eliminar' : 'rechazar'
                              } esta observación?`}
                              buttonOne="Cancelar"
                              buttonTwo="Aceptar"
                              onClose={() => setShowCancelObsModal(false)}
                              onClickBnOne={() => setShowCancelObsModal(false)}
                              onClickBnTwo={getCancelOnclick}
                              isLoading={loading}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <Divider className="obsTag-content-dividerInformation" />
                <Title className="obsTag-content-title">
                  {optionSelected === 0
                    ? currentObservationSelected?.type?.subtype
                    : 'Observación de modelo'}
                </Title>
                {!isEdit ? (
                  <Text className="obsTag-content-description">
                    {currentObservationSelected?.justification || ''}
                  </Text>
                ) : (
                  <div className="container-edit-observation">
                    <TextArea
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      style={{ resize: 'none' }}
                    />
                    <div className="container-buttons">
                      <Button
                        type="primary"
                        className="button-cancel-edit"
                        onClick={() => {
                          setIsEdit(false);
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="primary"
                        className="button-edit-observation"
                        loading={false}
                        onClick={() => {
                          updateObservation(observationSelectedId, { justification: text });
                          setIsEdit(false);
                        }}
                      >
                        Guardar
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="obsTag-content">
                <Title className="obsTag-content-title mb-0">
                  Comentarios ({comments?.length || 0})
                </Title>
                <Divider className="obsTag-content-dividerComment" />
                {comments.length > 0 &&
                  comments.map((comment) => {
                    const { id, commentary, userDetails = {}, creationDate } = comment;
                    const { imageUrl = '', name = '' } = userDetails;
                    return (
                      <Row className="obstag-comment" key={`comment-${id}`}>
                        <Row className="obstag-comment_user-info">
                          <Col className="obsTag-comment-photoUser">
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                style={{
                                  width: '32px',
                                  height: '32px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                }}
                              />
                            ) : (
                              <CustomIcon type="listUser" className="icon" />
                            )}
                          </Col>
                          <Col className="obsTag-content-comment">
                            <Text className="obsTag-comment-user">{name}</Text>
                            <Text className="observation-date">
                              {creationDate ? moment(creationDate).format('DD/MM/YYYY') : '-'}
                            </Text>
                            <Text className="obsTag-comment-message">{commentary}</Text>
                          </Col>
                        </Row>
                        <Divider className="obsTag-content-dividerComment" />
                      </Row>
                    );
                  })}
                <Row>
                  <Col className="obsTag-comment-photoUser">
                    <CustomIcon type="listUser" />
                  </Col>
                  <Col className="obsTag-content-comment">
                    <Input
                      placeholder="Escribe tu comentario..."
                      value={commentJson?.commentary}
                      onChange={onChangeDescription}
                      disabled={loading}
                    />
                  </Col>
                  <Col className="obsTag-send-icon">
                    {loadingComment ? (
                      <Spin className="comment-loading" spinning />
                    ) : (
                      <>
                        {commentJson?.commentary && (
                          <CustomIcon type="send" className="send-icon" onClick={addComment} />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <div />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

ObservationTabRequestTemplate.defaultProps = {
  observations: [],
};

export default ObservationTabRequestTemplate;
