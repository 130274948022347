import React, { useState } from 'react';
import './index.less';
import { Typography, Button, Modal, Input, Divider } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Paragraph, Text } = Typography;

const { TextArea } = Input;
const cancelationInitial = { justify: '' };

const ConfirmationModal = ({
  visible,
  icon,
  title,
  subtitle,
  buttonOne,
  buttonTwo,
  onClose,
  onClickBnOne,
  onClickBnTwo,
  isLoading,
  textArea,
  onOptionCancelation,
}) => {
  const [infoCancelation, setCancelation] = useState(cancelationInitial);
  const { justify } = infoCancelation;

  const onSaveCancelation = (value) => {
    setCancelation({ justify: value });
  };

  return (
    <Modal visible={visible} footer={null}>
      <div className="container-confirmationModal">
        <CustomIcon type="closeGrey" className="confirmationModal-iconClose" onClick={onClose} />
        <div className="container-itemsConfirmation">
          <CustomIcon className="icon-confirmationModal" type={icon} />
        </div>
        <Paragraph className="title-confirmationModal">{title}</Paragraph>
        <Text type="secondary" className="subtitle-confirmationModal">
          {subtitle}
        </Text>
        {textArea && (
          <TextArea
            placeholder="Escriba aquí su comentario"
            onChange={(e) => onSaveCancelation(e.target.value)}
            value={justify || ''}
            autoSize={{ minRows: 6, maxRows: 10 }}
          />
        )}
        <Divider className="confirmationModal-divider" />
        <div className="container-itemsConfirmation">
          <Button
            className={`bnOne-confirmationModal${
              isLoading ? ' bnOneDisabled-confirmationModal' : ''
            }`}
            onClick={() => {
              setCancelation(cancelationInitial);
              onClickBnOne();
            }}
            disabled={isLoading}
          >
            {buttonOne}
          </Button>
          <Button
            className={`bnTwo-confirmationModal${
              isLoading ? ' bnTwoDisabled-confirmationModal' : ''
            }`}
            onClick={() => {
              onClickBnTwo();
              onOptionCancelation(infoCancelation);
            }}
            loading={isLoading}
          >
            {buttonTwo}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.defaultProps = {
  visible: false,
  icon: 'alertNotification',
  title: 'Salir de solicitud',
  subtitle: '¿Desea salir de la solicitud? Los cambios realizados no se guardarán.',
  buttonOne: 'Salir',
  buttonTwo: 'Continuar',
  disabledButtonOne: false,
  disabledButtonTwo: false,
  isLoading: false,
  onClickBnOne: () => {},
  onClickBnTwo: () => {},
  onOptionCancelation: () => {},
  textArea: false,
};
